import styled, { css, keyframes } from "styled-components";

export const WaterPlace = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  cursor: ${(props) => (props.isActive ? "pointer" : "default")};

  & > img {
    position: absolute;
    transform: translate(-50%, -60%);
  }

  & > div {
    visibility: hidden;
    opacity: 0;
    transition: 0.3s linear;
  }

  &:hover > div {
    visibility: visible;
    opacity: 1;
  }

  & > img:nth-child(2) {
    clip-path: polygon(
      0 ${(props) => props.percent}%,
      100% ${(props) => props.percent}%,
      100% 100%,
      0% 100%
    );
  }
`;

export const ButtonGet = styled.button`
  & > div {
    & > span {
      width: max-content;
    }
  }
`;

export const Info = styled.div`
  display: flex;
  position: absolute;
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  cursor: pointer;
  opacity: 0;
  transition: 0.3s linear;

  &:hover {
    opacity: 1;
  }

  & > img {
    width: 100%;
    height: 100%;
  }
`;

export const Place = styled.div`
  display: flex;
  position: absolute;
  left: ${(props) => props.x && props.x}px;
  top: ${(props) => props.y && props.y}px;

  opacity: 0;
  transition: 0.3s linear;
  cursor: ${(props) =>
    !props.isDone && props.isActive ? "pointer" : "default"};

  & > img {
    filter: ${(props) => (props.isOpen ? "none" : "invert(1)")};
  }

  &:hover {
    opacity: ${(props) => (!props.isDone && props.isActive ? "1" : "0")};

    & > div {
      transition: 0.3s linear;
      opacity: 1;
    }
  }
`;

export const InfoPopup = styled.div`
  text-align: center;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  font-weight: 600;
  display: ${(props) => (props.isOpen ? "none" : "flex")};
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);

  color: #f7b471;
  font-size: 12px;
  transition: all 0.1s linear;
  padding: 3px 6px;
  background: rgba(17, 12, 9, 0.8);
  border-radius: 3px;

  outline: 1px solid #281707;
  box-shadow: inset 0px 0px 0px 1px #281707;
  border: 2px solid #fc9800;
  z-index: 1;
  width: max-content;
`;

export const InfoWater = styled(InfoPopup)`
  top: -12px;
  transform: translate(-50%, -100%) scale(1);
`;

export const InfoPlace = styled.div`
  position: absolute;
  left: ${(props) => props.x && props.x}px;
  top: ${(props) => props.y && props.y}px;
  width: 38px;
  height: 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(
    calc(${(props) => props.width && props.width / 2}px - 50%),
    calc(${(props) => props.height && props.height}px - 20px)
  );
  border-radius: 50%;

  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: rgba(17, 12, 9, 0.8);
    border-radius: 50%;
    outline: 1px solid #281707;
    box-shadow: inset 0px 0px 0px 1px #281707;
    border: 2px solid #fc9800;
    color: #fcc382;
    font-size: 12px;
    line-height: 12px;
  }
`;

export const Shovel = styled.img`
  position: absolute;
`;

export const Spoon = styled.img`
  right: 0;
  top: 0;
  position: absolute;
  cursor: pointer;
`;

export const PotionsGetWrapper = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  & > div {
    & > span {
      font-size: 14px;
    }
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;
