import React, { useEffect, useState } from "react";
import * as S from "./OrdenWar.styles";
import imgBack from "../../img/BookWar.png";

import imgCloseBook from "../../img/book/CloseBook.png";
import imgPage from "../../img/book/BookWarPage.png";
import { BookPages } from "../Book/Book.styles";
import {
  warQuestArray,
  warPagePrizes,
  warSoloQuests,
  warOrdenQuests,
} from "../../info/data";
import { RewardWrapper } from "../DayReward/DayReward.styles";
import Reward from "../Reward/Reward";
import Popup from "../Popup/Popup";
import frame1 from "../../img/Frame1.png";
import { Close } from "../../App.styles";
import { Button } from "../../panels/Arena/Arena.styles";
import { secondsToHm } from "../../helpers";

function OrdenWar({
  isOpen,
  setIsOpen,
  setModalError,
  isFetching,
  setIsFetching,
  app,
  setUser,
  serverTime,
  _id,
  email,
  adminId,
  myOrden,
  setAdmin,
  setMyOrden,
  admin,
  raidActiveTask,
}) {
  const [dataDayString, setDataDayString] = useState("");
  const [dataString, setDataString] = useState("");
  const [memberInfo, setMemberInfo] = useState(null);
  const [activeElem, setActiveElem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [countDone, setCountDone] = useState(0);
  const [myOrdenInfo, setMyOrdenInfo] = useState({ place: -1, raidDamage: 0 });

  useEffect(() => {
    let count = 0;
    if (myOrden) {
      if (myOrden.war.pageDone < warQuestArray.length) {
        warQuestArray[myOrden.war.pageDone || 0].map((elem, i) => {
          if (myOrden.war.pageTasksDone[i]) {
            count++;
          }
        });
      }
      setCountDone(count);
      setMemberInfo(myOrden.members.find((elem) => elem.email === email));
    }
  }, [myOrden]);

  useEffect(() => {
    if (admin.ordens.length && myOrden) {
      const ordensInfo = admin.ordens.map((orden) => {
        const damage = orden.war.damage
          ? Object.values(orden.war.damage).reduce((sum, cur) => sum + cur, 0)
          : 0;
        return {
          ...orden,
          raidDamage: damage,
          raidPoints: orden.war.points || 0,
        };
      });

      const ordensSorted = ordensInfo.sort((a, b) => {
        if (b.raidPoints === a.raidPoints) {
          return b.raidDamage - a.raidDamage;
        }
        return b.raidPoints - a.raidPoints;
      });

      ordensSorted.forEach((elem, i) => {
        if (elem.name === myOrden.name) {
          setMyOrdenInfo({ ...elem, place: elem.raidPoints > 0 ? i : -1 });
        }
      });
    } else {
      setMyOrdenInfo({ place: -1, raidDamage: 0 });
    }
  }, [myOrden, admin]);

  useEffect(() => {
    const d1 = new Date(serverTime);
    const offsetOld = d1.getTimezoneOffset();
    const offsetMoscow = -180;
    const d2 = new Date(
      serverTime + offsetOld * 60 * 1000 - offsetMoscow * 60 * 1000
    );

    const d3 = new Date(
      d2.getFullYear(),
      d2.getMonth(),
      d2.getDate(),
      23,
      59,
      59
    );

    const weakDay = d2.getDay();

    const time = secondsToHm(
      (d3.getTime() - d2.getTime()) / 1000 +
        (weakDay === 0 ? 0 : 7 - weakDay) * 86400
    );

    const timeDay = secondsToHm((d3.getTime() - d2.getTime()) / 1000);

    setDataDayString(timeDay);

    setDataString(time);
  }, [serverTime]);

  function handleClose() {
    setIsOpen("");
  }

  function changeActive(elem) {
    setActiveElem(elem);
  }

  function getPrizeTask(number) {
    if (
      myOrden.war.pageTasksDone[number] &&
      myOrden.war.pageDone < warQuestArray.length
    ) {
      setModalError("Награда уже получена");
      console.log("Награда уже получена");
    } else {
      const task = warQuestArray[myOrden.war.pageDone][number];
      if (task && myOrden.war.pageTasks[task.property] >= task.count) {
        app.service("admin").warGetTask({
          prize: task,
          number: number,
          myOrden: myOrden,
          email: email,
        });

        //
        // setIsFetching(true);
        // setIsLoading(true);
        // app
        //   .service("admin")
        //   .patch(
        //     adminId,
        //     {
        //       $inc: {
        //         "ordens.$.war.points": task.points,
        //         "ordens.$.treasury.gold": task.gold,
        //       },
        //       [`ordens.$.war.pageTasksDone.${number}`]: 1,
        //     },
        //     {
        //       query: {
        //         ["ordens.name"]: myOrden.name,
        //         $select: ["ordens"],
        //       },
        //     }
        //   )
        //   .then((adminData) => {
        //     setAdmin((prev) => ({ ...prev, ...adminData }));
        //     setIsFetching(false);
        //     setIsLoading(false);
        //   })
        //   .catch((e) => {
        //     setModalError(e);
        //     console.log(e);
        //     setIsFetching(false);
        //     setIsLoading(false);
        //   });
      } else {
        setModalError("Условие не выполнено");
        console.log("Условие не выполнено");
      }
    }
  }

  function getPrize(prize) {
    app
      .service("admin")
      .warGetPrize({ prize: prize, myOrden: myOrden, email: email });
  }

  function getOrdenDayPrize(prize) {
    app
      .service("admin")
      .warGetOrdenPrize({ prize: prize, myOrden: myOrden, email: email });

    // setIsFetching(true);
    // app
    //   .service("admin")
    //   .patch(
    //     adminId,
    //     {
    //       "ordens.$.war.ordenTaskDone": true,
    //       $inc: {
    //         "ordens.$.war.points": prize.points,
    //         "ordens.$.treasury.gold": prize.gold,
    //       },
    //     },
    //     {
    //       query: {
    //         ["ordens.name"]: myOrden.name,
    //         $select: ["ordens"],
    //       },
    //     }
    //   )
    //   .then((adminData) => {
    //     setAdmin((prev) => ({ ...prev, ...adminData }));
    //     setIsFetching(false);
    //   })
    //   .catch((e) => {
    //     setModalError(e);
    //     console.log(e);
    //     setIsFetching(false);
    //   });
  }

  function getPrizeSolo(prize) {
    setIsFetching(true);
    setIsLoading(true);
    app
      .service("admin")
      .patch(
        adminId,
        {
          $inc: {
            "ordens.$.war.points": prize.points,
            [`ordens.$.war.soloRate.${email}`]: 1,
          },
        },
        {
          query: {
            ["ordens.name"]: myOrden.name,
            $select: ["ordens"],
          },
        }
      )
      .then((adminData) => {
        setAdmin((prev) => ({ ...prev, ...adminData }));
        app
          .service("users")
          .patch(
            _id,
            {
              "raidActiveTask.isDone": true,
              $inc: {
                "spells.points": prize.magic,
                "spells.pointsAll": prize.magic,
              },
              field: serverTime,
            },
            {
              query: {
                $select: ["_id", "email", "raidActiveTask", "spells"],
              },
            }
          )
          .then((data) => {
            setUser((prev) => ({ ...prev, ...data }));
            setIsFetching(false);
            setIsLoading(false);
          })
          .catch((e) => {
            setModalError(e);
            console.log(e);
            setIsFetching(false);
            setIsLoading(false);
          });
      })
      .catch((e) => {
        setModalError(e);
        console.log(e);
        setIsFetching(false);
        setIsLoading(false);
      });
  }

  return (
    <S.Wrapper isOpen={isOpen} onClick={handleClose}>
      <S.Content onClick={(e) => e.stopPropagation()} back={imgBack}>
        {isOpen && myOrden && memberInfo && (
          <>
            <S.CloseBook onClick={handleClose} back={imgCloseBook} />
            <S.Page back={imgPage}>
              <BookPages>
                <S.PageWrapper>
                  <S.Header>Сражения орденов</S.Header>
                  <S.Data>{`Осталось: ${dataString}`}</S.Data>

                  <S.Data>
                    Выполняйте задания, получайте очки и поборитесь за призы в
                    таблице рейтинга
                  </S.Data>

                  <S.Data>
                    При равенстве очков решающим фактором станет нанесённый урон
                  </S.Data>

                  <S.MyOrdenTop>
                    <S.Place>
                      {myOrdenInfo.place === -1
                        ? "—"
                        : myOrdenInfo.place + 1 > 99
                        ? "99+"
                        : myOrdenInfo.place + 1}
                    </S.Place>

                    <S.OrdenName>
                      <span>[{myOrden.tag}]</span>
                      <span>{myOrden.name}</span>
                    </S.OrdenName>
                    <S.MyOrdenPoints>
                      <span>очки</span>
                      <span>{myOrdenInfo.raidPoints}</span>
                    </S.MyOrdenPoints>
                  </S.MyOrdenTop>

                  {raidActiveTask.number > -1 && (
                    <>
                      <S.Header>Личное задание</S.Header>
                      <S.Data>Осталось: {dataDayString}</S.Data>

                      <S.QuestWrapper>
                        <S.Table>
                          <tbody>
                            <tr>
                              <td scope="col">
                                <S.Quest
                                  isDone={
                                    raidActiveTask.tasks[
                                      warSoloQuests[raidActiveTask.number]
                                        .property
                                    ] >=
                                    warSoloQuests[raidActiveTask.number].count
                                  }
                                >
                                  {warSoloQuests[raidActiveTask.number].string}
                                </S.Quest>
                                <S.CountsDone
                                  isDone={
                                    raidActiveTask.tasks[
                                      warSoloQuests[raidActiveTask.number]
                                        .property
                                    ] >=
                                    warSoloQuests[raidActiveTask.number].count
                                  }
                                >
                                  <span>
                                    {raidActiveTask.tasks[
                                      warSoloQuests[raidActiveTask.number]
                                        .property
                                    ] >=
                                    warSoloQuests[raidActiveTask.number].count
                                      ? warSoloQuests[raidActiveTask.number]
                                          .count
                                      : raidActiveTask.tasks[
                                          warSoloQuests[raidActiveTask.number]
                                            .property
                                        ] || 0}
                                    /
                                    {warSoloQuests[raidActiveTask.number].count}
                                  </span>
                                </S.CountsDone>
                              </td>
                              <td scope="col">
                                <div>
                                  {raidActiveTask.isDone ? (
                                    <S.Done>Выполнено</S.Done>
                                  ) : raidActiveTask.tasks[
                                      warSoloQuests[raidActiveTask.number]
                                        .property
                                    ] >=
                                    warSoloQuests[raidActiveTask.number]
                                      .count ? (
                                    <S.MarkerButton
                                      width={80}
                                      onClick={
                                        !isFetching && !isLoading
                                          ? getPrizeSolo.bind(
                                              null,
                                              warSoloQuests[
                                                raidActiveTask.number
                                              ]
                                            )
                                          : null
                                      }
                                    >
                                      <div>
                                        <span>Забрать</span>
                                      </div>
                                    </S.MarkerButton>
                                  ) : (
                                    <S.RewardsTask>
                                      <div>
                                        {
                                          warSoloQuests[raidActiveTask.number]
                                            .points
                                        }{" "}
                                        оч.
                                      </div>
                                      <Reward
                                        button={true}
                                        count={
                                          warSoloQuests[raidActiveTask.number]
                                            .magic
                                        }
                                        name={"magic"}
                                        w={12}
                                      />
                                    </S.RewardsTask>
                                  )}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </S.Table>
                      </S.QuestWrapper>
                    </>
                  )}

                  {myOrden.war.ordenActiveTask > -1 && (
                    <>
                      <S.Header>Спец. задание</S.Header>
                      <S.Data>Осталось: {dataDayString}</S.Data>

                      <S.QuestWrapper>
                        <S.Table>
                          <tbody>
                            <tr>
                              <td scope="col">
                                <S.Quest
                                  isDone={
                                    myOrden.war.ordenTasks[
                                      warOrdenQuests[
                                        myOrden.war.ordenActiveTask
                                      ].property
                                    ] >=
                                    warOrdenQuests[myOrden.war.ordenActiveTask]
                                      .count
                                  }
                                >
                                  {
                                    warOrdenQuests[myOrden.war.ordenActiveTask]
                                      .string
                                  }
                                </S.Quest>
                                <S.CountsDone
                                  isDone={
                                    myOrden.war.ordenTasks[
                                      warOrdenQuests[
                                        myOrden.war.ordenActiveTask
                                      ].property
                                    ] >=
                                    warOrdenQuests[myOrden.war.ordenActiveTask]
                                      .count
                                  }
                                >
                                  <span>
                                    {myOrden.war.ordenTasks[
                                      warOrdenQuests[
                                        myOrden.war.ordenActiveTask
                                      ].property
                                    ] >=
                                    warOrdenQuests[myOrden.war.ordenActiveTask]
                                      .count
                                      ? warOrdenQuests[
                                          myOrden.war.ordenActiveTask
                                        ].count
                                      : myOrden.war.ordenTasks[
                                          warOrdenQuests[
                                            myOrden.war.ordenActiveTask
                                          ].property
                                        ] || 0}
                                    /
                                    {
                                      warOrdenQuests[
                                        myOrden.war.ordenActiveTask
                                      ].count
                                    }
                                  </span>
                                </S.CountsDone>
                              </td>
                              <td scope="col">
                                <div>
                                  {memberInfo.isHead ||
                                  memberInfo.title === 1 ? (
                                    myOrden.war.ordenTaskDone ? (
                                      <S.Done>Выполнено</S.Done>
                                    ) : myOrden.war.ordenTasks[
                                        warOrdenQuests[
                                          myOrden.war.ordenActiveTask
                                        ].property
                                      ] >=
                                      warOrdenQuests[
                                        myOrden.war.ordenActiveTask
                                      ].count ? (
                                      <S.MarkerButton
                                        width={80}
                                        onClick={
                                          !isFetching && !isLoading
                                            ? getOrdenDayPrize.bind(
                                                null,
                                                warOrdenQuests[
                                                  myOrden.war.ordenActiveTask
                                                ]
                                              )
                                            : null
                                        }
                                      >
                                        <div>
                                          <span>Забрать</span>
                                        </div>
                                      </S.MarkerButton>
                                    ) : (
                                      <S.RewardsTask>
                                        <div>
                                          {
                                            warOrdenQuests[
                                              myOrden.war.ordenActiveTask
                                            ].points
                                          }{" "}
                                          оч.
                                        </div>
                                        <Reward
                                          button={true}
                                          count={
                                            warOrdenQuests[
                                              myOrden.war.ordenActiveTask
                                            ].gold
                                          }
                                          name={"gold"}
                                          w={12}
                                        />
                                      </S.RewardsTask>
                                    )
                                  ) : myOrden.war.ordenTaskDone ? (
                                    <S.Done>Выполнено</S.Done>
                                  ) : (
                                    <S.RewardsTask>
                                      <div>
                                        {
                                          warOrdenQuests[
                                            myOrden.war.ordenActiveTask
                                          ].points
                                        }{" "}
                                        оч.
                                      </div>
                                      <Reward
                                        button={true}
                                        count={
                                          warOrdenQuests[
                                            myOrden.war.ordenActiveTask
                                          ].gold
                                        }
                                        name={"gold"}
                                        w={12}
                                      />
                                    </S.RewardsTask>
                                  )}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </S.Table>
                      </S.QuestWrapper>
                    </>
                  )}
                </S.PageWrapper>
                <S.PageWrapper>
                  <S.Header>Задания на неделю</S.Header>
                  {myOrden.war.pageDone < warQuestArray.length ? (
                    <>
                      <S.Data>Страница {myOrden.war.pageDone + 1}/5</S.Data>
                      <S.QuestWrapper>
                        {warQuestArray[myOrden.war.pageDone || 0].map(
                          (elem, i) => {
                            return (
                              <S.Table key={elem.string + elem.count}>
                                <tbody>
                                  <tr>
                                    <td scope="col">
                                      <S.Quest
                                        isDone={
                                          myOrden.war.pageTasks[
                                            elem.property
                                          ] >= elem.count
                                        }
                                      >
                                        {elem.string}
                                      </S.Quest>
                                      <S.CountsDone
                                        isDone={
                                          myOrden.war.pageTasks[
                                            elem.property
                                          ] >= elem.count
                                        }
                                      >
                                        <span>
                                          {myOrden.war.pageTasks[
                                            elem.property
                                          ] >= elem.count
                                            ? elem.count
                                            : myOrden.war.pageTasks[
                                                elem.property
                                              ] || 0}
                                          /{elem.count}
                                        </span>
                                      </S.CountsDone>
                                    </td>
                                    <td scope="col">
                                      <div>
                                        {memberInfo.isHead ||
                                        memberInfo.title === 1 ? (
                                          myOrden.war.pageTasksDone[i] ? (
                                            <S.Done>Выполнено</S.Done>
                                          ) : myOrden.war.pageTasks[
                                              elem.property
                                            ] >= elem.count ? (
                                            <S.MarkerButton
                                              width={80}
                                              onClick={
                                                !isFetching && !isLoading
                                                  ? getPrizeTask.bind(null, i)
                                                  : null
                                              }
                                            >
                                              <div>
                                                <span>Забрать</span>
                                              </div>
                                            </S.MarkerButton>
                                          ) : (
                                            <S.RewardsTask>
                                              <div>{elem.points} оч.</div>
                                              <Reward
                                                button={true}
                                                count={elem.gold}
                                                name={"gold"}
                                                w={12}
                                              />
                                            </S.RewardsTask>
                                          )
                                        ) : myOrden.war.pageTasksDone[i] ? (
                                          <S.Done>Выполнено</S.Done>
                                        ) : (
                                          <S.RewardsTask>
                                            <div>{elem.points} оч.</div>
                                            <Reward
                                              button={true}
                                              count={elem.gold}
                                              name={"gold"}
                                              w={12}
                                            />
                                          </S.RewardsTask>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </S.Table>
                            );
                          }
                        )}
                      </S.QuestWrapper>

                      {myOrden.war.pageDone < warQuestArray.length && (
                        <S.RewardWrapper>
                          <RewardWrapper light={true} w={60}>
                            <div>
                              <Reward
                                name={"gold"}
                                count={
                                  warPagePrizes[myOrden.war.pageDone || 0].gold
                                }
                                w={24}
                                showCount={true}
                                round={true}
                                font={12}
                                reverseColumn={true}
                                color={"#281707"}
                              />
                            </div>
                          </RewardWrapper>
                          <RewardWrapper light={true} w={60}>
                            <div>
                              <Reward
                                name={"silver"}
                                count={
                                  warPagePrizes[myOrden.war.pageDone || 0]
                                    .silver
                                }
                                w={24}
                                showCount={true}
                                round={true}
                                font={12}
                                reverseColumn={true}
                                color={"#281707"}
                              />
                            </div>
                          </RewardWrapper>

                          <div>
                            +{warPagePrizes[myOrden.war.pageDone || 0].points}{" "}
                            оч.
                          </div>
                        </S.RewardWrapper>
                      )}

                      {(memberInfo.isHead || memberInfo.title === 1) &&
                        myOrden.war.pageDone < warQuestArray.length &&
                        countDone >=
                          warQuestArray[myOrden.war.pageDone || 0].length && (
                          <S.GetButton>
                            <S.MarkerButton
                              width={120}
                              onClick={
                                !isFetching &&
                                !isLoading &&
                                countDone >=
                                  warQuestArray[myOrden.war.pageDone || 0]
                                    .length
                                  ? getPrize.bind(
                                      null,
                                      warPagePrizes[myOrden.war.pageDone || 0]
                                    )
                                  : null
                              }
                            >
                              <div>
                                <span>Забрать награду</span>
                              </div>
                            </S.MarkerButton>
                          </S.GetButton>
                        )}
                    </>
                  ) : (
                    <S.Quest>Орден выполнил все задания</S.Quest>
                  )}
                </S.PageWrapper>

                <Popup
                  isOpen={activeElem}
                  setIsOpen={setActiveElem}
                  w={281}
                  h={152}
                  back={frame1}
                >
                  <Close onClick={changeActive.bind(null, false)} />
                  {activeElem && (
                    <>
                      <b>Желаете платно завершить задание?</b>

                      <Button onClick={buyQuest}>
                        <Reward
                          name={"gold"}
                          count={`Завершить ${activeElem.price}`}
                          w={15}
                          button={true}
                        />
                      </Button>
                    </>
                  )}
                </Popup>
              </BookPages>
            </S.Page>
          </>
        )}
      </S.Content>
    </S.Wrapper>
  );
}

export default React.memo(OrdenWar);
