import React, { useEffect, useState } from "react";

import * as S from "./InputNumber.styles";

function InputNumber({ min, max, setCount, width, count = min }) {
  const [value, setValue] = useState(min);

  useEffect(() => {
    setCount(value);
  }, [value]);

  useEffect(() => {
    if (value > max) {
      if (max >= 1) {
        setValue(max);
      } else {
        setValue(1);
      }
    }
  }, [max, value]);

  function handleInput(e) {
    let value = e.target.value;
    if (value > max) {
      if (value >= 1) {
        value = max;
      } else {
        value = 1;
      }
    }
    setValue(value ? Math.abs(+Math.floor(value)) : "");
  }

  return (
    <S.Wrapper width={width}>
      <input
        value={count}
        onChange={handleInput}
        type="number"
        max={max}
        min={min}
        placeholder={""}
        step={1}
      />
    </S.Wrapper>
  );
}

export default React.memo(InputNumber);
