import React, { useEffect, useState } from "react";

import * as S from "./Inventory.styles";
import InventoryElem from "../InventoryElem/InventoryElem";
import backImg from "../../img/InventoryBack.png";
import { Close } from "../../App.styles";
import Popup from "../Popup/Popup";
import Reward from "../Reward/Reward";
import {
  bossesArray,
  bosses2Array,
  allClothes,
  grindData,
  treasuresArray,
  inventoryItems,
} from "../../info/data";
import frame3 from "../../img/Frame3.png";
import { RewardWrapper } from "../DayReward/DayReward.styles";
import { Button } from "../../panels/Arena/Arena.styles";
import { ShopElements, ShopElemWrapper } from "../Shop/Shop.styles";
import {
  ButtonBottom,
  TopString,
  TopWrapper,
} from "../ShopElem/ShopElem.styles";
import { roundConsumablesFixed } from "../../helpers";
import { ButtonsWrapper } from "../Shop/Shop.styles";

function Inventory({
  _id,
  clothes,

  bosses,
  bosses2,
  treasures,
  characteristic,
  grind,
  sex,
  damageTalents,
  gold,
  candy,
  isOpen,
  isFetching,
  isModalOpen,
  useItem,
  setIsFetching,
  setModalError,
  setUser,
  allUsers,
  moveToUserRoom,
  app,
  serverTime,
  handleAltarOpen,
  warOrdenTask,
  inventory,
  warOrdenDayTask,
  diceGameCount,
  game21Count,
  moveLocation,
  setActiveMenuModal,
  isEvent,
  eventGameCount,
  eventDiamondsCount,
}) {
  const [treasureReward, setTreasureReward] = useState(null);
  const [treasureOpen, setTreasureOpen] = useState(0);
  const [items, setItems] = useState([]);
  const [candyInfo, setCandyInfo] = useState({});
  const [tab, setTab] = useState("potion");

  useEffect(() => {
    const array = [];

    inventoryItems.map((elem) => {
      const item = inventory[`${elem.category}${elem.name}`];
      if (item > 0) {
        array.push({ ...elem, count: item });
      }
    });

    if (tab === "potion") {
      setItems(
        [...array].filter(
          (elem) =>
            elem.category === "hp" ||
            elem.category === "energy" ||
            elem.category === "mana"
        )
      );
    } else if (tab === "soul") {
      setItems(
        [...array].filter(
          (elem) => elem.category === "soul" || elem.category === "soul2"
        )
      );
    } else {
      setItems([]);
    }
  }, [tab, inventory]);

  function handleMove(name) {
    moveLocation(name);
    setActiveMenuModal("");
  }

  function changeTreasure(number) {
    setTreasureOpen(number);
  }

  function changeTreasureReward(reward) {
    setTreasureReward(reward);
  }

  function openTreasure(lvl) {
    if (treasures.countHave[lvl] > 0) {
      const randomChance = Math.random().toFixed(5);

      if (
        gold <
        (treasures.countOpenDay[lvl] > 10 ? 10 : treasures.countOpenDay[lvl]) *
          10
      ) {
        console.log("Недостаточно золота");

        setModalError({
          string: "Недостаточно золота",
          buttons: ["bank"],
        });
      } else {
        const clothesInfo = Object.entries(allClothes)
          .filter(
            (elem) =>
              elem[1]?.treasure?.lvl === lvl &&
              clothes.isBuy.findIndex((el) => el === elem[0]) === -1
          )
          .sort((a, b) => b[1].treasure.chance - a[1].treasure.chance);

        let prize = null;

        clothesInfo.map((elem) => {
          if (randomChance <= elem[1].treasure.chance) {
            prize = {
              name: elem[0],
              category: elem[1].category,
              lvl: elem[1].lvl,
              src: elem[1].src,
              isClothe: true,
            };
          }
        });

        setIsFetching(true);
        if (prize) {
          const buyClothes = [...clothes.isBuy, prize.name];

          const newCharacteristic = { ...characteristic };

          for (const key in newCharacteristic) {
            newCharacteristic[key].armor = 0;
          }

          const clothesInfo = Object.entries(allClothes).filter((elem) =>
            buyClothes.includes(elem[0])
          );

          clothesInfo.forEach((elem) => {
            Object.entries(elem[1].characteristic).forEach((el) => {
              newCharacteristic[el[0]].armor += el[1];
            });
          });

          const scales = {};
          const grindValue = grindData["book"].bonus[grind["book"] - 1] / 100;
          damageTalents.characteristicTalents.forEach((elem) => {
            scales[elem.property] =
              1 +
              grindValue +
              Math.round(elem.step * elem.countDone * 100) / 10000;
          });

          const hp = Math.floor(
            Object.values(newCharacteristic.health).reduce((acc, cur, i) => {
              if (i < 2) {
                return acc + cur * 5 * scales.health;
              }
              return acc + cur * 5;
            }, 0)
          );

          app
            .service("users")
            .patch(
              _id,
              {
                $inc: {
                  [`treasures.countHave.${lvl}`]: -1,
                  [`treasures.countOpen.${lvl}`]: 1,
                  [`treasures.countOpenDay.${lvl}`]: 1,
                  gold:
                    -(treasures.countOpenDay[lvl] > 10
                      ? 10
                      : treasures.countOpenDay[lvl]) * 10,
                  "summerSave.results.treasures": 1,
                },
                "health.max": hp,
                "clothes.isBuy": buyClothes,
                characteristic: newCharacteristic,
                field: serverTime,
              },
              {
                query: {
                  $select: [
                    "_id",
                    "email",
                    "health",
                    "clothes",
                    "characteristic",
                    "treasures",
                    "gold",
                    "summerSave",
                  ],
                },
              }
            )
            .then((data) => {
              setUser((prev) => ({ ...prev, ...data }));
              setTreasureReward(prize);
              setIsFetching(false);
              changeTreasure(0);

              const tasksDay = [{ property: "treasureOpen", count: 1 }];
              warOrdenDayTask(tasksDay);
            })
            .catch((e) => {
              setIsFetching(false);
              setModalError(e);
              console.log(e);
            });
        } else {
          const rewardIndex = Math.floor(
            Math.random() * treasuresArray[lvl].length
          );
          const reward = treasuresArray[lvl][rewardIndex];

          if (reward.property === "potion") {
            app
              .service("users")
              .patch(
                _id,
                {
                  $inc: {
                    [`inventoryNew.${reward.category}${reward.potionName}`]:
                      reward.property === "potion" ? reward.count : 0,
                    [`treasures.countHave.${lvl}`]: -1,
                    [`treasures.countOpen.${lvl}`]: 1,
                    [`treasures.countOpenDay.${lvl}`]: 1,
                  },
                  field: serverTime,
                },
                {
                  query: {
                    $select: ["_id", "email", "inventoryNew", "treasures"],
                  },
                }
              )
              .then((data) => {
                setUser((prev) => ({ ...prev, ...data }));
                setTreasureReward(reward);
                setIsFetching(false);
                changeTreasure(0);

                const tasksDay = [{ property: "treasureOpen", count: 1 }];
                warOrdenDayTask(tasksDay);
              })
              .catch((e) => {
                setIsFetching(false);
                setModalError(e);
                console.log(e);
              });
          } else {
            app
              .service("users")
              .patch(
                _id,
                {
                  $inc: {
                    [`treasures.countHave.${lvl}`]: -1,
                    [`treasures.countOpen.${lvl}`]: 1,
                    [`treasures.countOpenDay.${lvl}`]: 1,
                    gold:
                      -(
                        (treasures.countOpenDay[lvl] > 10
                          ? 10
                          : treasures.countOpenDay[lvl]) * 10
                      ) + (reward.property === "gold" ? reward.count : 0),
                    "spells.points":
                      reward.property === "magic" ? reward.count : 0,
                    "spells.pointsAll":
                      reward.property === "magic" ? reward.count : 0,
                    "game21.isFree":
                      reward.property === "game21" ? reward.count : 0,
                    "diceGame.isFree":
                      reward.property === "diceGame" ? reward.count : 0,
                    [`bosses.${
                      reward.property === "soul" ? reward.number : 0
                    }.souls`]: reward.property === "soul" ? reward.count : 0,
                    "summerSave.results.treasures": 1,
                  },
                  field: serverTime,
                },
                {
                  query: {
                    $select: [
                      "_id",
                      "email",
                      "treasures",
                      "gold",
                      "bosses",
                      "game21",
                      "diceGame",
                      "spells",
                      "summerSave",
                    ],
                  },
                }
              )
              .then((data) => {
                setUser((prev) => ({ ...prev, ...data }));
                setTreasureReward(reward);
                setIsFetching(false);
                changeTreasure(0);

                const tasksDay = [{ property: "treasureOpen", count: 1 }];
                warOrdenDayTask(tasksDay);
              })
              .catch((e) => {
                setIsFetching(false);
                setModalError(e);
                console.log(e);
              });
          }
        }
      }
    } else {
      setModalError("Недостаточно сундуков для открытия");
      changeTreasure(0);
    }
  }

  function handleTab(name) {
    setTab(name);
  }

  function handleClose() {
    setActiveMenuModal(false);
  }

  function handleClickUser(user) {
    moveToUserRoom(user);
    setCandyInfo({});
    handleClose();
  }

  function eatCandy() {
    if (candy.length > 0) {
      setIsFetching(true);
      const array = [...candy];
      const candyEat = array.shift();
      const userInfo = allUsers.find((user) => user._id === candyEat.userId);
      const manaRandom = Math.floor(Math.random() * 181) + 70;
      setCandyInfo({
        name: candyEat.name,
        user: userInfo,
        mana: manaRandom,
      });
      app
        .service("users")
        .patch(
          _id,
          {
            candy: array,
            field: serverTime,
            $inc: {
              mana: manaRandom,
              "dayQuest.candyGet": 1,
              "summerSave.results.candyGet": 1,
            },
          },
          {
            query: {
              $select: [
                "_id",
                "email",
                "candy",
                "mana",
                "dayQuest",
                "summerSave",
              ],
            },
          }
        )
        .then((data) => {
          setUser((prev) => ({ ...prev, ...data }));
          setIsFetching(false);

          const tasks = [{ property: "candyGet", count: 1 }];
          warOrdenTask(tasks);
        })
        .catch((e) => {
          setModalError(e);
          console.log(e);
          setIsFetching(false);
        });
    } else {
      setModalError("Вы уже съесли все драже");
      console.log("Вы уже съесли все драже");
    }
  }

  return (
    <S.Wrapper isOpen={isOpen} onClick={handleClose}>
      <S.Content onClick={(e) => e.stopPropagation()} back={backImg}>
        {isOpen && (
          <>
            <div>
              <Close onClick={handleClose} />

              <ButtonsWrapper>
                <div>
                  <Button
                    disabled={tab === "potion"}
                    onClick={handleTab.bind(null, "potion")}
                    width={100}
                  >
                    <div>Зелья</div>
                  </Button>

                  <Button
                    disabled={tab === "soul"}
                    onClick={handleTab.bind(null, "soul")}
                    width={100}
                  >
                    <div>Души</div>
                  </Button>

                  <Button
                    disabled={tab === "all"}
                    onClick={handleTab.bind(null, "all")}
                    width={100}
                  >
                    <div>Разное</div>
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={() => {
                      setActiveMenuModal("collections");
                    }}
                    width={100}
                  >
                    <div>Коллекции</div>
                  </Button>
                </div>
              </ButtonsWrapper>

              <ShopElements>
                {tab === "potion" &&
                  items.length > 0 &&
                  items.map((elem) => {
                    return (
                      <InventoryElem
                        key={`${elem.name}${elem.category}${elem.value}`}
                        elem={elem}
                        loading={isFetching}
                        isModalOpen={isModalOpen}
                        useItem={useItem}
                      />
                    );
                  })}

                {isEvent && eventDiamondsCount > 0 && tab === "all" && (
                  <ShopElemWrapper>
                    <TopWrapper>
                      <TopString>
                        Изумруды ({roundConsumablesFixed(eventDiamondsCount)})
                      </TopString>
                      <Reward name={"diamond"} w={68} button={true} />
                    </TopWrapper>
                    <ButtonBottom
                      onClick={() => {
                        setActiveMenuModal("");
                        moveLocation("ascaban");
                      }}
                    >
                      <div>Перейти в лавку</div>
                    </ButtonBottom>
                  </ShopElemWrapper>
                )}

                {isEvent && eventGameCount > 0 && tab === "all" && (
                  <ShopElemWrapper>
                    <TopWrapper>
                      <TopString>
                        Подарки ({roundConsumablesFixed(eventGameCount)})
                      </TopString>
                      <Reward name={"prize"} w={68} button={true} />
                    </TopWrapper>

                    <ButtonBottom
                      onClick={() => {
                        setActiveMenuModal("prizeGame");
                      }}
                    >
                      <div>Открыть</div>
                    </ButtonBottom>
                  </ShopElemWrapper>
                )}

                {diceGameCount > 0 && tab === "all" && (
                  <ShopElemWrapper>
                    <TopWrapper>
                      <TopString>
                        Игры в Кости ({roundConsumablesFixed(diceGameCount)})
                      </TopString>
                      <Reward name={"diceGame"} w={68} button={true} />
                    </TopWrapper>

                    <ButtonBottom onClick={handleMove.bind(null, "gameRoom")}>
                      <div>Играть</div>
                    </ButtonBottom>
                  </ShopElemWrapper>
                )}

                {game21Count > 0 && tab === "all" && (
                  <ShopElemWrapper>
                    <TopWrapper>
                      <TopString>
                        Игры в 21 ({roundConsumablesFixed(game21Count)})
                      </TopString>
                      <Reward name={"game21"} w={68} button={true} />
                    </TopWrapper>

                    <ButtonBottom onClick={handleMove.bind(null, "gameRoom")}>
                      <div>Играть</div>
                    </ButtonBottom>
                  </ShopElemWrapper>
                )}

                {candy.length > 0 && tab === "all" && (
                  <ShopElemWrapper>
                    <TopWrapper>
                      <TopString>
                        Драже Сюрприз ({roundConsumablesFixed(candy.length)})
                      </TopString>
                      <Reward name={"candy"} w={68} button={true} />
                    </TopWrapper>

                    <ButtonBottom onClick={!isFetching ? eatCandy : null}>
                      <div>Открыть</div>
                    </ButtonBottom>
                  </ShopElemWrapper>
                )}

                {tab === "all" &&
                  treasures.countHave.map((elem, i) => {
                    return (
                      elem > 0 && (
                        <ShopElemWrapper key={`Сундук ${i + 1}`}>
                          <TopWrapper>
                            <TopString>
                              Сундук {i + 1} ({roundConsumablesFixed(elem || 0)}
                              )
                            </TopString>
                            <Reward
                              name={"treasure"}
                              treasure={i}
                              w={68}
                              button={true}
                            />
                          </TopWrapper>

                          <ButtonBottom
                            onClick={changeTreasure.bind(null, i + 1)}
                          >
                            <div>Открыть</div>
                          </ButtonBottom>
                        </ShopElemWrapper>
                      )
                    );
                  })}

                {tab === "soul" && (
                  <>
                    {bosses.map((elem, i) => {
                      return (
                        (elem?.souls || 0) > 0 && (
                          <ShopElemWrapper key={bossesArray[i][0].name}>
                            <TopWrapper>
                              <TopString>
                                {bossesArray[i][0].name} (
                                {roundConsumablesFixed(elem?.souls || 0)})
                              </TopString>
                              <Reward
                                name={"soul"}
                                soul={i}
                                w={68}
                                button={true}
                              />
                            </TopWrapper>

                            <ButtonBottom
                              onClick={handleAltarOpen.bind(null, 1)}
                            >
                              <div>Обмен</div>
                            </ButtonBottom>
                          </ShopElemWrapper>
                        )
                      );
                    })}

                    {bosses2.map((elem, i) => {
                      return (
                        (elem?.souls || 0) > 0 && (
                          <ShopElemWrapper key={bosses2Array[i][0].name}>
                            <TopWrapper>
                              <TopString>
                                {bosses2Array[i][0].name} (
                                {roundConsumablesFixed(elem?.souls || 0)})
                              </TopString>
                              <Reward
                                name={"soul2"}
                                soul={i}
                                w={68}
                                button={true}
                              />
                            </TopWrapper>

                            <ButtonBottom
                              onClick={handleAltarOpen.bind(null, 2)}
                            >
                              <div>Обмен</div>
                            </ButtonBottom>
                          </ShopElemWrapper>
                        )
                      );
                    })}
                  </>
                )}
              </ShopElements>
            </div>

            <Popup
              isOpen={treasureOpen}
              setIsOpen={setTreasureOpen}
              w={317}
              h={217}
              back={frame3}
            >
              <Close onClick={changeTreasure.bind(null, 0)} />
              <b>Желаете открыть сундук {treasureOpen}?</b>
              <span>
                Каждое последующие открытие дороже на 10 золотых (не более 100),
                цена сбрасывается в конце дня
              </span>
              <Button
                disabled={isFetching}
                onClick={
                  !isFetching ? openTreasure.bind(null, treasureOpen - 1) : null
                }
              >
                <Reward
                  name={"gold"}
                  count={`Открыть ${
                    (treasures.countOpenDay[treasureOpen - 1] > 10
                      ? 10
                      : treasures.countOpenDay[treasureOpen - 1]) * 10
                  }`}
                  w={14}
                  button={true}
                />
              </Button>
            </Popup>

            <Popup
              isOpen={treasureReward}
              w={317}
              h={217}
              back={frame3}
              setIsOpen={changeTreasureReward}
            >
              <Close onClick={changeTreasureReward.bind(null, null)} />
              <b>Содержимое сундука</b>
              {treasureReward &&
                (treasureReward.isClothe ? (
                  treasureReward.category === "broom" ||
                  treasureReward.category === "wand" ? (
                    <img
                      width={90}
                      height={90}
                      src={require(`../../img/clothes/${
                        treasureReward.category
                      }${
                        treasureReward.lvl >= 1
                          ? `${treasureReward.lvl + 1}`
                          : ""
                      }/${treasureReward.src}`)}
                      alt={treasureReward.category}
                    />
                  ) : (
                    <img
                      width={90}
                      height={90}
                      src={require(`../../img/clothes/${
                        treasureReward.category
                      }${
                        treasureReward.lvl >= 1
                          ? `${treasureReward.lvl + 1}`
                          : ""
                      }/${sex}/${treasureReward.src}`)}
                      alt={treasureReward.category}
                    />
                  )
                ) : (
                  <RewardWrapper light={false} w={65}>
                    <div>
                      <Reward
                        name={treasureReward.property}
                        potionName={treasureReward.potionName}
                        category={treasureReward.category}
                        count={treasureReward.count}
                        soul={treasureReward.number}
                        w={28}
                        showCount={true}
                        round={true}
                        font={13}
                        reverseColumn={true}
                        color={"#fdbb54"}
                      />
                    </div>
                  </RewardWrapper>
                ))}
            </Popup>

            <Popup
              isOpen={candyInfo.name}
              setIsOpen={setCandyInfo}
              w={317}
              h={217}
              back={frame3}
            >
              <Close
                onClick={() => {
                  setCandyInfo({});
                }}
              />
              {candyInfo.name && (
                <>
                  <b>
                    {candyInfo.user ? (
                      <S.UserSpan
                        onClick={handleClickUser.bind(null, candyInfo.user)}
                      >
                        {candyInfo.user.nickName
                          ? candyInfo.user.nickName
                          : `${candyInfo.user.name?.firstName} ${candyInfo.user.name?.lastName}`}
                      </S.UserSpan>
                    ) : (
                      "Маг"
                    )}{" "}
                    прислал Вам Драже Сюрприз со вкусом "{candyInfo.name}"
                  </b>
                  <S.CandyMana>
                    Вы получили:
                    <Reward name={"mana"} count={candyInfo.mana} w={15} />
                  </S.CandyMana>

                  {candy.length > 0 && (
                    <Button
                      width={100}
                      disabled={isFetching}
                      onClick={
                        !isFetching && candy.length > 0 ? eatCandy : null
                      }
                    >
                      <div>Съесть ещё</div>
                    </Button>
                  )}
                </>
              )}
            </Popup>
          </>
        )}
      </S.Content>
    </S.Wrapper>
  );
}

export default React.memo(Inventory);
