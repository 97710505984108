import React, { useCallback, useEffect, useState } from "react";

import * as S from "./Bosses.styles";
import ActiveBoss from "../../components/ActiveBoss/ActiveBoss";
import {
  roundConsumablesFixed,
  roundLvlExp,
  roundProperty,
  secondsToDhm,
  secondsToHm,
} from "../../helpers";
import { CentredWrapper, Close } from "../../App.styles";
import {
  allClothes,
  bosses2Array,
  bossesArray,
  bossesStages,
  bossesTalents,
  eventBossArray,
  grindData,
  soloBossArray,
} from "../../info/data";
import Popup from "../../components/Popup/Popup";
import Reward from "../../components/Reward/Reward";
import imgArrow from "../../img/ArrowBosses.png";
import imgTongue from "../../img/Tongue.png";

import piksiImg from "../../img/bossesPictures/Piksi.png";
import pivzImg from "../../img/bossesPictures/Pivz.png";
import mirtlImg from "../../img/bossesPictures/Mirtl.png";
import filchImg from "../../img/bossesPictures/Filch.png";
import boggartImg from "../../img/bossesPictures/Boggart.png";
import baronImg from "../../img/bossesPictures/Baron.png";
import abskurImg from "../../img/bossesPictures/Abskur.png";
import grumImg from "../../img/bossesPictures/Grum.png";
import { BottomPrice, BottomPrices, Button } from "../Arena/Arena.styles";
import { RewardWrapper } from "../../components/DayReward/DayReward.styles";
import frame3 from "../../img/Frame3.png";
import BossInterface from "../../components/BossInterface/BossInterface";
import { TopPart } from "../../components/BossInterface/BossInterface.styles";
import imgTop from "../../img/boss/TopPart.png";
import { TopButtonFull } from "../Tops/Tops.styles";
import noteImg from "../../img/map/Note.png";
import {
  ArrowPage,
  ArrowPagePrev,
  CloseNote,
  LocationNote,
  LocationsNote,
  NameNote,
} from "../../components/Map/Map.styles";
import closeImg from "../../img/map/Close.png";
import closeHoverImg from "../../img/map/CloseHover.png";
import pageArrowImg from "../../img/map/PageArrow.png";
import pageArrowHoverImg from "../../img/map/PageArrowHover.png";
import soloBossesImg from "../../img/map/SoloBosses.png";
import soloBossesHoverImg from "../../img/map/SoloBossesHover.png";
import bosses1Img from "../../img/map/Bosses1.png";
import bosses1HoverImg from "../../img/map/Bosses1Hover.png";
import bosses2Img from "../../img/map/Bosses2.png";
import bosses2HoverImg from "../../img/map/Bosses2Hover.png";
import frame6 from "../../img/Frame6.png";
import { createPortal } from "react-dom";
import { MenuElemRight, MenuRight } from "../Home/Home.styles";
import helperIcon from "../../img/menuIcons/Helper.png";
import { ButtonChangeWrapper } from "../../components/ArenaMenu/ArenaMenu.styles";
import {
  ButtonChange,
  ButtonValue as ButtonValueSmall,
} from "../../components/Healls/Healls.styles";
import { InputAttacks } from "../../components/Spell/Spell.styles";
import { ButtonsWrapper, MarkerButtonStyled } from "./Bosses.styles";

const types = {
  entity: "Существо",
  man: "Человек",
  ghost: "Призрак",
};

const locationsOpen = [
  {
    location: "soloBoss",
    name: "Соло Боссы",
    src: soloBossesImg,
    srcHover: soloBossesHoverImg,
  },
  {
    location: "bosses",
    name: "Территория замка",
    src: bosses1Img,
    srcHover: bosses1HoverImg,
  },
  {
    location: "bosses2",
    name: "Окресности замка",
    src: bosses2Img,
    srcHover: bosses2HoverImg,
  },
];

const photos = [
  piksiImg,
  pivzImg,
  mirtlImg,
  filchImg,
  boggartImg,
  baronImg,
  abskurImg,
  grumImg,
];

function Bosses({
  user,
  app,
  friends,
  setUser,
  serverTime,
  setIsFetching,
  isFetching,
  isModalOpen,
  setIsModalOpen,
  setIsLvlUpOpen,
  changeOptionsLocation,
  setModalError,
  bossesLimitScale,
  allUsers,
  useItem,
  setBossLog,
  bossLog,
  bossesInfo,
  setBossesInfo,
  setIsAltarOpen,
  warOrdenTask,
  ordenBonus,
  moveLocation,
  bridge,
  setActiveMenuModal,
}) {
  const [countAttacks, setCountAttacks] = useState(bossesInfo?.bosses1X || 1);

  const [locationsOpenArray, setLocationsOpenArray] = useState([]);
  const [isOpenMap, setIsOpenMap] = useState(false);
  const [notePage, setNotePage] = useState(0);

  const [helperOpen, setHelperOpen] = useState(false);
  const [timeHelp, setTimeHelp] = useState(null);
  const [domReady, setDomReady] = React.useState(false);

  const [bossesLimit, setBossesLimit] = useState(0);
  const [bossStage, setBossStage] = useState(bossesInfo?.lastBoss2Stage || 0);
  const [pageNumber, setPageNumber] = useState(bossesInfo?.lastBoss2 || 0);
  const [solo, setSolo] = useState(false);
  const [bossRandomReward, setBossRandomReward] = useState(null);
  const [timeToNext, setTimeToNext] = useState("0");
  const [clearLimitOpen, setClearLimitOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bossExpScale, setBossExpScale] = useState(
    user.damageTalents.simpleTalents[
      bossesTalents.bossExpScale[bossesArray[pageNumber][bossStage].classType]
    ]
  );
  const [bossSilverScale, setBossSilverScale] = useState(
    user.damageTalents.simpleTalents[
      bossesTalents.bossSilverScale[
        bossesArray[pageNumber][bossStage].classType
      ]
    ]
  );
  const [clothesOpen, setClothesOpen] = useState([0, 0]);

  useEffect(() => {
    setDomReady(true);
    changeOptionsLocation("bosses");
  }, []);

  useEffect(() => {
    if (!timeHelp) {
      setCountAttacks(1);
    }
  }, [timeHelp]);

  useEffect(() => {
    setLocationsOpenArray(locationsOpen.slice(notePage, notePage + 2));
  }, [notePage]);

  useEffect(() => {
    setBossesInfo((prev) => ({
      ...prev,
      lastBoss2: pageNumber,
      lastBoss2Stage: bossStage,
    }));
  }, [bossStage, pageNumber]);

  useEffect(() => {
    setBossExpScale(
      user.damageTalents.simpleTalents[
        bossesTalents.bossExpScale[bossesArray[pageNumber][bossStage].classType]
      ]
    );

    setBossSilverScale(
      user.damageTalents.simpleTalents[
        bossesTalents.bossSilverScale[
          bossesArray[pageNumber][bossStage].classType
        ]
      ]
    );

    const clothes = Object.entries(allClothes).filter(
      (elem) => elem[1]?.bossRandom && elem[1]?.bossRandom.number === pageNumber
    );

    const clothesBoss = clothes.filter(
      (elem) =>
        elem[1]?.bossRandom.stage === bossStage &&
        elem[1]?.bossRandom.isSolo === solo
    );

    const clothesBossOpen = clothesBoss.filter(
      (elem) => user.clothes.isOpen.findIndex((el) => el === elem[0]) === -1
    );

    setClothesOpen([
      clothesBoss.length - clothesBossOpen.length,
      clothesBoss.length,
    ]);
  }, [user, bossStage, pageNumber, solo]);

  useEffect(() => {
    const mm = (59 - new Date(serverTime).getMinutes()) * 60;
    const ss = 60 - new Date(serverTime).getSeconds();

    setTimeToNext(secondsToHm(mm + ss));

    if (
      serverTime <
        bossesLimitScale.startTime + bossesLimitScale.time * 3600000 &&
      serverTime > bossesLimitScale.startTime
    ) {
      setBossesLimit(bossesLimitScale.count);
    } else {
      setBossesLimit(0);
    }

    if ((user.helpers?.soloBosses || 0) >= serverTime) {
      setTimeHelp(
        secondsToDhm(((user.helpers?.soloBosses || 0) - serverTime) / 1000)
      );
    } else {
      setTimeHelp(null);
    }
  }, [bossesLimitScale, serverTime]);

  useEffect(() => {
    setBossesInfo((prev) => ({
      ...prev,
      bosses1X: countAttacks,
    }));
  }, [countAttacks]);

  function changeSolo(solo) {
    setSolo(solo);
  }

  function changeCount(add) {
    let maxCount =
      10 + bossesLimit - (user.bosses[pageNumber]?.countAttacks?.done || 0);

    if (maxCount > 10) {
      maxCount = 10;
    }

    setCountAttacks((prev) => {
      if (add) {
        if (+prev + 1 > maxCount) {
          return maxCount;
        }
        return +prev + 1;
      } else {
        if (+prev - 1 >= 1) {
          return +prev - 1;
        }
      }
      return +prev;
    });
  }

  function handleInput(e) {
    let maxCount =
      10 + bossesLimit - (user.bosses[pageNumber]?.countAttacks?.done || 0);

    if (maxCount > 10) {
      maxCount = 10;
    }

    let value = e.target.value;
    if (value > maxCount) {
      if (value >= 1) {
        value = maxCount;
      } else {
        value = 1;
      }
    }

    setCountAttacks(value ? Math.abs(+Math.floor(value)) : "");
  }

  function changeNotePage(page) {
    if (page > 0) {
      setNotePage((prev) => prev + 1);
    } else {
      setNotePage((prev) => {
        if (prev > 0) {
          return prev - 1;
        } else {
          return 0;
        }
      });
    }
  }

  function changeOpenMap(isOpen) {
    setIsOpenMap(isOpen);
  }

  function changeHelperOpen(open) {
    setHelperOpen(open);
  }

  function changePageNumber(isNext) {
    if (isNext) {
      setPageNumber((prev) => {
        if (prev + 1 < bossesArray.length) {
          return prev + 1;
        } else {
          return prev;
        }
      });
    } else {
      setPageNumber((prev) => {
        if (prev - 1 >= 0) {
          return prev - 1;
        } else {
          return 0;
        }
      });
    }
  }

  function buyHelper({ days, price }) {
    setIsFetching(true);
    setLoading(true);

    bridge
      .send("VKWebAppShowOrderBox", {
        type: "item",
        item: JSON.stringify({
          helpersSoloBosses: days,
          g: price,
        }),
      })
      .then((data) => {
        if (data.success) {
          console.log("успех", data);
        }
        setIsFetching(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsFetching(false);
        setLoading(false);
      });
  }

  function randomBossClothes(bossNumber, stage, isSolo) {
    const randomChance = Math.random().toFixed(2);

    const clothes = Object.entries(allClothes)
      .filter(
        (elem) =>
          elem[1]?.bossRandom &&
          elem[1]?.bossRandom.number === bossNumber &&
          elem[1]?.bossRandom.stage === stage &&
          elem[1]?.bossRandom.isSolo === isSolo &&
          user.clothes.isOpen.findIndex((el) => el === elem[0]) === -1
      )
      .sort((a, b) => a[1].bossRandom.chance - b[1].bossRandom.chance);

    let prize = null;

    clothes.map((elem) => {
      if (randomChance <= elem[1].bossRandom.chance) {
        prize = elem;
      }
    });

    if (prize) {
      const openClothes = [...user.clothes.isOpen, prize[0]];
      setLoading(true);
      setIsFetching(false);
      app
        .service("users")
        .patch(
          user._id,
          {
            "clothes.isOpen": openClothes,
            field: serverTime,
          },
          {
            query: {
              $select: ["_id", "email", "clothes"],
            },
          }
        )
        .then((data) => {
          setUser((prev) => ({ ...prev, ...data }));
          setBossRandomReward(prize[1]);
          setIsFetching(false);
          setLoading(false);
        })
        .catch((e) => {
          setIsFetching(false);
          setLoading(false);
          setModalError(e);
          console.log(e);
        });
    }
  }

  function changeBossReward(reward) {
    setBossRandomReward(reward);
  }

  function handleAltarOpen() {
    setIsAltarOpen(1);
  }

  function handleClearLimits() {
    setClearLimitOpen((prevState) => !prevState);
  }

  function clearLimits() {
    if ((user.bossLimitClear || 0) < 1) {
      if (user.gold >= 500) {
        setLoading(true);
        setIsFetching(true);
        app
          .service("users")
          .patch(
            user._id,
            {
              "bosses.$[].countAttacks.done": 0,
              $inc: {
                bossLimitClear: 1,
                gold: -500,
                "dayQuest.bossesLimit": 1,
              },
              field: serverTime,
            },
            {
              query: {
                $select: [
                  "_id",
                  "email",
                  "bossLimitClear",
                  "bosses",
                  "gold",
                  "dayQuest",
                ],
              },
            }
          )
          .then((data) => {
            setLoading(false);
            setClearLimitOpen(false);
            setUser((prev) => ({ ...prev, ...data }));
            setIsFetching(false);
          })
          .catch((e) => {
            setModalError(e);
            console.log(e);
            setIsFetching(false);
            setLoading(false);
          });
      } else {
        console.log("Недостаточно золота");

        setModalError({
          string: "Недостаточно золота",
          buttons: ["bank"],
        });
      }
    } else {
      setModalError("Лимит сбросов исчерпан");
      console.log("Лимит сбросов исчерпан");
    }
  }

  function attackBoss() {
    if (
      (!user.raidBoss.isEnd && user.raidBoss.isAttack) ||
      user.activeBoss.number > -1
    ) {
      if (user.activeBoss.number > -1) {
        setModalError(
          `Вы уже находитесь в бою с "${
            user.activeBoss.isShyt
              ? "Шутом"
              : user.activeBoss.type === "location"
              ? soloBossArray[user.activeBoss.number][0].name
              : user.activeBoss.type === "eventBoss"
              ? eventBossArray[user.activeBoss.number].name
              : user.activeBoss.type === "dungeon2"
              ? bosses2Array[user.activeBoss.number][0].name
              : bossesArray[user.activeBoss.number][0].name
          }"`
        );
      } else {
        setModalError("Вы уже находитесь в бою с Рейдовым Боссом");
      }
    } else {
      let isAttack = true;
      if (
        (user.bosses[pageNumber]?.countAttacks?.done || 0) +
          (countAttacks || 1) >
        10 + bossesLimit
      ) {
        isAttack = false;
        setModalError("Нельзя нападать выше лимита");
      } else if (
        (user.bosses[pageNumber]?.souls || 0) <
        bossesArray[pageNumber][bossStage].soulsNeed * (countAttacks || 1)
      ) {
        isAttack = false;
        setModalError("Недостаточно душ");
      }

      if (isAttack) {
        setIsFetching(true);
        setLoading(true);
        app
          .service("users")
          .patch(
            user._id,
            {
              $inc: {
                [`bosses.${pageNumber}.souls`]:
                  pageNumber > 0
                    ? -bossesArray[pageNumber][bossStage].soulsNeed *
                      (countAttacks || 1)
                    : 0,
                [`bosses.${pageNumber}.countAttacks.done`]: countAttacks || 1,
              },
              "activeBoss.countX": countAttacks || 1,
              "activeBoss.type": "dungeon",
              "activeBoss.isSolo": solo,
              "activeBoss.stage": bossStage,
              "activeBoss.number": pageNumber,
              "activeBoss.currentHp":
                bossesArray[pageNumber][bossStage].allHp * (countAttacks || 1),
              "activeBoss.endTime":
                bossesArray[pageNumber][bossStage].time.maxTime * 3600000 +
                serverTime,
              field: serverTime,
            },
            {
              query: {
                $select: ["_id", "email", "bosses", "activeBoss"],
              },
            }
          )
          .then((data) => {
            setUser((prev) => ({ ...prev, ...data }));
            setTimeout(() => {
              setLoading(false);
            }, 250);
            setIsFetching(false);
          })
          .catch((e) => {
            setModalError(e);
            console.log(e);
            setLoading(false);
            setIsFetching(false);
          });
      } else {
        setIsFetching(false);
      }
    }
  }

  const leaveBoss = useCallback(
    ({ isWin, exp, silver, leave, stage, isSolo, countX }) => {
      setLoading(true);
      setIsFetching(true);

      let number = user.activeBoss.number;

      setPageNumber(number);
      setBossStage(stage);

      exp = Math.floor(exp * countX);
      silver = Math.floor(silver * countX);

      let isUp = 0;
      let currentExpOnLvl = user.currentExpOnLvl;
      let onLvlExpNeed = user.onLvlExpNeed;

      if (isWin) {
        if (currentExpOnLvl + Math.floor(exp) >= onLvlExpNeed) {
          currentExpOnLvl += Math.floor(exp) - onLvlExpNeed;
          onLvlExpNeed = roundLvlExp(
            onLvlExpNeed *
              (user.lvl > 100
                ? 1.1
                : user.lvl > 150
                ? 1.05
                : user.lvl > 200
                ? 1.02
                : 1.2)
          );
          isUp = 1;
          while (currentExpOnLvl >= onLvlExpNeed) {
            currentExpOnLvl -= onLvlExpNeed;
            onLvlExpNeed = roundLvlExp(
              onLvlExpNeed *
                (user.lvl > 100
                  ? 1.1
                  : user.lvl > 150
                  ? 1.05
                  : user.lvl > 200
                  ? 1.02
                  : 1.2)
            );
            isUp++;
          }
          app.service("users").removeListener("timer");
        } else {
          currentExpOnLvl += Math.floor(exp);
        }
      }

      const characteristicInfo = {};
      Object.entries(user.characteristic).forEach((elem) => {
        characteristicInfo[elem[0]] = {
          ...elem[1],
          lvl: (user.lvl + isUp) * 5,
        };
      });

      let bossSoul =
        isWin && number < bossesArray.length - 1
          ? [`bosses.${number + 1}.souls`]
          : "sex";
      let soulCount =
        isWin && number < bossesArray.length - 1
          ? (user.bosses[number + 1]?.souls || 0) + (countX || 1)
          : user.sex;

      const scales = {};
      const grindValue = grindData["book"].bonus[user.grind["book"] - 1] / 100;
      user.damageTalents.characteristicTalents.forEach((elem) => {
        scales[elem.property] =
          1 + grindValue + Math.round(elem.step * elem.countDone * 100) / 10000;
      });

      const hp = Math.floor(
        Object.values(characteristicInfo.health).reduce((acc, cur, i) => {
          if (i < 2) {
            return acc + cur * 5 * scales.health;
          }
          return acc + cur * 5;
        }, 0)
      );

      let goldUp = 0;
      for (let step = 0; step < isUp; step++) {
        goldUp += user.lvl + step + 1;
      }

      const points = isWin
        ? (number + 1) * (stage + 1) * (isSolo ? 2 + number : 1) * (countX || 1)
        : 0;

      app
        .service("users")
        .patch(
          user._id,
          {
            $inc: {
              weakBossesPoints: points,
              leaveBoss: leave ? 1 : 0,
              [`bosses.${number}.totalKills`]: isWin ? countX || 1 : 0,
              [`bosses.${number}.total2Kills`]:
                isWin && stage === 1 ? countX || 1 : 0,
              [`bosses.${number}.total3Kills`]:
                isWin && stage === 2 ? countX || 1 : 0,
              [`bosses.${number}.soloKills`]: isWin && isSolo ? countX || 1 : 0,
              [`bosses.${number}.solo2Kills`]:
                isWin && isSolo && stage === 1 ? countX || 1 : 0,
              [`bosses.${number}.solo3Kills`]:
                isWin && isSolo && stage === 2 ? countX || 1 : 0,
              allExp: isWin ? Math.floor(exp) : 0,
              lvl: isUp,
              gold: goldUp,
              silver: isWin ? Math.floor(silver) : 0,
              currentEnergy:
                isUp &&
                user.currentEnergy <
                  user.damageTalents.simpleTalents.maxValueEnergy +
                    grindData["owl"].bonus[user.grind["owl"] - 1]
                  ? user.damageTalents.simpleTalents.maxValueEnergy +
                    grindData["owl"].bonus[user.grind["owl"] - 1] -
                    user.currentEnergy
                  : 0,
              "newQuest.results.bossesWins":
                isWin && number === 0 ? countX || 1 : 0,
              "dayQuest.bossesWins": isWin ? countX || 1 : 0,
              "dayQuest.bossesSoloWins": isWin && isSolo ? countX || 1 : 0,
              "spells.points": isUp,
              "spells.pointsAll": isUp,
            },
            "activeBoss.damageTop": {},
            "activeBoss.type": "",
            "activeBoss.isFirst": true,
            "activeBoss.isShare": false,
            "activeBoss.damageInfo": [],
            "activeBoss.damageLog": [],
            "activeBoss.number": -1,
            "activeBoss.treasure": {
              lvl: 0,
              count: 0,
            },
            [bossSoul]: soulCount,
            currentExpOnLvl: currentExpOnLvl,
            onLvlExpNeed: onLvlExpNeed,
            health: {
              current: isUp ? hp : user.health.current,
              max: hp,
            },
            characteristic: characteristicInfo,
            field: serverTime,
          },
          {
            query: {
              $select: [
                "_id",
                "email",
                "leaveBoss",
                "currentEnergy",
                "bosses",
                "activeBoss",
                "currentExpOnLvl",
                "onLvlExpNeed",
                "health",
                "characteristic",
                "allExp",
                "lvl",
                "silver",
                "gold",
                "dayQuest",
                "spells",
                "weakBossesPoints",
                "newQuest",
              ],
            },
          }
        )
        .then((data) => {
          setIsModalOpen(false);
          setUser((prev) => ({ ...prev, ...data }));
          setIsFetching(false);
          setTimeout(() => {
            setLoading(false);
          }, 250);
          if (isUp) {
            setIsLvlUpOpen(true);
          }

          const tasks = [];

          if (isWin) {
            randomBossClothes(number, stage, isSolo);

            tasks.push({ property: "bossesWins", count: countX || 1 });
            if (isSolo) {
              tasks.push({ property: "bossesSoloWins", count: countX || 1 });
            }
            if (stage === 1) {
              tasks.push({ property: "bossesStage1Wins", count: countX || 1 });
            }
            if (stage === 2) {
              tasks.push({ property: "bossesStage2Wins", count: countX || 1 });
            }
          }

          if (tasks.length) {
            warOrdenTask(tasks);
          }
        })
        .catch((e) => {
          setModalError(e);
          console.log(e);
          setIsFetching(false);
          setIsModalOpen(false);
          setLoading(false);
        });
    },
    [user, serverTime]
  );

  function changeStage(number) {
    setBossStage(number);
  }

  return (
    <>
      {!(user.activeBoss.number > -1 && user.activeBoss.type === "dungeon") && (
        <>
          <TopPart>
            <img src={imgTop} alt="интерфейс" />
          </TopPart>

          <TopButtonFull>
            <Button width={130} onClick={changeOpenMap.bind(null, true)}>
              <div>Соседние локации</div>
            </Button>
          </TopButtonFull>
        </>
      )}

      {user.activeBoss.number > -1 && user.activeBoss.type === "dungeon" ? (
        <>
          <BossInterface bossLog={bossLog} />
          <CentredWrapper>
            <ActiveBoss
              {...user.bosses[user.activeBoss.number]}
              {...bossesArray[user.activeBoss.number][user.activeBoss.stage]}
              {...user.activeBoss}
              classType={
                bossesArray[user.activeBoss.number][user.activeBoss.stage]
                  .classType
              }
              photos={photos}
              bossExpScale={
                user.damageTalents.simpleTalents[
                  bossesTalents.bossExpScale[
                    bossesArray[user.activeBoss.number][user.activeBoss.stage]
                      .classType
                  ]
                ]
              }
              bossSilverScale={
                user.damageTalents.simpleTalents[
                  bossesTalents.bossSilverScale[
                    bossesArray[user.activeBoss.number][user.activeBoss.stage]
                      .classType
                  ]
                ]
              }
              bossKritChance={
                bossesArray[user.activeBoss.number][user.activeBoss.stage]
                  .bossKritChance
              }
              bossEvasionChance={
                bossesArray[user.activeBoss.number][user.activeBoss.stage]
                  .bossEvasionChance
              }
              number={user.activeBoss.number}
              isFetching={isFetching}
              setIsFetching={setIsFetching}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              serverTime={serverTime}
              setUser={setUser}
              leaveBoss={leaveBoss}
              app={app}
              user={user}
              friends={friends}
              setModalError={setModalError}
              allUsers={allUsers}
              useItem={useItem}
              setBossLog={setBossLog}
              bossLog={bossLog}
              bossesInfo={bossesInfo}
              setBossesInfo={setBossesInfo}
              ordenBonus={ordenBonus}
            />
          </CentredWrapper>
        </>
      ) : (
        <S.Content>
          <S.BookPages>
            <div>
              <S.Text size={18}>
                {bossesArray[pageNumber][bossStage].name}
              </S.Text>

              <S.Text size={14}>
                Класс: {types[bossesArray[pageNumber][bossStage].classType]}
              </S.Text>

              <S.Picture
                src={photos[pageNumber]}
                width={230}
                height={159}
                alt=""
              />

              <S.Text>{bossesArray[pageNumber][bossStage].string}</S.Text>

              <S.Line />

              <S.Columns2>
                <div>
                  Лимит нападения:
                  <span>
                    {user.bosses[pageNumber]?.countAttacks?.done || 0}/
                    {10 + bossesLimit}
                  </span>
                </div>
                <div>
                  Сброс лимита:<span>{timeToNext}</span>
                </div>
              </S.Columns2>

              <S.ArrowBack
                disabled={pageNumber === 0}
                onClick={changePageNumber.bind(null, false)}
                src={imgArrow}
                width={60}
                height={37}
                alt="стрелка"
              />
            </div>
            <div>
              <S.Stages>
                {bossesStages.map((elem, i) => {
                  return (
                    <S.MarkerButtonStyled
                      key={i}
                      onClick={changeStage.bind(null, i)}
                      disabled={bossStage === i}
                      size={12}
                    >
                      <div>{elem}</div>
                    </S.MarkerButtonStyled>
                  );
                })}
              </S.Stages>

              <S.Text>Характеристики:</S.Text>

              <S.Columns4>
                <div>
                  Здоровье:
                  <span>
                    {roundProperty(
                      bossesArray[pageNumber][bossStage].allHp
                    ).toLocaleString("ru")}
                  </span>
                </div>
                <div>
                  Урон:
                  <span>
                    {roundProperty(
                      bossesArray[pageNumber][bossStage].attack
                    ).toLocaleString("ru")}
                  </span>
                </div>
                <div>
                  Крит:
                  <span>
                    {bossesArray[pageNumber][bossStage].bossKritChance}%
                  </span>
                </div>
                <div>
                  Уклонение:
                  <span>
                    {bossesArray[pageNumber][bossStage].bossEvasionChance}%
                  </span>
                </div>
              </S.Columns4>

              <S.Line />

              <S.ButtonsWrapper>
                <S.MarkerButtonStyled
                  disabled={!solo}
                  width={90}
                  size={12}
                  onClick={
                    !isFetching && !loading
                      ? changeSolo.bind(null, false)
                      : null
                  }
                >
                  <div>Совместно</div>
                </S.MarkerButtonStyled>
                <S.MarkerButtonStyled
                  disabled={solo}
                  width={90}
                  size={12}
                  onClick={
                    !isFetching && !loading ? changeSolo.bind(null, true) : null
                  }
                >
                  <div>Лично</div>
                </S.MarkerButtonStyled>
              </S.ButtonsWrapper>

              <S.Text>Награда:</S.Text>

              <S.Rewards>
                <RewardWrapper light={true} w={54}>
                  <div>
                    <Reward
                      name={"exp"}
                      count={Math.floor(
                        bossesArray[pageNumber][bossStage].rewards.exp *
                          (bossExpScale > 0 ? 1 + bossExpScale / 100 : 1) *
                          (solo ? 3 : 1) *
                          (countAttacks || 1)
                      )}
                      w={18}
                      showCount={true}
                      round={true}
                      font={12}
                      reverseColumn={true}
                      color={"#281707"}
                    />
                  </div>
                </RewardWrapper>

                <RewardWrapper light={true} w={54}>
                  <div>
                    <Reward
                      name={"silver"}
                      count={Math.floor(
                        bossesArray[pageNumber][bossStage].rewards.silver *
                          (bossSilverScale > 0
                            ? 1 + bossSilverScale / 100
                            : 1) *
                          (solo ? 3 : 1) *
                          (countAttacks || 1)
                      )}
                      w={18}
                      showCount={true}
                      round={true}
                      font={12}
                      reverseColumn={true}
                      color={"#281707"}
                    />
                  </div>
                </RewardWrapper>

                {pageNumber < bossesArray.length - 1 && (
                  <RewardWrapper light={true} w={54}>
                    <div>
                      <Reward
                        name={"soul"}
                        soul={pageNumber + 1}
                        count={countAttacks || 1}
                        w={18}
                        showCount={true}
                        round={true}
                        font={12}
                        reverseColumn={true}
                        color={"#281707"}
                      />
                    </div>
                  </RewardWrapper>
                )}
              </S.Rewards>

              <S.Line />

              {user.health.max >
                bossesArray[pageNumber][bossStage].attack * 2 &&
              (bossStage === 0 ||
                (bossStage === 1 &&
                  (user.bosses[pageNumber]?.totalKills || 0) >= 100) ||
                (bossStage === 2 &&
                  (user.bosses[pageNumber]?.totalKills || 0) >= 500)) ? (
                (user.bosses[pageNumber]?.countAttacks?.done || 0) >=
                10 + bossesLimit ? (
                  <S.Limits>
                    <S.Text>Исчерпан лимит для нападения</S.Text>
                    <S.MarkerButtonStyled size={12} onClick={handleClearLimits}>
                      <div>Сбросить лимиты</div>
                    </S.MarkerButtonStyled>
                  </S.Limits>
                ) : (
                  <>
                    {pageNumber > 0 ? (
                      <>
                        <S.Text>Требуется для нападения</S.Text>

                        <RewardWrapper light={true} w={54}>
                          <div>
                            <Reward
                              name={"soul"}
                              soul={pageNumber}
                              count={`${roundConsumablesFixed(
                                user.bosses[pageNumber]?.souls || 0
                              )}/${
                                bossesArray[pageNumber][bossStage].soulsNeed *
                                (countAttacks || 1)
                              }`}
                              w={18}
                              font={12}
                              reverseColumn={true}
                              color={"#281707"}
                            />
                          </div>
                        </RewardWrapper>
                      </>
                    ) : (
                      <>
                        <S.Text>Ничего не требуется для нападения</S.Text>

                        <RewardWrapper light={true} w={54}>
                          <div>
                            <Reward
                              name={""}
                              count={`-`}
                              w={24}
                              font={18}
                              reverseColumn={true}
                              color={"#281707"}
                              button={true}
                            />
                          </div>
                        </RewardWrapper>
                      </>
                    )}

                    {timeHelp && (
                      <ButtonChangeWrapper>
                        <ButtonChange
                          islast={true}
                          onClick={changeCount.bind(null, true)}
                        >
                          <div>+1</div>
                        </ButtonChange>
                        <ButtonValueSmall islast={false}>
                          <InputAttacks>
                            <input
                              value={countAttacks}
                              onChange={handleInput}
                              type="number"
                              max={50}
                              min={1}
                              placeholder={""}
                              step={1}
                            />
                          </InputAttacks>
                        </ButtonValueSmall>
                        <ButtonChange
                          islast={false}
                          onClick={changeCount.bind(null, false)}
                        >
                          <div>-1</div>
                        </ButtonChange>
                      </ButtonChangeWrapper>
                    )}

                    {(user.bosses[pageNumber]?.souls || 0) <
                    bossesArray[pageNumber][bossStage].soulsNeed *
                      (countAttacks || 1) ? (
                      <ButtonsWrapper>
                        <MarkerButtonStyled
                          width={120}
                          size={12}
                          onClick={handleAltarOpen}
                        >
                          <div>Обменять души</div>
                        </MarkerButtonStyled>
                        <MarkerButtonStyled
                          width={120}
                          size={12}
                          onClick={() => setActiveMenuModal("shop")}
                        >
                          <div>В магазин</div>
                        </MarkerButtonStyled>
                      </ButtonsWrapper>
                    ) : (
                      <ButtonsWrapper>
                        <MarkerButtonStyled
                          width={120}
                          size={12}
                          onClick={!isFetching && !loading ? attackBoss : null}
                        >
                          <div>Начать бой</div>
                        </MarkerButtonStyled>
                      </ButtonsWrapper>
                    )}

                    <S.Text>
                      Получено предметов: {clothesOpen[0]}/{clothesOpen[1]}
                    </S.Text>
                  </>
                )
              ) : (
                <S.Block>
                  <S.Text>Требования для открытия Босса:</S.Text>
                  {bossStage === 0 ? (
                    <S.TextBlock
                      isBlock={
                        user.health.max < bossesArray[pageNumber][1].attack * 2
                      }
                    >
                      Не менее{" "}
                      {roundProperty(
                        bossesArray[pageNumber][0].attack * 2
                      ).toLocaleString("ru")}{" "}
                      здоровья
                    </S.TextBlock>
                  ) : bossStage === 1 ? (
                    <>
                      <S.TextBlock
                        isBlock={
                          user.health.max <
                          bossesArray[pageNumber][1].attack * 2
                        }
                      >
                        Не менее{" "}
                        {roundProperty(
                          bossesArray[pageNumber][1].attack * 2
                        ).toLocaleString("ru")}{" "}
                        здоровья
                      </S.TextBlock>

                      <S.TextBlock
                        isBlock={
                          (user.bosses[pageNumber]?.totalKills || 0) < 100
                        }
                      >
                        Одержать победу над Боссом:{" "}
                        {user.bosses[pageNumber]?.totalKills || 0}/100
                      </S.TextBlock>
                    </>
                  ) : (
                    <>
                      <S.TextBlock
                        isBlock={
                          user.health.max <
                          bossesArray[pageNumber][2].attack * 2
                        }
                      >
                        Не менее{" "}
                        {roundProperty(
                          bossesArray[pageNumber][2].attack * 2
                        ).toLocaleString("ru")}{" "}
                        здоровья
                      </S.TextBlock>

                      <S.TextBlock
                        isBlock={
                          (user.bosses[pageNumber]?.totalKills || 0) < 500
                        }
                      >
                        Одержать победу над Боссом:{" "}
                        {user.bosses[pageNumber]?.totalKills || 0}/500
                      </S.TextBlock>
                    </>
                  )}
                </S.Block>
              )}

              <S.ArrowNext
                disabled={pageNumber === bossesArray.length - 1}
                onClick={changePageNumber.bind(null, true)}
                src={imgArrow}
                width={60}
                height={37}
                alt="стрелка"
              />
            </div>
          </S.BookPages>
          <S.Tongue src={imgTongue} width={97} height={238} alt="язык" />
        </S.Content>
      )}

      {domReady &&
      !(user.activeBoss.number > -1 && user.activeBoss.type === "dungeon")
        ? createPortal(
            <MenuRight>
              <MenuElemRight name={"Резервация"}>
                <div onClick={changeHelperOpen.bind(null, true)} />
                <img
                  width={80}
                  height={80}
                  src={helperIcon}
                  alt={"резервация"}
                />
              </MenuElemRight>
            </MenuRight>,
            document.querySelector("#menuRight")
          )
        : null}

      <Popup
        isOpen={helperOpen}
        setIsOpen={setHelperOpen}
        w={436}
        h={320}
        back={frame6}
      >
        <Close onClick={changeHelperOpen.bind(null, false)} />
        <b>Резервация Боссов</b>
        <span>Доступны иксовки для нападения на Боссов</span>
        <span>
          Доступны все лимиты Соло Боссов
          <br />
          (сбрасываются в конце игрового дня)
        </span>
        {timeHelp && (
          <>
            <span>Оставшееся время резервации:</span>
            <span>{timeHelp}</span>
          </>
        )}
        <BottomPrice>
          {timeHelp ? (
            <span>Желаете продлить резервацию?</span>
          ) : (
            <span>Доступные варианты для резервации:</span>
          )}

          <BottomPrices>
            <div>
              <span>3 суток</span>
              <Button
                width={100}
                onClick={buyHelper.bind(null, { days: 3, price: 10 })}
              >
                <div>10 голосов</div>
              </Button>
            </div>
            <div>
              <span>7 суток</span>
              <Button
                width={100}
                onClick={buyHelper.bind(null, { days: 7, price: 15 })}
              >
                <div>15 голосов</div>
              </Button>
            </div>
            <div>
              <span>30 суток</span>
              <Button
                width={100}
                onClick={buyHelper.bind(null, { days: 30, price: 50 })}
              >
                <div>50 голосов</div>
              </Button>
            </div>
          </BottomPrices>
        </BottomPrice>
      </Popup>

      <Popup
        isOpen={clearLimitOpen}
        setIsOpen={setClearLimitOpen}
        w={317}
        h={217}
        back={frame3}
      >
        <Close onClick={handleClearLimits} />
        <b>Желаете сбросить все лимиты в бестиарии?</b>
        <span>Доступно в сутки: {1 - (user.bossLimitClear || 0)}/1</span>
        <Button
          disabled={loading || isFetching}
          onClick={!loading && !isFetching ? clearLimits : null}
        >
          <Reward button={true} name={"gold"} count={"Сбросить 500"} w={15} />
        </Button>
      </Popup>

      <Popup
        isOpen={bossRandomReward}
        setIsOpen={setBossRandomReward}
        w={317}
        h={217}
        back={frame3}
      >
        <Close onClick={changeBossReward.bind(null, null)} />
        <b>Разблокирован предмет</b>
        {bossRandomReward &&
          (bossRandomReward.category === "broom" ||
          bossRandomReward.category === "wand" ? (
            <img
              width={90}
              height={90}
              src={require(`../../img/clothes/${bossRandomReward.category}${
                bossRandomReward.lvl >= 1 ? `${bossRandomReward.lvl + 1}` : ""
              }/${bossRandomReward.src}`)}
              alt={bossRandomReward.category}
            />
          ) : (
            <img
              width={90}
              height={90}
              src={require(`../../img/clothes/${bossRandomReward.category}${
                bossRandomReward.lvl >= 1 ? `${bossRandomReward.lvl + 1}` : ""
              }/${user.sex}/${bossRandomReward.src}`)}
              alt={bossRandomReward.category}
            />
          ))}
      </Popup>

      <Popup
        isOpen={isOpenMap}
        setIsOpen={changeOpenMap}
        w={900}
        h={340}
        back={noteImg}
      >
        <CloseNote onClick={changeOpenMap.bind(null, false)}>
          <img width={36} height={36} src={closeImg} alt="" />
          <img width={36} height={36} src={closeHoverImg} alt="" />
        </CloseNote>
        {locationsOpenArray.length > 0 && (
          <>
            <NameNote>Боссы</NameNote>

            <ArrowPagePrev
              disabled={notePage - 1 < 0}
              onClick={notePage - 1 >= 0 ? changeNotePage.bind(null, -1) : null}
            >
              <img width={56} height={56} src={pageArrowImg} alt="" />
              <img width={56} height={56} src={pageArrowHoverImg} alt="" />
            </ArrowPagePrev>

            <ArrowPage
              disabled={notePage >= locationsOpen.length - 2}
              onClick={
                notePage + 1 <= locationsOpen.length - 2
                  ? changeNotePage.bind(null, 1)
                  : null
              }
            >
              <img width={56} height={56} src={pageArrowImg} alt="" />
              <img width={56} height={56} src={pageArrowHoverImg} alt="" />
            </ArrowPage>

            <LocationsNote>
              {locationsOpenArray.map(
                ({ src, srcHover, location, name, type }, i) => {
                  return (
                    <LocationNote
                      key={name}
                      type={type}
                      location={location}
                      onClick={
                        !isFetching && location
                          ? moveLocation.bind(null, location)
                          : null
                      }
                    >
                      <img width={300} height={233} src={src} alt={name} />

                      {srcHover && (
                        <img
                          width={300}
                          height={233}
                          src={srcHover}
                          alt={name}
                        />
                      )}
                    </LocationNote>
                  );
                }
              )}
            </LocationsNote>
          </>
        )}
      </Popup>
    </>
  );
}

export default React.memo(Bosses);
