import React, { useCallback, useEffect, useRef, useState } from "react";

import * as S from "./ArenaFight.styles";
import Popup from "../../components/Popup/Popup";
import { motion } from "framer-motion";
import { Close } from "../../App.styles";
import Reward from "../../components/Reward/Reward";
import { grindData } from "../../info/data";
import { RewardWrapper } from "../DayReward/DayReward.styles";
import frame3 from "../../img/Frame3.png";
import { ButtonsWrapper } from "../ActiveBoss/ActiveBoss.styles";
import { TopButton } from "../../panels/Tops/Tops.styles";
import vectorImg from "../../img/ascaban/fight/Vector.png";
import {
  Avatar,
  Button,
  ButtonValue,
  Lvl,
  Name,
} from "../../panels/Arena/Arena.styles";
import { CharactersInfo } from "../ArenaInfo/ArenaInfo.styles";
import {
  BottomButton,
  BottomVector,
} from "../AscabanFight/AscabanFight.styles";

function popal(player, enemy) {
  const yklonenie = (
    3 +
    (enemy.evasion / (player.accuracy + enemy.evasion) - 0.5) * 250
  ).toFixed(1);

  let popadanie = 100 - yklonenie;
  if (popadanie > 97) {
    popadanie = 97;
  }
  if (popadanie < 30) {
    popadanie = 30;
  }
  const rand = (Math.random() * 100).toFixed(1);
  return popadanie - rand > 0;
}

function probil(player, enemy) {
  const block = (
    3 +
    (enemy.protection / (player.power + enemy.protection) - 0.5) * 250
  ).toFixed(1);
  let probitie = 100 - block;

  if (probitie > 97) {
    probitie = 97;
  }
  if (probitie < 30) {
    probitie = 30;
  }
  const rand = (Math.random() * 100).toFixed(1);
  return probitie - rand > 0;
}

function krit(player, enemy) {
  const krit = (
    3 +
    (player.power / (player.power + enemy.protection) - 0.5) * 250
  ).toFixed(1);
  let probitie = 100 - krit;
  if (probitie > 97) {
    probitie = 97;
  }
  if (probitie < 30) {
    probitie = 30;
  }
  const rand = (Math.random() * 100).toFixed(1);
  return probitie - rand <= 0;
}

function dealDamage(player) {
  const damage = player.force * 0.65;
  const rand1 = Math.floor(Math.random() * 11);
  const rand2 = Math.floor(Math.random() * 2);
  if (rand2) {
    return Math.floor(damage * (1 + rand1 * 0.01));
  } else {
    return Math.floor(damage * (1 - rand1 * 0.01));
  }
}

function ArenaFight({
  user,
  app,
  setUser,
  isFetching,
  isModalOpen,
  setIsModalOpen,
  serverTime,
  setModalError,
  isLoading,
  setDisableMove,
  setIsFetching,
  setIsLoading,
  swapOpponents,
  warOrdenTask,
  warOrdenDayTask,
  ordenBonus,
}) {
  const [isStatistic, setIsStatistic] = useState(false);
  const [winEssence, setWinEssence] = useState(0);
  const [isWin, setIsWin] = useState(false);
  const [log, setLog] = useState([]);
  const intervalRef = useRef(null);
  const scrollRef = useRef(null);

  useEffect(() => {
    if (
      !user.arena.isOver &&
      (user.arena.enemy?.hp <= 0 || user.arena.me?.hp <= 0)
    ) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
      const winsChance =
        user.arena.countFights > 10
          ? Math.floor(user.arena.countWins / user.arena.countFights)
          : 0.5;
      const grindValue =
        1 + grindData["cup"].bonus[user.grind["cup"] - 1] / 100;
      setWinEssence({
        bonus: Math.floor(
          (user.arena.enemy.sum * 0.7 +
            user.arena.me.lvl * 20 +
            user.arena.countFights * winsChance) *
            grindValue *
            (1 + (ordenBonus?.essence?.count || 0) / 100) *
            user.arena.countX || 1
        ),
        clear: Math.floor(
          (user.arena.enemy.sum * 0.7 +
            user.arena.me.lvl * 20 +
            user.arena.countFights * winsChance) *
            grindValue *
            user.arena.countX || 1
        ),
      });
      setIsWin(user.arena.enemy.hp <= 0);
      setIsModalOpen(true);
    }

    if (
      !user.arena.isOver &&
      !intervalRef.current &&
      user.arena.enemy?.hp > 0 &&
      user.arena.me?.hp > 0
    ) {
      fight(1600);
    }
  }, [user]);

  function endFight() {
    setIsFetching(true);
    setIsLoading(true);

    if (user.arena.me.name) {
      app.service("users").arenaFight({
        isWin: isWin,
        essence: isWin ? winEssence?.bonus : 0,
        me: { id: user.arena.me.id },
        enemy: { id: user.arena.enemy.id },
      });
    }

    app
      .service("users")
      .patch(
        user._id,
        {
          "arena.endFightTime": serverTime,
          "arena.isOver": true,
          "arena.me": {},
          "arena.enemy": {},
          $inc: {
            weakEssence: isWin ? winEssence?.clear : 0,
            essence: isWin ? winEssence?.bonus : 0,
            "arena.countDayFight": user.arena.countX || 1,
            "arena.countHourFight": user.arena.countX || 1,
            "arena.countFights": user.arena.countX || 1,
            "arena.countWins": isWin ? user.arena.countX || 1 : 0,
            "newQuest.results.arenaWins": isWin ? user.arena.countX || 1 : 0,
            "summerSave.results.arenaFight": user.arena.countX || 1,
            "summerSave.results.arenaWins": isWin ? user.arena.countX || 1 : 0,
            "dayQuest.arenaWins": isWin ? user.arena.countX || 1 : 0,
            "raidActiveTask.tasks.arenaWins": isWin
              ? user.arena.countX || 1
              : 0,
          },
          $push: {
            arenaHistory: {
              $each: [
                {
                  time: serverTime,
                  isWin: isWin,
                  essence: isWin ? winEssence?.bonus : 0,
                  me: { id: user.arena.me.id },
                  enemy: { id: user.arena.enemy.id },
                },
              ],
              $slice: -20,
            },
          },
          field: serverTime,
        },
        {
          query: {
            $select: [
              "_id",
              "email",
              "arenaHistory",
              "arena",
              "essence",
              "dayQuest",
              "newQuest",
              "summerSave",
              "weakEssence",
              "raidActiveTask",
            ],
          },
        }
      )
      .then((data) => {
        setWinEssence(0);
        setIsModalOpen(false);
        setDisableMove(false);
        setUser((prev) => ({ ...prev, ...data }));
        setIsStatistic(false);
        setIsFetching(false);
        setIsLoading(false);
        swapOpponents();

        const tasks = [
          { property: "arenaFight", count: user.arena.countX || 1 },
        ];
        const tasksDay = [];
        if (isWin) {
          tasks.push({ property: "arenaWins", count: user.arena.countX || 1 });
          tasksDay.push({
            property: "arenaWins",
            count: user.arena.countX || 1,
          });
        }
        warOrdenTask(tasks);
        warOrdenDayTask(tasksDay);
      })
      .catch((e) => {
        setModalError(e);
        console.log(e);
        setIsModalOpen(false);
        setIsStatistic(false);
        setIsFetching(false);
        setIsLoading(false);
        setDisableMove(false);
      });
  }

  function move(player, enemy, isMe) {
    if (player.hp > 0 && enemy.hp > 0) {
      if (popal(player, enemy)) {
        if (probil(player, enemy)) {
          if (krit(player, enemy)) {
            const damage = dealDamage(player) * 2;
            if (isMe) {
              setLog((prev) => [
                {
                  string: `Вы нанесли крит ${damage.toLocaleString("ru")}`,
                  isMe: true,
                },
                ...prev,
              ]);
            } else {
              setLog((prev) => [
                {
                  string: `Противник нанёс крит ${damage.toLocaleString("ru")}`,
                  isMe: false,
                },
                ...prev,
              ]);
            }
            enemy.hp -= damage > enemy.hp ? enemy.hp : damage;
          } else {
            const damage = dealDamage(player);
            if (isMe) {
              setLog((prev) => [
                {
                  string: `Вы нанесли ${damage.toLocaleString("ru")} урона`,
                  isMe: true,
                },
                ...prev,
              ]);
            } else {
              setLog((prev) => [
                {
                  string: `Противник нанёс ${damage.toLocaleString(
                    "ru"
                  )} урона`,
                  isMe: false,
                },
                ...prev,
              ]);
            }
            enemy.hp -= damage > enemy.hp ? enemy.hp : damage;
          }
        } else {
          if (isMe) {
            setLog((prev) => [
              { string: "Вы не пробили блок", isMe: true },
              ...prev,
            ]);
          } else {
            setLog((prev) => [
              { string: "Противник не пробил блок", isMe: false },
              ...prev,
            ]);
          }
        }
      } else {
        if (isMe) {
          setLog((prev) => [
            { string: "Вы промахнулись заклинанием", isMe: true },
            ...prev,
          ]);
        } else {
          setLog((prev) => [
            { string: "Противник промахнулся заклинанием", isMe: false },
            ...prev,
          ]);
        }
      }
    } else {
      if (isMe) {
        setLog((prev) => [
          { string: "Вы не можете продолжать", isMe: true },
          ...prev,
        ]);
      } else {
        setLog((prev) => [
          { string: "Противник не может продолжать", isMe: false },
          ...prev,
        ]);
      }
    }
    return enemy;
  }

  function fight(ms) {
    intervalRef.current = setInterval(() => {
      round(!ms);
    }, ms);
  }

  function round(isFast) {
    let me;
    let enemy;
    if (user.arena.me.speed >= user.arena.enemy.speed) {
      enemy = move(user.arena.me, user.arena.enemy, true);
      me = move(user.arena.enemy, user.arena.me, false);
    } else {
      me = move(user.arena.enemy, user.arena.me, false);
      enemy = move(user.arena.me, user.arena.enemy, true);
    }
    if (isFast) {
      if (user.arena.me.speed >= user.arena.enemy.speed) {
        while (enemy.hp > 0 && me.hp > 0) {
          enemy = move(user.arena.me, user.arena.enemy, true);
          me = move(user.arena.enemy, user.arena.me, false);
        }
      } else {
        while (enemy.hp > 0 && me.hp > 0) {
          me = move(user.arena.enemy, user.arena.me, false);
          enemy = move(user.arena.me, user.arena.enemy, true);
        }
      }
    }

    if (intervalRef.current || isFast) {
      app
        .service("users")
        .patch(
          user._id,
          {
            "arena.me.hp": me.hp,
            "arena.enemy.hp": enemy.hp,
            common: true,
          },
          {
            query: {
              $select: ["_id", "email", "arena"],
            },
          }
        )
        .then((data) => {
          setUser((prev) => ({ ...prev, ...data }));
        })
        .catch((e) => {
          setModalError(e);
          console.log(e);
        });
    }
  }

  const getResults = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = null;
    round(true);
  }, [user]);

  return (
    <React.Fragment>
      <S.FightWrapper>
        <div>
          <S.Opponent faculty={user.arena.me?.faculty}>
            <div>
              <Lvl>
                <span>{user.arena.me?.lvl} уровень</span>
              </Lvl>

              <Avatar>
                <img
                  src={user.arena.me?.img}
                  width={100}
                  height={100}
                  alt={"аватар"}
                />
              </Avatar>

              <Name>
                <span>
                  {user.arena.me?.orden?.tag
                    ? `[${user.arena.me?.orden?.tag}] `
                    : ""}
                </span>
                <span>
                  {user.arena.me?.nickName
                    ? user.arena.me?.nickName
                    : `${user.arena.me?.name?.firstName} ${user.arena.me?.name?.lastName}`}
                </span>
              </Name>

              <CharactersInfo>
                <ButtonValue width={110}>
                  <div>{user.arena.me?.sum?.toLocaleString("ru")}</div>
                </ButtonValue>
              </CharactersInfo>

              <S.HealthInfo>
                <div>
                  <span>{user.arena.me?.hp?.toLocaleString("ru")}</span>
                  <Reward button={true} name={"hp"} w={13} />
                </div>
                <S.ButtonPercent
                  isMe={true}
                  value={
                    ((user.arena.me?.hp || 0) / (user.arena.me?.fullHp || 0)) *
                    100
                  }
                >
                  <div>
                    <div />
                  </div>
                </S.ButtonPercent>
              </S.HealthInfo>
            </div>
          </S.Opponent>
        </div>

        <div>
          <S.LogWrapper ref={scrollRef}>
            {log.length > 0 &&
              log.map((el, i) => {
                return (
                  <motion.div
                    style={{ color: el.isMe ? "#fcc382" : "#ff3232" }}
                    key={log.length - i}
                    initial={{ opacity: 0.3 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ root: scrollRef }}
                  >
                    {el.string}
                  </motion.div>
                );
              })}
            <BottomVector width={220} src={vectorImg} />
          </S.LogWrapper>
        </div>

        <div>
          <S.Opponent faculty={user.arena.enemy?.faculty}>
            <div>
              <Lvl>
                <span>{user.arena.enemy?.lvl} уровень</span>
              </Lvl>

              <Avatar>
                <img
                  src={user.arena.enemy?.img}
                  width={100}
                  height={100}
                  alt={"аватар"}
                />
              </Avatar>

              <Name>
                <span>
                  {user.arena.enemy?.orden?.tag
                    ? `[${user.arena.enemy?.orden?.tag}] `
                    : ""}
                </span>
                <span>
                  {user.arena.enemy?.nickName
                    ? user.arena.enemy?.nickName
                    : `${user.arena.enemy?.name?.firstName} ${user.arena.enemy?.name?.lastName}`}
                </span>
              </Name>

              <S.CharactersInfo>
                <ButtonValue width={110}>
                  <div>{user.arena.enemy?.sum?.toLocaleString("ru")}</div>
                </ButtonValue>
              </S.CharactersInfo>

              <S.HealthInfo>
                <div>
                  <span>{user.arena.enemy?.hp?.toLocaleString("ru")}</span>
                  <Reward button={true} name={"hp"} w={13} />
                </div>
                <S.ButtonPercent
                  isMe={false}
                  value={
                    ((user.arena.enemy?.hp || 0) /
                      (user.arena.enemy?.fullHp || 0)) *
                    100
                  }
                >
                  <div>
                    <div />
                  </div>
                </S.ButtonPercent>
              </S.HealthInfo>
            </div>
          </S.Opponent>
        </div>
      </S.FightWrapper>
      <BottomButton>
        {isStatistic ? (
          <Button
            width={125}
            onClick={!isLoading && !isFetching ? endFight : null}
          >
            <div>Забрать награду</div>
          </Button>
        ) : (
          <Button
            width={125}
            disabled={!intervalRef.current}
            onClick={
              !isLoading && !isFetching && !isModalOpen && intervalRef.current
                ? getResults
                : null
            }
          >
            <div>Завершить досрочно</div>
          </Button>
        )}
      </BottomButton>

      <Popup isOpen={isModalOpen && !isStatistic} w={317} h={217} back={frame3}>
        {!isWin && <Close onClick={!isFetching ? endFight : null} />}
        <b>Результат дуэли: {isWin ? "победа" : "поражение"}</b>
        {isWin && (
          <S.Rewards>
            <RewardWrapper light={false} w={65}>
              <div>
                <Reward
                  name={"essence"}
                  count={winEssence?.bonus}
                  w={28}
                  showCount={true}
                  round={true}
                  font={13}
                  reverseColumn={true}
                  color={"#fdbb54"}
                />
              </div>
            </RewardWrapper>
          </S.Rewards>
        )}
        <ButtonsWrapper>
          <Button
            width={100}
            onClick={() => {
              setIsStatistic(true);
            }}
          >
            <div>Статистика</div>
          </Button>
          <Button width={100} onClick={!isFetching ? endFight : null}>
            <div>{isWin ? "Забрать" : "Выход"}</div>
          </Button>
        </ButtonsWrapper>
      </Popup>
    </React.Fragment>
  );
}

export default React.memo(ArenaFight);
