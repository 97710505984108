import React, { useCallback, useEffect, useRef, useState } from "react";

import * as S from "./ActiveBoss.styles";
import { roundProperty, roundPropertyLow, secondsToHm } from "../../helpers";

import Popup from "../Popup/Popup";
import Spell from "../Spell/Spell";
import Reward from "../Reward/Reward";
import frame1 from "../../img/Frame1.png";
import imgBars from "../../img/boss/HpFrame.png";
import imgHpBar from "../../img/HealthBar.png";
import imgHpBack from "../../img/boss/HpBack.png";
import handImg from "../../img/boss/Hand.png";
import winRibon from "../../img/boss/WinRibon.png";
import loseRibon from "../../img/boss/LoseRibon.png";
import bridge from "@vkontakte/vk-bridge";
import Damage from "../Dagame/Damage";
import DamageTop from "../DamageTop/DamageTop";
import Healls from "../Healls/Healls";
import { Close } from "../../App.styles";
import DamageShadow from "../DagameShadow/DamageShadow";
import {
  allClothes,
  bosses2Array,
  bossesArray,
  bossesStages,
  spellsArray,
} from "../../info/data";
import frame6 from "../../img/Frame6.png";
import {
  BarsHp,
  BarsHpBack,
  BarsPart,
  Rewards,
  Time,
} from "./ActiveBoss.styles";
import { RewardWrapper } from "../DayReward/DayReward.styles";
import frame7 from "../../img/Frame7.png";
import FinishTopBosses from "../FinishTopBosses/FinishTopBosses";
import { Button } from "../../panels/Arena/Arena.styles";
import timeIcon from "../../img/miniIcons/Time.png";
import { createPortal } from "react-dom";
import { MenuElemRight, MenuRight } from "../../panels/Home/Home.styles";
import questsImg from "../../img/menuIcons/NewQuest.png";
import frameQuest from "../../img/RaidBossFrame.png";
import { Header } from "../Ordens/Ordens.styles";
import { Progress } from "../Talents/Talents.styles";
import progressImg from "../../img/boss/Progress.png";
import {
  Bar,
  BoldText,
  Grid2,
  LightText,
} from "../ActiveRaidBoss/ActiveRaidBoss.styles";

const types = {
  entity: "Существо",
  man: "Человек",
  ghost: "Призрак",
};

function ActiveBoss({
  isSolo,
  allHp,
  name,
  attack,
  bossKritChance,
  bossEvasionChance,
  leaveBoss,
  currentHp,
  rewards,
  endTime,
  serverTime,
  app,
  user,
  friends,
  setUser,
  damageInfo,
  damageTop,
  setIsFetching,
  isFetching,
  isModalOpen,
  setIsModalOpen,
  setModalError,
  useItem,
  allUsers,
  isShare,
  stage,
  number,
  bossExpScale,
  bossSilverScale,
  setBossLog,
  bossLog,
  damageLog = [],
  classType,
  bossesInfo,
  setBossesInfo,
  postImage,
  type,
  treasure,
  photos,
  souls = [],
  prizeShyt,
  manaCost = 1,
  ordenBonus,
  countX,
}) {
  const [leftTimeString, setLeftTimeString] = useState("00ч:00м");
  const [leaveBossOpen, setLeaveBossOpen] = useState(false);
  const [endTopInfo, setEndTopInfo] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [bossAuto, setBossAuto] = useState(false);
  const [leftTime, setLeftTime] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [isWin, setIsWin] = useState(false);
  const [currentBossHp, setCurrentBossHp] = useState(
    currentHp <= 0 ? 0 : currentHp
  );
  const [questsOpen, setQuestsOpen] = useState(false);
  const [percent, setPercent] = useState(0);

  const [activePanel, setActivePanel] = useState("log");

  const isEndRef = useRef(false);
  const [domReady, setDomReady] = React.useState(false);

  const currentHpRef = useRef(currentHp);
  const currentSpell = useRef(null);
  const currentHeal = useRef(null);

  useEffect(() => {
    setDomReady(true);
    app.service("users").on("bosses", () => {
      app
        .service("users")
        .get(user._id, {
          query: {
            $select: [
              "_id",
              "email",
              "activeBoss",
              "damageTalents",
              "health",
              "mana",
              "allDamage",
              "weakDamage",
              "dayDamage",
              "raidDamage",
            ],
          },
        })
        .then((data) => {
          setUser((prev) => ({ ...prev, ...data }));
          setIsFetching(false);
        })
        .catch((e) => {
          setIsFetching(false);
          console.log(e);
        });
    });

    return () => {
      app.service("users").removeListener("bosses");
      setLoading(false);
      setIsFetching(false);
    };
  }, []);

  useEffect(() => {
    setPercent(
      (damageTop[user.email] || 0) > allHp * (countX || 1) * 0.2
        ? 1
        : (
            (damageTop[user.email] || 0) /
            (allHp * (countX || 1) * 0.2)
          ).toFixed(3)
    );
  }, [damageTop, allHp]);

  useEffect(() => {
    if (bossAuto) {
      if (!isEnd && user.health.current > 0 && currentSpell.current) {
        currentSpell.current?.click();
      } else if (!isEnd && user.health.current <= 0 && currentHeal.current) {
        currentHeal.current?.click();
      } else if (!isEnd && !currentHeal.current) {
        setBossAuto(false);
      }
    }
  }, [serverTime, user, isEnd, bossAuto]);

  useEffect(() => {
    if (!isEndRef.current && currentHpRef.current > 0 && serverTime) {
      timerFunc(serverTime);
    }
  }, [serverTime]);

  useEffect(() => {
    currentHpRef.current = currentHp;
    if (currentHp <= 0) {
      setCurrentBossHp(0);
      setIsWin(true);
      setIsEnd(true);
    } else {
      setCurrentBossHp(currentHp);
    }
  }, [currentHp]);

  useEffect(() => {
    isEndRef.current = isEnd;
    if (isEnd) {
      setLoading(false);
      setIsFetching(false);
      setIsModalOpen(true);
    }
  }, [isEnd]);

  useEffect(() => {
    if (leftTime <= 0 && leftTime !== null) {
      setIsEnd(true);
      setIsWin(false);
    }
  }, [leftTime]);

  function changeInfoOpen(info) {
    setIsInfoOpen(info);
  }

  function changeEndTop(info) {
    setEndTopInfo(info);
  }

  function timerFunc(serverTime) {
    let secs = Math.floor((endTime - serverTime) / 1000);
    if (secs <= 0) {
      secs = 0;
    }
    setLeftTime(secs);
    setLeftTimeString(secondsToHm(secs));
  }

  const dealDamage = useCallback(
    ({ damage, mana, countAttacks, deffence, bossDamage }) => {
      if (typeof damage === "function") {
        damage = damage();
      }

      const randEvasion = (Math.random() * 100).toFixed(1);
      const isEvasionBoss =
        100 -
          bossEvasionChance *
            (1 - user.damageTalents.simpleTalents.accuracyChance / 100) -
          randEvasion <=
        0;

      const randKrit = (Math.random() * 100).toFixed(1);
      const isKritBoss = 100 - bossKritChance - randKrit <= 0;

      app
        .service("users")
        .dealDamage({
          damage: {
            ...damage,
            value:
              damage.isKrit && !isEvasionBoss
                ? Math.floor(damage.value * damage.krit)
                : damage.value,
          },
          countAttacks: countAttacks,
          friends: [...friends, user.email],
          user: {
            _id: user._id,
            email: user.email,
          },
          mana: mana,
          bossDamage: {
            isKrit: isKritBoss,
            damage: Math.floor(bossDamage * (isKritBoss ? 1.5 : 1)),
          },
          isEvasionBoss: isEvasionBoss,
          deffence: deffence,
          ordenBonus: ordenBonus?.talent,
        })
        .catch((e) => {
          setModalError(e);
          setLoading(false);
          setIsFetching(false);
          console.log(e);
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 700);
        });
    },
    [user, ordenBonus]
  );

  function handleLeaveBoss({ isWin, leave = false }) {
    if (leave && user.leaveBoss >= 5) {
      setLeaveBossOpen(false);
      console.log("Вы сбегали слишком много за сегодня");
      setModalError("Вы сбегали слишком много за сегодня");
    } else {
      leaveBoss({
        isWin,
        ...rewards,
        exp: Math.floor(
          rewards.exp *
            (bossExpScale > 0 ? 1 + bossExpScale / 100 : 1) *
            (isSolo && (type === "dungeon" || type === "dungeon2") ? 3 : 1) *
            (!isSolo && (type === "dungeon" || type === "dungeon2")
              ? 1 + 0.5 * percent * (countX || 1)
              : 1)
        ),
        silver: Math.floor(
          rewards.silver *
            (bossSilverScale > 0 ? 1 + bossSilverScale / 100 : 1) *
            (isSolo && (type === "dungeon" || type === "dungeon2") ? 3 : 1) *
            (!isSolo && (type === "dungeon" || type === "dungeon2")
              ? 1 + 0.5 * percent * (countX || 1)
              : 1)
        ),
        treasure,
        leave,
        stage,
        isSolo,
        souls,
        prizeShyt,
        countX: countX || 1,
      });
    }
  }

  function updateBosses() {
    setIsFetching(true);
    app
      .service("users")
      .get(
        user._id,
        {},
        {
          query: {
            $select: ["_id", "email", "activeBoss"],
          },
        }
      )
      .then((data) => {
        setUser((prev) => ({ ...prev, ...data }));
        setIsFetching(false);
      })
      .catch((e) => {
        setIsFetching(false);
        console.log(e);
      });
  }

  async function addWallPost() {
    setIsFetching(true);
    bridge
      .send("VKWebAppShowStoryBox", {
        background_type: "image",
        url: postImage,
        attachment: {
          text: "game",
          type: `url`,
          url: `https://vk.com/app51651233#${user.email}`,
          owner_id: 221829804,
          id: user.email,
        },
      })
      .then((data) => {
        if (data.result) {
          app
            .service("users")
            .patch(
              user._id,
              {
                "activeBoss.isShare": true,
                $inc: {
                  mana: 50,
                },
                common: true,
              },
              {
                query: {
                  $select: ["_id", "email", "activeBoss", "mana"],
                },
              }
            )
            .then((data) => {
              setUser((prev) => ({ ...prev, ...data }));
              setIsFetching(false);
            })
            .catch((e) => {
              setIsFetching(false);
              console.log(e);
            });
        } else {
          setIsFetching(false);
        }
      })
      .catch((e) => {
        setIsFetching(false);
        console.log(e);
      });
  }

  function changePanel(name) {
    setActivePanel(name);
  }

  function handleQuests() {
    setQuestsOpen((prevState) => !prevState);
  }

  function changeLog() {
    setBossLog((prev) => {
      if (!prev) {
        return "log";
      } else {
        return "";
      }
    });
  }

  function changeAuto() {
    setBossAuto((prev) => !prev);
  }

  return (
    <>
      <BarsPart>
        <img src={imgBars} alt="BossBars" width={358} height={55} />
      </BarsPart>
      <BarsHpBack>
        <img src={imgHpBack} alt="HpBar" width={271} height={22} />
      </BarsHpBack>
      <BarsHp value={(currentBossHp / (allHp * (countX || 1))) * 100}>
        <img src={imgHpBar} alt="HpBar" width={193} height={18} />
        <span>
          {roundProperty(currentBossHp).toLocaleString("ru")}/
          {roundProperty(allHp * (countX || 1)).toLocaleString("ru")}
        </span>
      </BarsHp>

      <Time>
        <img src={timeIcon} alt="время" width={14} />
        <span>{leftTimeString}</span>
      </Time>

      <S.LogOpen>
        <Button
          disabled={bossAuto}
          active={true}
          onClick={changeAuto}
          width={80}
        >
          <div>Автобой</div>
        </Button>
        <Button onClick={changeLog} width={80}>
          <div>{bossLog ? "Бой" : "Лог"}</div>
        </Button>
      </S.LogOpen>

      <S.LogUsers bossLog={bossLog}>
        <Button
          disabled={activePanel === "log"}
          onClick={changePanel.bind(null, "log")}
          width={80}
        >
          <div>Лог</div>
        </Button>
        <Button
          disabled={activePanel === "top"}
          onClick={changePanel.bind(null, "top")}
          width={80}
        >
          <div>Топ</div>
        </Button>
      </S.LogUsers>

      <S.DamageLog bossLog={bossLog}>
        <DamageShadow damageInfo={damageLog} />
      </S.DamageLog>

      <S.LogUsersDamage bossLog={bossLog}>
        {activePanel === "log" && (
          <Damage damageInfo={damageInfo} allUsers={allUsers} />
        )}
        {activePanel === "top" && (
          <DamageTop
            name={user.name}
            nickName={user.nickName}
            orden={user.orden}
            email={user.email}
            damageTop={damageTop}
            allUsers={allUsers}
            allHp={allHp * (countX || 1)}
          />
        )}
      </S.LogUsersDamage>

      <S.InfoBoss>
        <Button
          width={80}
          onClick={
            !loading && !isFetching && !isModalOpen ? updateBosses : null
          }
        >
          <div>Обновить</div>
        </Button>
        <Button width={80} onClick={changeInfoOpen.bind(null, true)}>
          <div>Инфо</div>
        </Button>
        {isEnd && !isWin ? (
          <Button
            width={80}
            onClick={
              !loading && !isFetching && !isModalOpen
                ? () => setLeaveBossOpen(true)
                : null
            }
          >
            <div>Выход</div>
          </Button>
        ) : isEnd && isWin ? (
          <Button
            width={80}
            onClick={
              !loading && !isFetching
                ? handleLeaveBoss.bind(null, { isWin })
                : null
            }
          >
            <div>Выход</div>
          </Button>
        ) : (
          <Button
            width={80}
            onClick={
              !loading && !isFetching && !isModalOpen
                ? () => setLeaveBossOpen(true)
                : null
            }
          >
            <div>Сбежать</div>
          </Button>
        )}
      </S.InfoBoss>

      <S.FirstSpell>
        <S.SpellWrapper>
          <div />
        </S.SpellWrapper>

        <Spell
          currentSpell={currentSpell}
          grind={user.grind}
          useItem={useItem}
          {...spellsArray[classType]}
          mana={Math.floor(
            spellsArray[classType].mana *
              manaCost *
              (1 - user.damageTalents.simpleTalents.manaScale / 100)
          )}
          lvl={user.spells[classType].lvl}
          balance={user.spells[classType].balance}
          userHp={user.health.current}
          userMana={user.mana}
          bossDamage={Math.floor(
            attack *
              (1 - user.damageTalents.simpleTalents.bossDefenceScale / 100)
          )}
          damage={
            spellsArray[classType].damage *
            (1 + user.damageTalents.simpleTalents.bossDamageScale / 100)
          }
          kritChance={user.damageTalents.simpleTalents.kritChance}
          currentHp={currentHp}
          isFetching={isFetching || loading || isEnd}
          isModalOpen={isModalOpen}
          dealDamage={dealDamage}
          force={user.characteristic.force}
          power={user.characteristic.power}
          accuracy={user.characteristic.accuracy}
          setIsFetching={setIsFetching}
          setLoading={setLoading}
          setModalError={setModalError}
          damageTalents={user.damageTalents}
          stockLvl={user.stockLvl}
          bossesInfo={bossesInfo}
          setBossesInfo={setBossesInfo}
          serverTime={serverTime}
        />
      </S.FirstSpell>

      <S.Heal>
        <S.SpellWrapper>
          <div />
        </S.SpellWrapper>
      </S.Heal>

      <S.Heal>
        <Healls
          currentHeal={currentHeal}
          inventory={user.inventoryNew}
          useItem={useItem}
          isFetching={isFetching}
          bossesInfo={bossesInfo}
          setBossesInfo={setBossesInfo}
        />
      </S.Heal>

      <Popup
        isOpen={isInfoOpen}
        setIsOpen={setIsInfoOpen}
        w={436}
        h={320}
        back={frame6}
      >
        <Close onClick={changeInfoOpen.bind(null, false)} />
        <b>Информация о Боссе</b>
        <span>
          Тип: {type === "location" ? "Соло-Босс" : "Босс бестиария"}, Класс:{" "}
          {types[classType]}, Стадия:{" "}
          {stage === 0 ? "Обычный" : stage === 1 ? "Агрессивный" : "Проклятый"},
          Режим: {isSolo ? "Соло" : "Совместный"}
        </span>
        <span>
          Урон:{" "}
          {roundProperty(
            Math.floor(
              attack *
                (1 - user.damageTalents.simpleTalents.bossDefenceScale / 100)
            )
          ).toLocaleString("ru")}
          , Шанс крита: {Math.floor(bossKritChance)}%, Шанс уклонения:{" "}
          {Math.floor(bossEvasionChance)}%
        </span>

        {manaCost > 1 && <span>Стоимость заклинаний: {manaCost * 100}%</span>}

        <span>Награда:</span>

        <Rewards>
          <RewardWrapper light={false} w={65}>
            <div>
              <Reward
                name={"exp"}
                count={Math.floor(
                  rewards.exp *
                    (bossExpScale > 0 ? 1 + bossExpScale / 100 : 1) *
                    (isSolo && type !== "location" ? 3 : 1) *
                    (countX || 1)
                )}
                w={28}
                showCount={true}
                round={true}
                font={13}
                reverseColumn={true}
                color={"#fdbb54"}
              />
            </div>
          </RewardWrapper>
          {rewards.silver && (
            <RewardWrapper light={false} w={65}>
              <div>
                <Reward
                  name={"silver"}
                  count={Math.floor(
                    rewards.silver *
                      (bossSilverScale > 0 ? 1 + bossSilverScale / 100 : 1) *
                      (isSolo && type !== "location" ? 3 : 1) *
                      (countX || 1)
                  )}
                  w={28}
                  showCount={true}
                  round={true}
                  font={13}
                  reverseColumn={true}
                  color={"#fdbb54"}
                />
              </div>
            </RewardWrapper>
          )}
          {rewards.gold && (
            <RewardWrapper light={false} w={65}>
              <div>
                <Reward
                  name={"gold"}
                  count={rewards.gold * (countX || 1)}
                  w={28}
                  showCount={true}
                  round={true}
                  font={13}
                  reverseColumn={true}
                  color={"#fdbb54"}
                />
              </div>
            </RewardWrapper>
          )}

          {type === "dungeon" && number < bossesArray.length - 1 && (
            <RewardWrapper light={false} w={65}>
              <div>
                <Reward
                  name={"soul"}
                  count={countX || 1}
                  soul={number + 1}
                  w={28}
                  showCount={true}
                  round={true}
                  font={13}
                  reverseColumn={true}
                  color={"#fdbb54"}
                />
              </div>
            </RewardWrapper>
          )}

          {type === "dungeon2" && number < bosses2Array.length - 1 && (
            <RewardWrapper light={false} w={65}>
              <div>
                <Reward
                  name={"soul2"}
                  count={countX || 1}
                  soul={number + 1}
                  w={28}
                  showCount={true}
                  round={true}
                  font={13}
                  reverseColumn={true}
                  color={"#fdbb54"}
                />
              </div>
            </RewardWrapper>
          )}
        </Rewards>
      </Popup>

      <Popup
        isOpen={leaveBossOpen}
        setIsOpen={setLeaveBossOpen}
        w={281}
        h={152}
        back={frame1}
      >
        <Close
          onClick={() => {
            setLeaveBossOpen(false);
          }}
        />
        <span>
          {isEnd ? <b>Вы хотите выйти?</b> : <b>Вы хотите сбежать?</b>}
        </span>
        {!isEnd && <span>(доступно сегодня: {5 - user.leaveBoss})</span>}
        <S.ButtonsWrapper>
          {isEnd ? (
            <Button
              onClick={
                !loading && !isFetching
                  ? handleLeaveBoss.bind(null, { isWin })
                  : null
              }
            >
              <div>Выйти</div>
            </Button>
          ) : (
            <Button
              onClick={
                !loading && !isFetching
                  ? handleLeaveBoss.bind(null, { isWin, leave: true })
                  : null
              }
            >
              <div>Сбежать</div>
            </Button>
          )}
        </S.ButtonsWrapper>
      </Popup>

      <Popup
        isOpen={isModalOpen}
        w={706}
        h={420}
        back={frame7}
        justify={"start"}
      >
        <S.EndFightWrapper>
          <img
            src={isWin ? winRibon : loseRibon}
            width={430}
            height={80}
            alt="лента"
          />
          <S.EndLeftPart>
            <img src={photos[number]} width={310} height={214} alt="фото" />

            {isWin && (
              <S.Hand src={handImg} width={140} height={154} alt="кровь" />
            )}

            <S.LoseText>{isWin ? "" : "Сбежал"}</S.LoseText>

            <S.BossName>
              <div>{name}</div>
            </S.BossName>

            <S.EndBossInfo>
              {bossesStages[stage]},{" "}
              {isSolo ? "Соло режим" : "Совместный режим"}
            </S.EndBossInfo>
          </S.EndLeftPart>
          <S.EndRightPart>
            <S.EndButtonsWrapper>
              <Button
                width={120}
                disabled={!endTopInfo}
                onClick={changeEndTop.bind(null, false)}
              >
                <div>Мои результаты</div>
              </Button>
              <Button
                width={120}
                disabled={endTopInfo}
                onClick={changeEndTop.bind(null, true)}
              >
                <div>Топ урона</div>
              </Button>
            </S.EndButtonsWrapper>

            {endTopInfo ? (
              <S.TopInfo>
                <div>Лучшие маги:</div>

                <FinishTopBosses
                  nickName={user.nickName}
                  photo={user.photo}
                  orden={user.orden}
                  name={user.name}
                  email={user.email}
                  damageTop={damageTop}
                  allUsers={allUsers}
                  allHp={allHp * (countX || 1)}
                />
              </S.TopInfo>
            ) : (
              <S.MeInfo>
                <div>Нанесённый урон:</div>

                <S.Avatar>
                  <img src={user.photo} width={70} height={70} alt={"аватар"} />
                </S.Avatar>

                <S.ButtonValue width={70}>
                  <div>
                    {roundPropertyLow(
                      damageTop?.[user.email] || 0
                    ).toLocaleString("ru")}
                  </div>
                </S.ButtonValue>

                {isWin && (
                  <>
                    <div>Ваша награда за бой:</div>

                    <Rewards>
                      <RewardWrapper light={false} w={65}>
                        <div>
                          <Reward
                            name={"exp"}
                            count={Math.floor(
                              rewards.exp *
                                (bossExpScale > 0
                                  ? 1 + bossExpScale / 100
                                  : 1) *
                                (isSolo && type !== "location" ? 3 : 1) *
                                (!isSolo &&
                                (type === "dungeon" || type === "dungeon2")
                                  ? 1 + 0.5 * percent
                                  : 1) *
                                (countX || 1)
                            )}
                            w={28}
                            showCount={true}
                            round={true}
                            font={13}
                            reverseColumn={true}
                            color={"#fdbb54"}
                          />
                        </div>
                      </RewardWrapper>
                      {rewards.silver && (
                        <RewardWrapper light={false} w={65}>
                          <div>
                            <Reward
                              name={"silver"}
                              count={Math.floor(
                                rewards.silver *
                                  (bossSilverScale > 0
                                    ? 1 + bossSilverScale / 100
                                    : 1) *
                                  (isSolo && type !== "location" ? 3 : 1) *
                                  (!isSolo &&
                                  (type === "dungeon" || type === "dungeon2")
                                    ? 1 + 0.5 * percent
                                    : 1) *
                                  (countX || 1)
                              )}
                              w={28}
                              showCount={true}
                              round={true}
                              font={13}
                              reverseColumn={true}
                              color={"#fdbb54"}
                            />
                          </div>
                        </RewardWrapper>
                      )}

                      {rewards.gold && (
                        <RewardWrapper light={false} w={65}>
                          <div>
                            <Reward
                              name={"gold"}
                              count={rewards.gold * (countX || 1)}
                              w={28}
                              showCount={true}
                              round={true}
                              font={13}
                              reverseColumn={true}
                              color={"#fdbb54"}
                            />
                          </div>
                        </RewardWrapper>
                      )}

                      {type === "location" && (treasure?.count || 0) > 0 && (
                        <RewardWrapper light={false} w={65}>
                          <div>
                            <Reward
                              name={"treasure"}
                              count={treasure?.count}
                              treasure={treasure?.lvl}
                              w={28}
                              showCount={true}
                              round={true}
                              font={13}
                              reverseColumn={true}
                              color={"#fdbb54"}
                            />
                          </div>
                        </RewardWrapper>
                      )}

                      {souls?.[0] > -1 && (
                        <RewardWrapper light={false} w={65}>
                          <div>
                            <Reward
                              name={"soul"}
                              count={prizeShyt === 0 ? 5 : 1}
                              soul={souls[0] + 1}
                              w={28}
                              showCount={true}
                              round={true}
                              font={13}
                              reverseColumn={true}
                              color={"#fdbb54"}
                            />
                          </div>
                        </RewardWrapper>
                      )}

                      {souls?.[1] > -1 && (
                        <RewardWrapper light={false} w={65}>
                          <div>
                            <Reward
                              name={"soul"}
                              count={prizeShyt === 0 ? 5 : 1}
                              soul={souls[1] + 1}
                              w={28}
                              showCount={true}
                              round={true}
                              font={13}
                              reverseColumn={true}
                              color={"#fdbb54"}
                            />
                          </div>
                        </RewardWrapper>
                      )}

                      {type === "dungeon" &&
                        number < bossesArray.length - 1 && (
                          <RewardWrapper light={false} w={65}>
                            <div>
                              <Reward
                                name={"soul"}
                                count={countX || 1}
                                soul={number + 1}
                                w={28}
                                showCount={true}
                                round={true}
                                font={13}
                                reverseColumn={true}
                                color={"#fdbb54"}
                              />
                            </div>
                          </RewardWrapper>
                        )}

                      {type === "dungeon2" &&
                        number < bosses2Array.length - 1 && (
                          <RewardWrapper light={false} w={65}>
                            <div>
                              <Reward
                                name={"soul2"}
                                count={countX || 1}
                                soul={number + 1}
                                w={28}
                                showCount={true}
                                round={true}
                                font={13}
                                reverseColumn={true}
                                color={"#fdbb54"}
                              />
                            </div>
                          </RewardWrapper>
                        )}
                    </Rewards>
                  </>
                )}

                <S.EndButtonsWrapper>
                  {isWin && !isShare && (
                    <Button
                      width={120}
                      onClick={!loading && !isFetching ? addWallPost : null}
                    >
                      <S.Rewards>
                        <span>Поделиться</span>
                        <Reward
                          name={"mana"}
                          count={"+50"}
                          w={14}
                          button={true}
                        />
                      </S.Rewards>
                    </Button>
                  )}

                  <Button
                    width={120}
                    onClick={
                      !loading && !isFetching
                        ? handleLeaveBoss.bind(null, { isWin })
                        : null
                    }
                  >
                    <div>{isWin ? "Забрать награду" : "Выход"}</div>
                  </Button>
                </S.EndButtonsWrapper>
              </S.MeInfo>
            )}
          </S.EndRightPart>
        </S.EndFightWrapper>
      </Popup>

      <Popup
        w={443}
        h={317}
        back={frameQuest}
        isOpen={questsOpen}
        setIsOpen={setQuestsOpen}
        justify={"start"}
      >
        <Close onClick={handleQuests} />

        <Header>Дополнительная награда</Header>

        <LightText margin={15}>
          Нанеси минимум 20% урона боссу, чтобы забрать полную награду. В другом
          случае, забери заслуженную часть
        </LightText>

        <BoldText>
          {roundProperty(
            (damageTop[user.email] || 0) > allHp * (countX || 1) * 0.2
              ? allHp * (countX || 1) * 0.2
              : damageTop[user.email] || 0
          ).toLocaleString("ru")}{" "}
          / {roundProperty(allHp * (countX || 1) * 0.2).toLocaleString("ru")}
        </BoldText>

        <Progress percent={(percent * 100).toFixed(2)}>
          <div>
            <img src={progressImg} alt="прогресс" />
            <Bar progress={percent} />
          </div>
        </Progress>

        <Grid2>
          <div>
            <LightText>Текущая награда</LightText>
            <Rewards>
              <RewardWrapper light={false} w={65}>
                <div>
                  <Reward
                    name={"exp"}
                    count={Math.ceil(
                      (rewards?.exp * 0.5 || 0) * percent * (countX || 1)
                    )}
                    w={28}
                    showCount={true}
                    round={true}
                    font={13}
                    reverseColumn={true}
                    color={"#fdbb54"}
                  />
                </div>
              </RewardWrapper>
              <RewardWrapper light={false} w={65}>
                <div>
                  <Reward
                    name={"silver"}
                    count={Math.ceil(
                      (rewards?.silver * 0.5 || 0) * percent * (countX || 1)
                    )}
                    w={28}
                    showCount={true}
                    round={true}
                    font={13}
                    reverseColumn={true}
                    color={"#fdbb54"}
                  />
                </div>
              </RewardWrapper>
            </Rewards>
          </div>
          <div>
            <LightText>Награда за 20%</LightText>

            <Rewards>
              <RewardWrapper light={false} w={65}>
                <div>
                  <Reward
                    name={"exp"}
                    count={Math.ceil(rewards?.exp * 0.5 * (countX || 1) || 0)}
                    w={28}
                    showCount={true}
                    round={true}
                    font={13}
                    reverseColumn={true}
                    color={"#fdbb54"}
                  />
                </div>
              </RewardWrapper>
              <RewardWrapper light={false} w={65}>
                <div>
                  <Reward
                    name={"silver"}
                    count={Math.ceil(
                      rewards?.silver * 0.5 * (countX || 1) || 0
                    )}
                    w={28}
                    showCount={true}
                    round={true}
                    font={13}
                    reverseColumn={true}
                    color={"#fdbb54"}
                  />
                </div>
              </RewardWrapper>
            </Rewards>
          </div>
        </Grid2>
      </Popup>

      {domReady
        ? createPortal(
            (type === "dungeon" || type === "dungeon2") && !isSolo && (
              <MenuRight>
                <MenuElemRight name={"Задание"}>
                  <div onClick={handleQuests} />
                  <img width={80} height={80} src={questsImg} alt={"задание"} />
                </MenuElemRight>
              </MenuRight>
            ),
            document.querySelector("#menuRight")
          )
        : null}
    </>
  );
}

export default React.memo(ActiveBoss);
