import React from "react";

import * as S from "./Reward.styles";
import ticket from "../../img/book/Ticket.png";
import diamond from "../../img/book/Diamond.png";
import prize from "../../img/book/Prize.png";
import talent from "../../img/book/Talent.png";
import gold from "../../img/book/Gold.png";
import silver from "../../img/book/Silver.png";
import exp from "../../img/book/Exp.png";
import energy from "../../img/Energy.png";
import energyPotion from "../../img/EnergyPotion.png";
import essence from "../../img/book/Essence.png";
import mana from "../../img/book/Mana.png";
import hp from "../../img/Health.png";
import manaPotion from "../../img/ManaPotion.png";
import hpPotion from "../../img/HealthPotion.png";
import magic from "../../img/book/MagicPoint.png";
import candy from "../../img/MiniCandy.png";
import diceGame from "../../img/DiceGame.png";
import game21 from "../../img/Game21.png";

import soulArkein from "../../img/souls/Arkein.png";
import soulDementor from "../../img/souls/Dementor.png";
import soulDvehgolovi from "../../img/souls/Dvehgolovi.png";
import soulGrindiloy from "../../img/souls/Grindiloy.png";
import soulHvost from "../../img/souls/Hvost.png";
import soulProklytElf from "../../img/souls/ProklytElf.png";
import soulZyfy from "../../img/souls/Zyfy.png";

import soulAbscur from "../../img/souls/Abscur.png";
import soulBaron from "../../img/souls/Baron.png";
import soulBoggart from "../../img/souls/Boggart.png";
import soulFilch from "../../img/souls/Filch.png";
import soulGrum from "../../img/souls/Grum.png";
import soulMirtl from "../../img/souls/Mirtl.png";
import soulPivz from "../../img/souls/Pivz.png";
import treasure1 from "../../img/Treasure1.png";
import treasure2 from "../../img/Treasure2.png";
import treasure3 from "../../img/Treasure3.png";
import treasure4 from "../../img/Treasure4.png";
import rareClothes from "../../img/games/RareClothes.png";
import commonClothes from "../../img/games/CommonClothes.png";

import { roundConsumablesFixed } from "../../helpers";
import { bosses2Array, bossesArray } from "../../info/data";

const property = {
  diamond: {
    name: "Изумруд",
    src: diamond,
  },

  ticket: {
    name: "Билет",
    src: ticket,
  },

  prize: {
    name: "Подарок",
    src: prize,
  },

  rareClothes: {
    name: "Уникальный предмет",
    src: rareClothes,
  },
  commonClothes: {
    name: "Обычный предмет",
    src: commonClothes,
  },
  diceGame: {
    name: "Игра в кости",
    src: diceGame,
  },
  game21: {
    name: "Игра в 21",
    src: game21,
  },
  talent: {
    name: "Очко навыков",
    src: talent,
  },
  magic: {
    name: "Очко заклинаний",
    src: magic,
  },
  candy: {
    name: "Драже",
    src: candy,
  },
  gold: {
    name: "Золото",
    src: gold,
  },
  silver: {
    name: "Серебро",
    src: silver,
  },
  mana: {
    name: "Мана",
    src: mana,
  },
  exp: {
    name: "Опыт",
    src: exp,
  },
  hp: {
    name: "Здоровье",
    src: hp,
  },

  energy: {
    name: "Энергия",
    src: energy,
  },

  essence: {
    name: "Эссенции",
    src: essence,
  },

  potion: {
    hp: {
      name: "Зелье здоровья",
      src: hpPotion,
    },

    energy: {
      name: "Зелье энергии",
      src: energyPotion,
    },

    mana: {
      name: "Зелье маны",
      src: manaPotion,
    },
  },

  soul: {
    name: "Душа",
    src: [
      "",
      soulPivz,
      soulMirtl,
      soulFilch,
      soulBoggart,
      soulBaron,
      soulAbscur,
      soulGrum,
    ],
  },

  soul2: {
    name: "Душа",
    src: [
      "",
      soulProklytElf,
      soulZyfy,
      soulDvehgolovi,
      soulArkein,
      soulGrindiloy,
      soulHvost,
      soulDementor,
    ],
  },

  treasure: {
    name: "Сундук",
    src: [treasure1, treasure2, treasure3, treasure4],
  },
};

function Reward({
  category,
  potionName,
  soul,
  treasure,
  name,
  count,
  showCount = false,
  round = false,
  w,
  h = "auto",
  g = 2,
  button = false,
  font,
  reverseColumn,
  column,
  color,
  string,
  lineH,
  filter,
}) {
  return (
    <S.Wrapper
      count={count && count.toLocaleString("ru")}
      showCount={showCount}
      g={g}
      filter={filter}
      name={
        name === "potion"
          ? `${property?.[name][category].name} ${potionName}`
          : treasure >= 0
          ? `${property?.[name].name} ${treasure + 1}`
          : soul >= 0 && name === "soul"
          ? `${bossesArray[soul][0].name}`
          : soul >= 0 && name === "soul2"
          ? `${bosses2Array[soul][0].name}`
          : property?.[name]?.name
      }
      button={button}
      font={font}
      reverseColumn={reverseColumn}
      column={column}
      color={color}
      w={w}
      lineH={lineH}
    >
      {count &&
        (round ? (
          <span>
            {string} {roundConsumablesFixed(count).toLocaleString("ru")}
          </span>
        ) : (
          <span>
            {string} {count.toLocaleString("ru")}
          </span>
        ))}

      {name === "soul" ? (
        <img width={w} height={w} src={property?.[name].src[soul]} alt={name} />
      ) : name === "soul2" ? (
        <img width={w} height={w} src={property?.[name].src[soul]} alt={name} />
      ) : name === "potion" ? (
        <img
          width={w}
          height={w}
          src={property?.[name][category].src}
          alt={name}
        />
      ) : name === "treasure" ? (
        <img
          width={w}
          height={w}
          src={property?.[name].src[treasure]}
          alt={name}
        />
      ) : (
        property?.[name]?.src && (
          <img width={w} height={w} src={property?.[name].src} alt={name} />
        )
      )}
    </S.Wrapper>
  );
}

export default React.memo(Reward);
