import React, { useEffect, useState } from "react";

import * as S from "./Ordens.styles";
import backImg from "../../img/OrdensBack.png";
import back2Img from "../../img/OrdensBack2.png";
import back3Img from "../../img/OrdensBack3.png";
import backButtonImg from "../../img/ordens/ButtonBack.png";
import removeImg from "../../img/ordens/Remove.png";
import addImg from "../../img/ordens/Add.png";
import rectangleImg from "../../img/ordens/Rectangle.png";
import { Close } from "../../App.styles";
import Popup from "../Popup/Popup";
import frame1 from "../../img/Frame1.png";
import Reward from "../Reward/Reward";
import SumCharacters from "../SumCharacters/SumCharacters";
import { roundProperty } from "../../helpers";
import { ButtonsWrapper } from "../NickName/NickName.styles";
import { Button } from "../../panels/Arena/Arena.styles";
import lines from "../../img/ordens/Lines.png";
import imgPlace1 from "../../img/Place1.png";
import imgPlace2 from "../../img/Place2.png";
import imgPlace3 from "../../img/Place3.png";
import { warOrdenPrizes } from "../../info/data";
import SumCharacter from "../SumCharacter/SumCharacter";
import $ from "jquery";
import { Info } from "../Chat/Chat.styles";

const places = [imgPlace1, imgPlace2, imgPlace3];

const limitMembers = 25;

function Ordens({
  _id,
  adminId,
  gold,
  email,
  isOpen,
  setIsOpen,
  admin,
  setAdmin,
  setUser,
  app,
  isFetching,
  setIsFetching,
  getCharacterSum,
  setModalError,
  serverTime,
  allUsers,
  myOrden,
  moveToUserRoom,
  isRaid,
  setMyOrden,
  setActiveWarModal,
  clickName,
}) {
  const [isApplications, setIsApplications] = useState("");
  const [ordenMembers, setOrdenMembers] = useState([]);
  const [ordenMembersRate, setOrdenMembersRate] = useState([]);
  const [applicationMembers, setApplicationMembers] = useState([]);
  const [myOrdenInfo, setMyOrdenInfo] = useState(null);
  const [ordensSort, setOrdensSort] = useState([]);
  const [resources, setResources] = useState({
    silver: 0,
    gold: 0,
  });
  const [createOpen, setCreateOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [resourcesUser, setResourcesUser] = useState(null);
  const [leaveOpen, setLeaveOpen] = useState(false);
  const [deleteUser, setDeleteUser] = useState(null);
  const [anyOrden, setAnyOrden] = useState(null);
  const [tab, setTab] = useState(myOrden ? "my" : "all");
  const [description, setDescription] = useState(myOrden?.description || "");
  const [name, setName] = useState("");
  const [tag, setTag] = useState("");
  const [headTitle, setHeadTitle] = useState(myOrden?.headTitle || "");
  const [title0, setTitle0] = useState(myOrden?.title0 || "");
  const [title1, setTitle1] = useState(myOrden?.title1 || "");
  const [hoverInfo, setHoverInfo] = useState(null);

  useEffect(() => {
    if (myOrden) {
      const membersArray = [];
      const applicationsArray = [];

      myOrden.members.map((member) => {
        const localUser = allUsers.find((user) => user.email === member.email);
        const rate = myOrden.war.rate[member.email] || 0;
        const soloRate = myOrden.war.soloRate[member.email] || 0;
        const damage = myOrden.war.damage[member.email] || 0;
        if (localUser) {
          membersArray.push({
            ...localUser,
            ...member,
            warRate: rate,
            warSoloRate: soloRate,
            warDamage: damage,
          });
          if (localUser.email === email) {
            setMyOrdenInfo({
              ...localUser,
              ...member,
              warRate: rate,
              warSoloRate: soloRate,
              warDamage: damage,
            });
          }
        }
      });

      myOrden.applications.map((member) => {
        const localUser = allUsers.find((user) => user.email === member.email);
        if (localUser) {
          applicationsArray.push({ ...localUser, ...member });
        }
      });

      setApplicationMembers([...applicationsArray]);
      setOrdenMembers([
        ...membersArray.sort((a, b) => {
          if (b.isHead !== a.isHead) {
            return b.isHead - a.isHead;
          }
          if (b.title !== a.title) {
            return b.title - a.title;
          }
          return b.lvl - a.lvl;
        }),
      ]);
    } else {
      setTab("all");
    }
  }, [myOrden, allUsers]);

  useEffect(() => {
    if (ordenMembers.length > 0) {
      setOrdenMembersRate(
        [...ordenMembers].sort((a, b) => {
          if (b.warSoloRate !== a.warSoloRate) {
            return b.warSoloRate - a.warSoloRate;
          }
          if (b.warRate !== a.warRate) {
            return b.warRate - a.warRate;
          }
          return b.warDamage - a.warDamage;
        })
      );
    }
  }, [ordenMembers]);

  useEffect(() => {
    if (anyOrden === null && ordensSort.length > 0) {
      const headUser = allUsers.find(
        (elem) =>
          elem.email == ordensSort[0].members.find((elem) => elem.isHead).email
      );
      setAnyOrden({ ...ordensSort[0], headUser });
    }
  }, [anyOrden, ordensSort]);

  useEffect(() => {
    if (admin.ordens.length > 0) {
      if (tab === "all") {
        setOrdensSort(admin.ordens.sort((a, b) => b.score - a.score));
      } else if (tab === "raid") {
        const ordensInfo = admin.ordens
          .map((orden) => {
            const damage = orden.war.damage
              ? Object.values(orden.war.damage).reduce(
                  (sum, cur) => sum + cur,
                  0
                )
              : 0;
            return {
              ...orden,
              raidDamage: damage,
              raidPoints: orden.war.points || 0,
            };
          })
          .filter((orden) => orden.raidPoints > 0);
        setOrdensSort(
          ordensInfo.sort((a, b) => {
            if (b.raidPoints === a.raidPoints) {
              return b.raidDamage - a.raidDamage;
            }
            return b.raidPoints - a.raidPoints;
          })
        );
      } else {
        setOrdensSort([]);
      }
    } else {
      setOrdensSort([]);
    }
  }, [admin, allUsers, tab]);

  useEffect(() => {
    if (isOpen) {
      updateTop();
    }
  }, [isOpen]);

  function updateTop() {
    if (isOpen) {
      setIsFetching(true);
      app
        .service("admin")
        .get(adminId, {
          query: {
            $select: ["ordens"],
          },
        })
        .then((adminData) => {
          const orden = adminData.ordens.find((elem) =>
            elem.members.map((el) => el.email).includes(email)
          );
          if (orden) {
            const isOrden = orden.members.findIndex(
              (elem) => elem.email === email
            );
            if (isOrden >= 0) {
              setMyOrden(orden);
            } else {
              setMyOrden(null);
            }
          } else {
            setMyOrden(null);
          }
          setAdmin((prev) => ({ ...prev, ...adminData }));
          setIsFetching(false);
        })
        .catch((e) => {
          setModalError(e);
          console.log(e);
          setIsFetching(false);
        });
    }
  }

  function handleClose(e) {
    if (
      e.target.classList.contains("ordensWrapper") ||
      e.target.tagName === "use"
    ) {
      setIsOpen(false);
    }
  }

  function handleChangeGold(e) {
    let gold = e.target.value;
    if (gold > myOrden?.treasury.gold) {
      gold = myOrden?.treasury.gold;
    }
    if (gold < 0) {
      gold = 0;
    }
    setResources((prevState) => ({ ...prevState, gold: Math.floor(gold) }));
  }

  function handleChangeSilver(e) {
    let silver = e.target.value;
    if (silver > myOrden?.treasury.silver) {
      silver = myOrden?.treasury.silver;
    }
    if (silver < 0) {
      silver = 0;
    }
    setResources((prevState) => ({ ...prevState, silver: Math.floor(silver) }));
  }

  function handleResources(user) {
    setResourcesUser(user);
  }

  function handleCreate() {
    setCreateOpen((prev) => !prev);
  }

  function handleLeave() {
    setLeaveOpen((prev) => !prev);
  }

  function handleDelete() {
    setDeleteOpen((prev) => !prev);
  }

  function handleDeleteUser({ email, id }) {
    setDeleteUser({
      email: email,
      id: id,
    });
  }

  function setHover(info) {
    setHoverInfo(info);
  }

  function handleClickUser(user) {
    moveToUserRoom(user);
    setIsOpen(false);
  }

  function handleAnyOrden(orden) {
    if (orden) {
      const headUser = allUsers.find(
        (elem) => elem.email == orden.members[0].email
      );
      setAnyOrden({ ...orden, headUser });
    } else {
      setAnyOrden(null);
    }
  }

  function handleCreateOrden() {
    if (isRaid) {
      setModalError("Создание Ордена во время рейда запрещено");
      console.log("Создание Ордена во время рейда запрещено");
    } else {
      if (gold >= 500) {
        if (
          tag.trim().length > 0 &&
          name.trim().length > 0 &&
          description.trim().length > 0
        ) {
          app
            .service("admin")
            .get(adminId)
            .then((adminData) => {
              if (
                adminData.ordens.findIndex((elem) => elem.name === name) === -1
              ) {
                app
                  .service("admin")
                  .patch(
                    adminId,
                    {
                      $push: {
                        ordens: {
                          $each: [
                            {
                              name: name,
                              tag: tag,
                              description: description,
                              gerb: "",
                              lvl: 1,
                              allExp: 0,
                              currentExp: 0,
                              onLvlExp: 0,
                              score: 0,
                              headTitle: "Глава",
                              title1: "Заместитель",
                              title0: "Маг",
                              members: [
                                {
                                  email: email,
                                  isHead: true,
                                  title: 0,
                                  raidDamage: 0,
                                },
                              ],
                              applications: [],
                              messagesOrden: [],
                              bonus: {},
                              treasury: {
                                gold: 0,
                                silver: 0,
                                essence: 0,
                                talents: 0,
                              },
                              war: {
                                damage: {},
                                rate: {},
                                soloRate: {},
                                points: 0,
                                pageDone: 0,
                                pageTasksDone: [0, 0, 0, 0, 0],
                                pageTasks: {},
                                ordenTasks: {},
                                ordenActiveTask: -1,
                              },
                            },
                          ],
                        },
                      },
                    },
                    {
                      query: {
                        $select: ["ordens"],
                      },
                    }
                  )
                  .then((adminData) => {
                    setDescription(description);
                    setHeadTitle("Глава");
                    setTitle0("Маг");
                    setTitle1("Заместитель");

                    setAdmin((prev) => ({ ...prev, ...adminData }));
                    app
                      .service("users")
                      .patch(
                        _id,
                        {
                          $inc: {
                            gold: -500,
                          },
                          orden: {
                            name: name,
                            tag: tag,
                          },
                          field: serverTime,
                        },
                        {
                          query: {
                            $select: ["_id", "email", "orden", "rooms", "gold"],
                          },
                        }
                      )
                      .then((data) => {
                        setTab("my");
                        setCreateOpen(false);
                        setUser((prev) => ({ ...prev, ...data }));
                        setIsFetching(false);
                      })
                      .catch((e) => {
                        setModalError(e);
                        console.log(e);
                        setIsFetching(false);
                      });
                  })
                  .catch((e) => {
                    setModalError(e);
                    console.log(e);
                    setIsFetching(false);
                  });
              } else {
                console.log("Орден с таким названием уже существует");
                setModalError("Орден с таким названием уже существует");
              }
            });
        } else {
          setModalError("Строки не должны быть пустые");
          console.log("Строки не должны быть пустые");
        }
      } else {
        console.log("Недостаточно золота");

        setModalError({
          string: "Недостаточно золота",
          buttons: ["bank"],
        });
      }
    }
  }

  function redactOrden() {
    if (
      description.trim().length > 0 &&
      headTitle.trim().length > 0 &&
      title1.trim().length > 0 &&
      title0.trim().length > 0
    ) {
      setIsFetching(true);
      app
        .service("admin")
        .patch(
          adminId,
          {
            [`ordens.$.description`]: description,
            [`ordens.$.headTitle`]: headTitle,
            [`ordens.$.title1`]: title1,
            [`ordens.$.title0`]: title0,
          },
          {
            query: {
              ["ordens.name"]: myOrden.name,
              $select: ["ordens"],
            },
          }
        )
        .then((adminData) => {
          setAdmin((prev) => ({ ...prev, ...adminData }));
          setIsFetching(false);
        })
        .catch((e) => {
          setModalError(e);
          console.log(e);
          setIsFetching(false);
        });
    } else {
      setModalError("Строки не должны быть пустые");
      console.log("Строки не должны быть пустые");
    }
  }

  function handleRemoveOrden() {
    app
      .service("admin")
      .patch(
        adminId,
        {
          $pull: {
            ordens: {
              name: myOrden.name,
            },
          },
        },
        {
          query: {
            $select: ["ordens"],
          },
        }
      )
      .then((adminData) => {
        setDeleteOpen(false);
        setIsApplications("");
        setAdmin((prev) => ({ ...prev, ...adminData }));
      })
      .catch((e) => {
        setModalError(e);
        console.log(e);
        setIsFetching(false);
      });
  }

  function handleEnter(anyOrden) {
    const orden = admin.ordens.find((elem) => elem.name === anyOrden.name);
    const isEnter = orden.applications.findIndex(
      (elem) => elem.email === email
    );
    if (isEnter === -1) {
      setIsFetching(true);
      app
        .service("admin")
        .patch(
          adminId,
          {
            $push: {
              [`ordens.$.applications`]: {
                $each: [
                  {
                    email: email,
                  },
                ],
                $slice: -20,
              },
            },
          },
          {
            query: {
              ["ordens.name"]: anyOrden.name,
              $select: ["ordens"],
            },
          }
        )
        .then((adminData) => {
          setAdmin((prev) => ({ ...prev, ...adminData }));

          setIsFetching(false);
        })
        .catch((e) => {
          setModalError(e);
          console.log(e);
          setIsFetching(false);
        });
    } else {
      setModalError("Вы уже отправили заявку в этот Орден");
      console.log("Вы уже отправили заявку в этот Орден");
    }
  }

  function changeRole({ email, number = 0 }) {
    setIsFetching(true);
    app
      .service("admin")
      .patch(
        adminId,
        {
          [`ordens.$.members.$[elem].title`]: number,
        },
        {
          query: {
            ["ordens.name"]: myOrden.name,
            mongodb: {
              arrayFilters: [{ "elem.email": email }],
            },
            $select: ["ordens"],
          },
        }
      )
      .then((adminData) => {
        setAdmin((prev) => ({ ...prev, ...adminData }));
        setHoverInfo(null);
        setIsFetching(false);
      })
      .catch((e) => {
        setModalError(e);
        console.log(e);
        setIsFetching(false);
      });
  }

  function removeApplications() {
    setIsFetching(true);
    app
      .service("admin")
      .patch(
        adminId,
        {
          [`ordens.$.applications`]: [],
        },
        {
          query: {
            ["ordens.name"]: myOrden.name,
            $select: ["ordens"],
          },
        }
      )
      .then((adminData) => {
        setAdmin((prev) => ({ ...prev, ...adminData }));

        setIsFetching(false);
      })
      .catch((e) => {
        setModalError(e);
        console.log(e);
        setIsFetching(false);
      });
  }

  function handleMember({ name, tag, userEmail, headEmail, userId, isGet }) {
    if (isGet) {
      if (isRaid) {
        setModalError("Приём в Орден на время рейда закрыт");
        console.log("Приём в Орден на время рейда закрыт");
      } else {
        if (myOrden.members.length < limitMembers) {
          setIsFetching(true);
          app
            .service("users")
            .changeOrden({
              userId: userId,
              userEmail: userEmail,
              headEmail: headEmail,
              isGet: true,
              name: name,
              tag: tag,
            })
            .then(() => {
              setIsFetching(false);
            })
            .catch((e) => {
              setIsFetching(false);
              setModalError(e);
              console.log(e);
            });
        } else {
          setModalError("В Ордене нет своободных мест");
          console.log("В Ордене нет своободных мест");
        }
      }
    } else {
      setIsFetching(true);
      app
        .service("admin")
        .patch(
          adminId,
          {
            $pull: {
              [`ordens.$.applications`]: { email: userEmail },
            },
          },
          {
            query: {
              ["ordens.name"]: name,
              $select: ["ordens"],
            },
          }
        )
        .then((adminData) => {
          setAdmin((prev) => ({ ...prev, ...adminData }));

          setIsFetching(false);
        })
        .catch((e) => {
          setModalError(e);
          console.log(e);
          setIsFetching(false);
        });
    }
  }

  function changeHeadTitle(e) {
    const string = e.target.value;
    if (string.length > 15) {
      string.length = 15;
      setHeadTitle(string);
    } else {
      setHeadTitle(string);
    }
  }

  function changeTitle0(e) {
    const string = e.target.value;
    if (string.length > 15) {
      string.length = 15;
      setTitle0(string);
    } else {
      setTitle0(string);
    }
  }

  function changeTitle1(e) {
    const string = e.target.value;
    if (string.length > 15) {
      string.length = 15;
      setTitle1(string);
    } else {
      setTitle1(string);
    }
  }

  function changeTag(e) {
    const string = e.target.value;
    if (string.length > 5) {
      string.length = 5;
      setTag(string);
    } else {
      setTag(string);
    }
  }

  function changeName(e) {
    const string = e.target.value;
    if (string.length > 30) {
      string.length = 30;
      setName(string);
    } else {
      setName(string);
    }
  }

  function changeString(e) {
    const string = e.target.value;
    if (string.length > 250) {
      string.length = 250;
      setDescription(string);
    } else {
      setDescription(string);
    }
  }

  function handleTab(name) {
    setTab(name);
  }

  function openInfo(e, elem) {
    const elementTouch = $(e?.target)?.closest(".info");
    const position = elementTouch?.position();

    if (position) {
      setHover({
        ...elem.elem,
        top: position.top + 100,
        left: position.left,
        right: elem.right,
      });
    } else {
      setHover(null);
    }
  }

  function closeInfo(e, user) {
    const target = e?.target?.closest(".infoClick");
    if (!target) {
      setHover(null);
    }
    if (user) {
      setHover(null);
    }
  }

  useEffect(() => {
    closeInfo(clickName);
  }, [clickName]);

  function handleResourcesChange() {
    if (resources.gold + resources.silver > 0) {
      let isTransaction = true;
      if (myOrden.treasury.gold < resources.gold) {
        isTransaction = false;
        console.log(name, "Недостаточно золота");
        setModalError("Недостаточно золота");
      }
      if (myOrden.treasury.silver < resources.silver) {
        isTransaction = false;
        console.log(name, "Недостаточно серебра");
        setModalError("Недостаточно серебра");
      }

      if (isTransaction) {
        setIsFetching(true);
        app
          .service("users")
          .sendResources({
            headEmail: email,
            userId: resourcesUser._id,
            name: myOrden?.name,
            gold: resources.gold,
            silver: resources.silver,
          })
          .then(() => {
            setIsFetching(false);
            setResources({
              silver: 0,
              gold: 0,
            });
          })
          .catch((e) => {
            setIsFetching(false);
            setModalError(e);
            console.log(e);
          });
      }
    } else {
      setModalError("Недостаточно ресурсов для отправки");
      console.log("Недостаточно ресурсов для отправки");
    }
  }

  function handleLeaveMember({ name, userEmail, headEmail, userId }) {
    setIsFetching(true);
    app
      .service("users")
      .changeOrden({
        userId: userId,
        headEmail: headEmail,
        userEmail: userEmail,
        isGet: false,
        name: name,
      })
      .then(() => {
        setIsFetching(false);
        setDeleteUser(null);
        setLeaveOpen(false);
        setHoverInfo(null);
      })
      .catch((e) => {
        setIsFetching(false);
        setModalError(e);
        console.log(e);
      });
  }

  return (
    <>
      <S.Wrapper
        isOpen={isOpen}
        onClick={handleClose}
        className={"ordensWrapper"}
      >
        {isOpen && (
          <S.Content back={backImg}>
            <Close onClick={handleClose} className={"ordensWrapper"} />
            <S.InnerWrapper>
              <S.Header>Ордена "Школы Магии"</S.Header>
              <ButtonsWrapper>
                <Button
                  disabled={tab === "all"}
                  onClick={handleTab.bind(null, "all")}
                  width={100}
                >
                  <div>Все Ордена</div>
                </Button>

                {myOrden ? (
                  <>
                    <Button
                      disabled={tab === "my"}
                      onClick={handleTab.bind(null, "my")}
                      width={100}
                    >
                      <div>Мой Орден</div>
                    </Button>
                  </>
                ) : (
                  <Button onClick={handleCreate} width={100}>
                    <div>Создать Орден</div>
                  </Button>
                )}

                {myOrden && (
                  <Button
                    disabled={tab === "raid"}
                    onClick={handleTab.bind(null, "raid")}
                    width={100}
                  >
                    <div>Сражение</div>
                  </Button>
                )}

                {myOrden && (isRaid || email === 168191289) && (
                  <Button
                    onClick={() => {
                      setActiveWarModal(true);
                    }}
                    width={100}
                  >
                    <div>Задания</div>
                  </Button>
                )}
              </ButtonsWrapper>

              {tab === "raid" && (
                <>
                  {ordensSort.length <= 0 && (
                    <div>
                      Здесь отобразится рейтинг Орденов, когда они наберут
                      первые очки
                    </div>
                  )}

                  {ordensSort.length > 0 && (
                    <S.Ordens>
                      {ordensSort.map((elem, i) => {
                        return (
                          <S.OrdenRaid
                            isMy={myOrden?.name === elem.name}
                            key={i}
                            onClick={handleAnyOrden.bind(null, elem)}
                          >
                            <S.Place>
                              {i < 3 ? (
                                <img
                                  src={places[i]}
                                  width={38}
                                  height={38}
                                  alt="место"
                                />
                              ) : i + 1 > 99 ? (
                                "99+"
                              ) : (
                                i + 1
                              )}
                            </S.Place>
                            <S.OrdenName>
                              [{elem.tag}] {elem.name}
                            </S.OrdenName>
                            <S.Orden2String>
                              <span>урон</span>
                              <span>
                                {roundProperty(
                                  elem?.raidDamage || 0
                                ).toLocaleString("ru")}
                              </span>
                            </S.Orden2String>
                            <S.Orden2String>
                              <span>очки</span>
                              <span>{elem.raidPoints}</span>
                            </S.Orden2String>
                            {i < 10 ? (
                              <S.Rewards>
                                {Object.entries(warOrdenPrizes[i]).map(
                                  (prize, i) => {
                                    return (
                                      <Reward
                                        button={true}
                                        key={i}
                                        count={`+${prize[1]}%`}
                                        name={prize[0]}
                                        w={15}
                                      />
                                    );
                                  }
                                )}
                              </S.Rewards>
                            ) : (
                              <span>—</span>
                            )}
                          </S.OrdenRaid>
                        );
                      })}
                    </S.Ordens>
                  )}
                </>
              )}

              <S.OrdensWrapper>
                {tab === "all" && (
                  <>
                    <S.OrdenInfo back={rectangleImg}>
                      {anyOrden && (
                        <>
                          <S.TopInfoOrdenWrapper>
                            <S.TopInfoOrden>
                              {anyOrden.gerb ? (
                                <S.GerbWrapper>
                                  <img
                                    width={86}
                                    height={86}
                                    src={require(`../../img/ordens/gerbs/GerbBack.png`)}
                                    alt=""
                                  />
                                  <img
                                    width={82}
                                    height={82}
                                    src={anyOrden.gerb}
                                    alt=""
                                  />
                                </S.GerbWrapper>
                              ) : (
                                <S.GerbWrapper>
                                  <img
                                    width={86}
                                    height={86}
                                    src={require(`../../img/ordens/gerbs/Gerb.png`)}
                                    alt=""
                                  />
                                </S.GerbWrapper>
                              )}

                              <S.TopInfoOrdenRight>
                                <div>
                                  <span>Глава</span>
                                  <S.HeadUser
                                    isActive={
                                      anyOrden?.headUser &&
                                      anyOrden?.headUser?.email !== email
                                    }
                                    onClick={
                                      anyOrden?.headUser &&
                                      anyOrden?.headUser?.email !== email
                                        ? handleClickUser.bind(
                                            null,
                                            anyOrden?.headUser
                                          )
                                        : null
                                    }
                                  >
                                    {anyOrden?.headUser?.nickName
                                      ? anyOrden?.headUser?.nickName
                                      : `${anyOrden?.headUser?.name?.firstName} ${anyOrden?.headUser?.name?.lastName}`}
                                  </S.HeadUser>
                                </div>
                                <div>
                                  <span>[{anyOrden.tag}]</span>
                                  <span>{anyOrden.name}</span>
                                </div>
                              </S.TopInfoOrdenRight>
                            </S.TopInfoOrden>

                            <S.Description>
                              <img src={lines} alt="" />
                              <span>
                                {anyOrden.description
                                  ? anyOrden.description
                                  : "Описание ордена, которое выставляется Главой или его Заместителем, вот располагается оно вот так, прямо тут на этих милых строчечках"}
                              </span>
                            </S.Description>
                            <S.BottomOrdenInfo>
                              <div>Уровень: {anyOrden.lvl}</div>
                              <div>Очки рейтинга: {anyOrden.score}</div>
                              <div>
                                Сумма характеристик:{" "}
                                <SumCharacters
                                  users={allUsers}
                                  ordenMembers={anyOrden.members}
                                />
                              </div>
                            </S.BottomOrdenInfo>
                          </S.TopInfoOrdenWrapper>
                          {!myOrden ? (
                            <Button
                              width={125}
                              onClick={
                                !isFetching && !myOrden
                                  ? handleEnter.bind(null, anyOrden)
                                  : null
                              }
                            >
                              <div>Вступить</div>
                            </Button>
                          ) : (
                            <div>Вы уже состоите в Ордене</div>
                          )}
                        </>
                      )}
                    </S.OrdenInfo>
                    <S.Ordens>
                      {ordensSort.length > 0 &&
                        ordensSort.map((elem, i) => {
                          return (
                            <S.Orden
                              isActive={anyOrden?.name === elem.name}
                              isMy={myOrden?.name === elem.name}
                              key={i}
                              onClick={handleAnyOrden.bind(null, elem)}
                            >
                              <S.Place>{i + 1 > 99 ? "99+" : i + 1}</S.Place>
                              <S.OrdenName>
                                <span>[{elem.tag}]</span>
                                <span>{elem.name}</span>
                              </S.OrdenName>
                              <span>
                                {elem.members.length}/{limitMembers}
                              </span>
                            </S.Orden>
                          );
                        })}
                    </S.Ordens>
                  </>
                )}

                {tab === "my" && (
                  <>
                    <S.OrdenInfo back={rectangleImg}>
                      {myOrden && (
                        <>
                          <S.TopInfoOrdenWrapper>
                            <S.TopInfoOrden>
                              {myOrden.gerb ? (
                                <S.GerbWrapper>
                                  <img
                                    width={86}
                                    height={86}
                                    src={require(`../../img/ordens/gerbs/GerbBack.png`)}
                                    alt=""
                                  />
                                  <img
                                    width={82}
                                    height={82}
                                    src={myOrden.gerb}
                                    alt=""
                                  />
                                </S.GerbWrapper>
                              ) : (
                                <S.GerbWrapper>
                                  <img
                                    width={86}
                                    height={86}
                                    src={require(`../../img/ordens/gerbs/Gerb.png`)}
                                    alt=""
                                  />
                                </S.GerbWrapper>
                              )}

                              <S.TopInfoOrdenRight>
                                <div>
                                  <span>[{myOrden.tag}]</span>
                                  <span>{myOrden.name}</span>
                                </div>
                                <div>
                                  <span>участники</span>
                                  <span>
                                    {myOrden.members.length}/{limitMembers}
                                  </span>
                                </div>
                              </S.TopInfoOrdenRight>
                            </S.TopInfoOrden>

                            <S.Description>
                              <img src={lines} alt="" />
                              <span>
                                {myOrden.description
                                  ? myOrden.description
                                  : "Описание ордена, которое выставляется Главой или его Заместителем, вот располагается оно вот так, прямо тут на этих милых строчечках"}
                              </span>
                            </S.Description>
                            <S.BottomOrdenInfo>
                              <div>Уровень: {myOrden.lvl}</div>
                              <div>Очки рейтинга: {myOrden.score}</div>
                              <div>
                                Сумма характеристик:{" "}
                                <SumCharacters
                                  users={allUsers}
                                  ordenMembers={myOrden.members}
                                />
                              </div>
                            </S.BottomOrdenInfo>
                          </S.TopInfoOrdenWrapper>

                          <ButtonsWrapper>
                            {myOrdenInfo && myOrdenInfo.isHead && (
                              <Button
                                width={125}
                                onClick={() => {
                                  setIsApplications("redact");
                                }}
                              >
                                <div>Управление</div>
                              </Button>
                            )}

                            {myOrdenInfo && !myOrdenInfo.isHead && (
                              <Button width={125} onClick={handleLeave}>
                                <div>Покинуть</div>
                              </Button>
                            )}

                            {myOrdenInfo &&
                              (myOrdenInfo.isHead ||
                                myOrdenInfo.title === 1) && (
                                <Button
                                  width={125}
                                  onClick={() => {
                                    setIsApplications("applications");
                                  }}
                                >
                                  <div>Заявки</div>
                                </Button>
                              )}

                            <Button
                              width={125}
                              onClick={() => {
                                setIsApplications("rate");
                              }}
                            >
                              <div>Сражение</div>
                            </Button>
                          </ButtonsWrapper>
                        </>
                      )}
                    </S.OrdenInfo>
                    <S.Mebers>
                      {ordenMembers.map((elem, i) => {
                        return (
                          <S.Member
                            className={"info infoClick"}
                            onClick={(e) => {
                              openInfo(e, { elem });
                            }}
                            key={i}
                          >
                            <S.LvlInfo>
                              <span>ур</span>
                              <span>{elem.lvl}</span>
                            </S.LvlInfo>
                            <S.Avatar isPointer={true}>
                              <img
                                src={elem.photo}
                                width={35}
                                height={35}
                                alt="аватар"
                              />
                            </S.Avatar>
                            <S.MemberInfo>
                              <span>
                                {elem?.nickName
                                  ? elem?.nickName
                                  : `${elem?.name?.firstName} ${elem?.name?.lastName}`}
                              </span>
                              <span>
                                {elem?.isHead
                                  ? myOrden?.headTitle
                                  : elem?.title === 1
                                  ? myOrden?.title1
                                  : myOrden?.title0}
                                ,{" "}
                                {roundProperty(elem.allCharac).toLocaleString(
                                  "ru"
                                )}{" "}
                                хар-ки
                              </span>
                            </S.MemberInfo>
                          </S.Member>
                        );
                      })}
                    </S.Mebers>
                  </>
                )}
              </S.OrdensWrapper>
              <S.InfoPopup
                visible={hoverInfo && hoverInfo.left}
                className={"info infoClick"}
                top={hoverInfo?.top}
                left={hoverInfo?.left}
                right={hoverInfo?.right}
              >
                {hoverInfo && (
                  <>
                    <Info>
                      <div>
                        <span>
                          {hoverInfo?.nickName
                            ? hoverInfo?.nickName
                            : `${hoverInfo?.name?.firstName} ${hoverInfo?.name?.lastName}`}
                        </span>
                      </div>

                      {hoverInfo?.email != email && (
                        <div onClick={handleClickUser.bind(null, hoverInfo)}>
                          В комнату
                        </div>
                      )}

                      {myOrdenInfo.isHead && (
                        <div onClick={handleResources.bind(null, hoverInfo)}>
                          Ресурсы
                        </div>
                      )}

                      {myOrdenInfo.isHead && !hoverInfo?.isHead && (
                        <>
                          {hoverInfo?.title === 0 ? (
                            <div
                              onClick={
                                !isFetching
                                  ? changeRole.bind(null, {
                                      email: hoverInfo?.email,
                                      number: 1,
                                    })
                                  : null
                              }
                            >
                              Повысить
                            </div>
                          ) : (
                            <div
                              onClick={
                                !isFetching
                                  ? changeRole.bind(null, {
                                      email: hoverInfo?.email,
                                      number: 0,
                                    })
                                  : null
                              }
                            >
                              Понизить
                            </div>
                          )}
                        </>
                      )}
                      {(myOrdenInfo.title === 1 || myOrdenInfo.isHead) &&
                        !hoverInfo?.email !== email &&
                        !hoverInfo?.isHead && (
                          <div
                            onClick={
                              !isFetching
                                ? handleDeleteUser.bind(null, {
                                    email: hoverInfo?.email,
                                    id: hoverInfo?._id,
                                  })
                                : null
                            }
                          >
                            Исключить
                          </div>
                        )}
                    </Info>
                  </>
                )}
              </S.InfoPopup>
            </S.InnerWrapper>
          </S.Content>
        )}
      </S.Wrapper>

      <Popup
        isOpen={isApplications}
        setIsOpen={setIsApplications}
        w={440}
        h={575}
        back={back2Img}
        justify={"start"}
        padding={"20px 15px"}
      >
        <Close
          onClick={() => {
            setIsApplications("");
          }}
        />

        {isApplications === "rate" ? (
          <S.Header>Рейтинг сражения</S.Header>
        ) : (
          <S.ButtonsWrapper>
            {myOrdenInfo && myOrdenInfo.isHead && (
              <Button
                width={120}
                onClick={() => {
                  setIsApplications("redact");
                }}
                disabled={isApplications === "redact"}
              >
                <div>Управление</div>
              </Button>
            )}

            <Button
              width={120}
              onClick={() => {
                setIsApplications("applications");
              }}
              disabled={isApplications === "applications"}
            >
              <div>Заявки</div>
            </Button>
          </S.ButtonsWrapper>
        )}

        {isApplications === "rate" && (
          <S.Applications>
            {ordenMembersRate.map((userLocal, i) => {
              return (
                userLocal && (
                  <S.Rate key={i}>
                    <S.Place>{i + 1 > 99 ? "99+" : i + 1}</S.Place>
                    <S.Avatar
                      isPointer={userLocal.email != email}
                      onClick={
                        userLocal.email != email
                          ? handleClickUser.bind(null, userLocal)
                          : null
                      }
                    >
                      <img
                        src={userLocal.photo}
                        width={35}
                        height={35}
                        alt="аватар"
                      />
                    </S.Avatar>
                    <S.ApplicationInfo>
                      <span>
                        {userLocal.nickName
                          ? userLocal.nickName
                          : `${userLocal.name?.firstName} ${userLocal.name?.lastName}`}
                      </span>

                      <span>
                        Урон:{" "}
                        {roundProperty(userLocal.warDamage).toLocaleString(
                          "ru"
                        )}
                      </span>
                    </S.ApplicationInfo>
                    <S.Orden2String>
                      <span>соло</span>
                      <span>
                        {roundProperty(userLocal.warSoloRate).toLocaleString(
                          "ru"
                        )}
                        /3
                      </span>
                    </S.Orden2String>
                    <S.Orden2String>
                      <span>вклад</span>
                      <span>
                        {roundProperty(userLocal.warRate).toLocaleString("ru")}
                      </span>
                    </S.Orden2String>
                  </S.Rate>
                )
              );
            })}
          </S.Applications>
        )}

        {isApplications === "redact" && myOrden && (
          <>
            <S.RedactWrapper>
              <div>
                <S.RegularString>
                  Для изменения герба ордена необходимо обратиться к
                </S.RegularString>{" "}
                <S.Link href={"https://vk.com/unikorn_blood"} target={"_blank"}>
                  директорату Школы Магии
                </S.Link>
              </div>

              {myOrden.gerb ? (
                <img width={86} height={86} src={myOrden.gerb} alt="" />
              ) : (
                <img
                  width={86}
                  height={86}
                  src={require(`../../img/ordens/gerbs/Gerb.png`)}
                  alt=""
                />
              )}

              <div>
                <S.RegularString>Название</S.RegularString>

                <S.BoldString>
                  [{myOrden.tag}] {myOrden.name}
                </S.BoldString>
              </div>

              <S.InputWrapper>
                <S.RegularString>Описание (до 250 символов)</S.RegularString>
                <img src={lines} alt="" />
                <S.AreaOrden
                  rows={6}
                  value={description}
                  onChange={changeString}
                  maxLength={250}
                />
              </S.InputWrapper>

              <S.InputWrapper>
                <S.RegularString>Глава (до 15 символов)</S.RegularString>
                <S.InputOrden
                  value={headTitle}
                  onChange={changeHeadTitle}
                  type="text"
                  maxLength={15}
                />
              </S.InputWrapper>

              <S.InputWrapper>
                <S.RegularString>Заместитель (до 15 символов)</S.RegularString>
                <S.InputOrden
                  value={title1}
                  onChange={changeTitle1}
                  type="text"
                  maxLength={15}
                />
              </S.InputWrapper>

              <S.InputWrapper>
                <S.RegularString>Участник (до 15 символов)</S.RegularString>
                <S.InputOrden
                  value={title0}
                  onChange={changeTitle0}
                  type="text"
                  maxLength={15}
                />
              </S.InputWrapper>
            </S.RedactWrapper>

            <S.ButtonsBottom>
              <Button width={120} onClick={!isFetching ? redactOrden : null}>
                <div>Сохранить</div>
              </Button>
              <Button width={120} onClick={!isFetching ? handleDelete : null}>
                <div>Распустить</div>
              </Button>
            </S.ButtonsBottom>
          </>
        )}

        {isApplications === "applications" && myOrden && (
          <>
            {applicationMembers.length <= 0 && (
              <S.ErrorString>Активных заявок нет</S.ErrorString>
            )}
            {applicationMembers.length > 0 && (
              <S.Applications>
                {applicationMembers.map((userLocal, i) => {
                  return (
                    userLocal && (
                      <S.Application key={i}>
                        <S.Place>{i + 1 > 99 ? "99+" : i + 1}</S.Place>
                        <S.Avatar
                          isPointer={true}
                          onClick={handleClickUser.bind(null, userLocal)}
                        >
                          <img
                            src={userLocal.photo}
                            width={35}
                            height={35}
                            alt="аватар"
                          />
                        </S.Avatar>
                        <S.ApplicationInfo>
                          <span>
                            {userLocal.nickName
                              ? userLocal.nickName
                              : `${userLocal.name?.firstName} ${userLocal.name?.lastName}`}
                          </span>

                          <span>
                            Уровень: {userLocal.lvl}, Хар-ки:{" "}
                            <SumCharacter
                              isRound={true}
                              user={userLocal}
                              getCharacterSum={getCharacterSum}
                            />
                          </span>
                        </S.ApplicationInfo>
                        <S.ApplicationButton
                          onClick={
                            !isFetching
                              ? handleMember.bind(null, {
                                  isGet: true,
                                  userEmail: userLocal.email,
                                  headEmail: email,
                                  userId: userLocal._id,
                                  name: myOrden.name,
                                  tag: myOrden.tag,
                                })
                              : null
                          }
                        >
                          <img
                            src={backButtonImg}
                            width={41}
                            height={33}
                            alt=""
                          />
                          <img src={addImg} width={41} height={33} alt="" />
                        </S.ApplicationButton>

                        <S.ApplicationButton
                          onClick={
                            !isFetching
                              ? handleMember.bind(null, {
                                  isGet: false,
                                  userEmail: userLocal.email,
                                  headEmail: email,
                                  userId: userLocal._id,
                                  name: myOrden.name,
                                  tag: myOrden.tag,
                                })
                              : null
                          }
                        >
                          <img
                            src={backButtonImg}
                            width={41}
                            height={33}
                            alt=""
                          />
                          <img src={removeImg} width={41} height={33} alt="" />
                        </S.ApplicationButton>
                      </S.Application>
                    )
                  );
                })}
              </S.Applications>
            )}

            <S.ButtonsBottom>
              <Button
                disabled={applicationMembers.length <= 0}
                width={120}
                onClick={
                  !isFetching && applicationMembers.length > 0
                    ? removeApplications
                    : null
                }
              >
                <div>Очистить</div>
              </Button>
            </S.ButtonsBottom>
          </>
        )}
      </Popup>

      <Popup
        isOpen={createOpen}
        setIsOpen={handleCreate}
        w={440}
        h={575}
        back={back2Img}
        justify={"start"}
      >
        <Close onClick={handleCreate} />
        <S.Header>Создание Ордена</S.Header>

        <S.CreateWrapper>
          <S.InputWrapper>
            <S.RegularString>Название (до 30 символов)</S.RegularString>
            <S.InputOrden
              value={name}
              onChange={changeName}
              type="text"
              maxLength={30}
            />
          </S.InputWrapper>

          <S.InputWrapper>
            <S.RegularString>Тег (до 5 символов)</S.RegularString>
            <S.TagWrapper>
              <S.InputOrden
                value={tag}
                onChange={changeTag}
                type="text"
                maxLength={5}
              />
            </S.TagWrapper>
          </S.InputWrapper>

          <S.InputWrapper>
            <S.RegularString>Описание (до 250 символов)</S.RegularString>
            <img src={lines} alt="" />
            <S.AreaOrden
              rows={6}
              value={description}
              onChange={changeString}
              maxLength={250}
            />
          </S.InputWrapper>
        </S.CreateWrapper>

        <S.ButtonsBottom>
          <Button onClick={handleCreateOrden} width={120}>
            <Reward name={"gold"} count={`Создать 500`} w={15} button={true} />
          </Button>
        </S.ButtonsBottom>
      </Popup>

      <Popup
        isOpen={deleteOpen}
        setIsOpen={handleDelete}
        w={281}
        h={152}
        back={frame1}
      >
        <Close onClick={handleDelete} />
        {myOrden && (
          <>
            <b>Желаете распустить Орден?</b>
            <Button width={120} onClick={handleRemoveOrden}>
              <div>Распустить</div>
            </Button>
          </>
        )}
      </Popup>

      <Popup
        isOpen={leaveOpen}
        setIsOpen={handleLeave}
        w={281}
        h={152}
        back={frame1}
      >
        <Close onClick={handleLeave} />
        {myOrden && (
          <>
            <b>Желаете покинуть Орден?</b>
            <ButtonsWrapper>
              <Button
                width={120}
                onClick={
                  !isFetching
                    ? handleLeaveMember.bind(null, {
                        userEmail: email,
                        userId: _id,
                        name: myOrden?.name,
                      })
                    : null
                }
              >
                <div>Покинуть</div>
              </Button>
            </ButtonsWrapper>
          </>
        )}
      </Popup>

      <Popup
        isOpen={deleteUser}
        setIsOpen={setDeleteUser}
        w={281}
        h={152}
        back={frame1}
      >
        <Close
          onClick={() => {
            setDeleteUser(null);
          }}
        />
        {deleteUser && myOrden && (
          <>
            <b>Желаете исключить мага из Ордена?</b>
            <ButtonsWrapper>
              <Button
                width={120}
                onClick={
                  !isFetching
                    ? handleLeaveMember.bind(null, {
                        headEmail: email,
                        userEmail: deleteUser.email,
                        userId: deleteUser.id,
                        name: myOrden?.name,
                      })
                    : null
                }
              >
                <div>Исключить</div>
              </Button>
            </ButtonsWrapper>
          </>
        )}
      </Popup>

      <Popup
        isOpen={resourcesUser}
        setIsOpen={setResourcesUser}
        w={440}
        h={317}
        back={back3Img}
        justify={"start"}
      >
        <Close
          onClick={() => {
            setResourcesUser(null);
          }}
        />
        <S.Header>Выдача ресурсов</S.Header>
        {myOrden && resourcesUser && (
          <>
            <S.Resources>
              <S.RegularString>Казна Ордена</S.RegularString>
              <S.Rewards>
                <Reward
                  round={true}
                  name={"gold"}
                  count={myOrden.treasury.gold}
                  w={20}
                />
                <Reward
                  round={true}
                  name={"silver"}
                  count={myOrden.treasury.silver}
                  w={20}
                />
              </S.Rewards>

              <S.RegularString>
                Укажите количество валюты, выдаваемой игроку:
              </S.RegularString>

              <span>
                {resourcesUser?.nickName
                  ? resourcesUser?.nickName
                  : `${resourcesUser?.name?.firstName} ${resourcesUser?.name?.lastName}`}
              </span>

              <S.ResourcesWrapper>
                <S.Resource>
                  <S.InputOrden
                    value={resources.gold}
                    onChange={handleChangeGold}
                    min={0}
                    max={myOrden?.treasury.gold}
                    type={"number"}
                  />
                  <span>(Золото)</span>
                </S.Resource>
                <S.Resource>
                  <S.InputOrden
                    value={resources.silver}
                    onChange={handleChangeSilver}
                    min={0}
                    max={myOrden?.treasury.silver}
                    type={"number"}
                  />
                  <span>(Серебро)</span>
                </S.Resource>
              </S.ResourcesWrapper>
            </S.Resources>

            <S.ButtonsBottom>
              <Button
                width={120}
                onClick={!isFetching ? handleResourcesChange : null}
              >
                <div>Выдать</div>
              </Button>
            </S.ButtonsBottom>
          </>
        )}
      </Popup>
    </>
  );
}

export default React.memo(Ordens);
