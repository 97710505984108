import React, { useEffect, useState } from "react";
import * as S from "./Quests.styles";
import { roundLvlExp, secondsToHm } from "../../helpers";
import { dayQuestArray, dayQuestRewards, grindData } from "../../info/data";
import { BookPages } from "../Book/Book.styles";
import Reward from "../Reward/Reward";
import imgGold from "../../img/book/Gold.png";
import Popup from "../Popup/Popup";
import frame1 from "../../img/Frame1.png";
import { Close } from "../../App.styles";
import { RewardWrapper } from "../DayReward/DayReward.styles";
import { Button } from "../../panels/Arena/Arena.styles";

const text = [
  "Каждый мой день в школе магии неповторим!",
  "Абсолютно каждый из них преподносит новые трудности на моем пути. Но трудности - не значит плохо. Иногда это даже к лучшему.  Как, например, с ежедневными заданиями. Они простые и увлекательные, а выполнять их - одно удовольствие! К тому же, за их выполнение можно получить щедрую награду, которая помогает мне постоянно улучшать свои навыки.",
  "Каждый день ученику выдается список заданий. Я переписал его на страницу справа и буду обновлять ежедневно.",
];

function Quests({
  _id,
  gold,
  health,
  currentEnergy,
  dayQuest,
  currentExpOnLvl,
  onLvlExpNeed,
  characteristic,
  lvl,
  damageTalents,
  grind,
  isFetching,
  setIsFetching,
  setUser,
  app,
  setModalError,
  setIsLvlUpOpen,
  serverTime,
}) {
  const [dataString, setDataString] = useState({ date: "", day: "" });
  const [activeElem, setActiveElem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [countDone, setCountDone] = useState(0);

  useEffect(() => {
    let count = 0;
    if (dayQuest.todayIsDone < dayQuestArray.length) {
      dayQuestArray[dayQuest.todayIsDone || 0].map((elem) => {
        if (dayQuest[elem.property] >= elem.count) {
          count++;
        }
      });
    }
    setCountDone(count);
  }, [dayQuest]);

  useEffect(() => {
    const d1 = new Date(serverTime);
    const offsetOld = d1.getTimezoneOffset();
    const offsetMoscow = -180;
    const d2 = new Date(
      serverTime + offsetOld * 60 * 1000 - offsetMoscow * 60 * 1000
    );

    const d3 = new Date(
      d2.getFullYear(),
      d2.getMonth(),
      d2.getDate(),
      23,
      59,
      59
    );

    const time = secondsToHm((d3.getTime() - d2.getTime()) / 1000);

    setDataString({
      date: time,
      day: getWeekDay(d2),
    });
  }, [serverTime]);

  function getWeekDay(date) {
    let days = [
      "Воскресенье",
      "Понедельник",
      "Вторник",
      "Среда",
      "Четверг",
      "Пятница",
      "Суббота",
    ];

    return days[date.getDay()];
  }

  function changeActive(elem) {
    setActiveElem(elem);
  }

  function getPrize({ gold, exp, mana }) {
    setIsFetching(true);
    setIsLoading(true);

    let isUp = 0;
    let currentExpOnLvlNew = currentExpOnLvl;
    let onLvlExpNeedNew = onLvlExpNeed;

    if (currentExpOnLvlNew + exp >= onLvlExpNeedNew) {
      currentExpOnLvlNew += exp - onLvlExpNeed;
      onLvlExpNeedNew = roundLvlExp(
        onLvlExpNeed *
          (lvl > 100 ? 1.1 : lvl > 150 ? 1.05 : lvl > 200 ? 1.02 : 1.2)
      );
      isUp = 1;
      while (currentExpOnLvlNew >= onLvlExpNeedNew) {
        currentExpOnLvlNew -= onLvlExpNeedNew;
        onLvlExpNeedNew = roundLvlExp(
          onLvlExpNeed *
            (lvl > 100 ? 1.1 : lvl > 150 ? 1.05 : lvl > 200 ? 1.02 : 1.2)
        );
        isUp++;
      }
    } else {
      currentExpOnLvlNew += exp;
    }

    const characteristicInfo = {};
    Object.entries(characteristic).forEach((elem) => {
      characteristicInfo[elem[0]] = {
        ...elem[1],
        lvl: (lvl + isUp) * 5,
      };
    });

    const scales = {};
    const grindValue = grindData["book"].bonus[grind["book"] - 1] / 100;
    damageTalents.characteristicTalents.forEach((elem) => {
      scales[elem.property] =
        1 + grindValue + Math.round(elem.step * elem.countDone * 100) / 10000;
    });

    const hp = Math.floor(
      Object.values(characteristicInfo.health).reduce((acc, cur, i) => {
        if (i < 2) {
          return acc + cur * 5 * scales.health;
        }
        return acc + cur * 5;
      }, 0)
    );

    let goldUp = 0;
    for (let step = 0; step < isUp; step++) {
      goldUp += lvl + step + 1;
    }

    app
      .service("users")
      .patch(
        _id,
        {
          $inc: {
            allExp: exp,
            lvl: isUp,
            gold: gold + goldUp,
            mana: mana,
            currentEnergy:
              isUp &&
              currentEnergy <
                damageTalents.simpleTalents.maxValueEnergy +
                  grindData["owl"].bonus[grind["owl"] - 1]
                ? damageTalents.simpleTalents.maxValueEnergy +
                  grindData["owl"].bonus[grind["owl"] - 1] -
                  currentEnergy
                : 0,
            "dayQuest.todayIsDone": 1,
            "spells.points": isUp + 1,
            "spells.pointsAll": isUp + 1,
          },
          health: {
            current: isUp ? hp : health.current,
            max: hp,
          },
          currentExpOnLvl: currentExpOnLvlNew,
          onLvlExpNeed: onLvlExpNeedNew,
          characteristic: characteristicInfo,
          field: serverTime,
        },
        {
          query: {
            $select: [
              "_id",
              "email",
              "allExp",
              "lvl",
              "gold",
              "mana",
              "currentEnergy",
              "health",
              "onLvlExpNeed",
              "characteristic",
              "currentExpOnLvl",
              "dayQuest",
              "spells",
            ],
          },
        }
      )
      .then((data) => {
        setCountDone(0);
        setUser((prev) => ({ ...prev, ...data }));
        setIsLoading(false);
        setIsFetching(false);
        if (isUp) {
          setIsLvlUpOpen(true);
        }
      })
      .catch((e) => {
        setModalError(e);
        console.log(e);
        setIsFetching(false);
        setIsLoading(false);
      });
  }

  function buyQuest() {
    if (activeElem && activeElem.price) {
      if (gold < activeElem.price) {
        console.log("Недостаточно золота");

        setModalError({
          string: "Недостаточно золота",
          buttons: ["bank"],
        });
      } else {
        setIsFetching(true);
        setIsLoading(true);

        app
          .service("users")
          .patch(
            _id,
            {
              $inc: {
                gold: -activeElem.price,
              },
              [`dayQuest.${activeElem.property}`]: activeElem.count,
              field: serverTime,
            },
            {
              query: {
                $select: ["_id", "email", "gold", "dayQuest"],
              },
            }
          )
          .then((data) => {
            setUser((prev) => ({ ...prev, ...data }));
            setIsLoading(false);
            setActiveElem(null);
            setIsFetching(false);
          })
          .catch((e) => {
            setModalError(e);
            console.log(e);
            setIsFetching(false);
            setIsLoading(false);
            setActiveElem(null);
          });
      }
    }
  }

  return (
    <BookPages>
      <S.Page>
        <S.Header>Ежедневные задания</S.Header>
        <S.Data isDone={dayQuest.todayIsDone >= dayQuestArray.length}>
          {dataString.day}{" "}
          {dayQuest.todayIsDone < dayQuestArray.length &&
            `(до конца: ${dataString.date})`}
        </S.Data>
        <S.Text>
          {text.map((elem, i) => {
            return <span key={i}>{elem}</span>;
          })}
        </S.Text>
        {dayQuest.todayIsDone < dayQuestArray.length && (
          <>
            <S.RewardString>Награда</S.RewardString>

            <S.RewardWrapper>
              <RewardWrapper light={true} w={65}>
                <div>
                  <Reward
                    name={"gold"}
                    count={dayQuestRewards[dayQuest.todayIsDone || 0].gold}
                    w={28}
                    showCount={true}
                    round={true}
                    font={13}
                    reverseColumn={true}
                    color={"#281707"}
                  />
                </div>
              </RewardWrapper>

              <RewardWrapper light={true} w={65}>
                <div>
                  <Reward
                    name={"mana"}
                    count={dayQuestRewards[dayQuest.todayIsDone || 0].mana}
                    w={28}
                    showCount={true}
                    round={true}
                    font={13}
                    reverseColumn={true}
                    color={"#281707"}
                  />
                </div>
              </RewardWrapper>
              <RewardWrapper light={true} w={65}>
                <div>
                  <Reward
                    name={"exp"}
                    count={dayQuestRewards[dayQuest.todayIsDone || 0].exp}
                    w={28}
                    showCount={true}
                    round={true}
                    font={13}
                    reverseColumn={true}
                    color={"#281707"}
                  />
                </div>
              </RewardWrapper>
              <RewardWrapper light={true} w={65}>
                <div>
                  <Reward
                    name={"magic"}
                    count={1}
                    w={28}
                    showCount={true}
                    round={true}
                    font={13}
                    reverseColumn={true}
                    color={"#281707"}
                  />
                </div>
              </RewardWrapper>
            </S.RewardWrapper>

            {dayQuest.todayIsDone < dayQuestArray.length &&
              countDone >= dayQuestArray[dayQuest.todayIsDone || 0].length && (
                <S.GetButton>
                  <S.MarkerButton
                    width={120}
                    onClick={
                      !isFetching &&
                      !isLoading &&
                      countDone >=
                        dayQuestArray[dayQuest.todayIsDone || 0].length
                        ? getPrize.bind(
                            null,
                            dayQuestRewards[dayQuest.todayIsDone || 0]
                          )
                        : null
                    }
                  >
                    <div>
                      <span>Забрать награду</span>
                    </div>
                  </S.MarkerButton>
                </S.GetButton>
              )}
          </>
        )}
      </S.Page>
      <S.Page>
        <S.Header>Список заданий</S.Header>
        {dayQuest.todayIsDone < dayQuestArray.length ? (
          <>
            <S.PageNumber>Страница {dayQuest.todayIsDone + 1}/5</S.PageNumber>
            {dayQuestArray[dayQuest.todayIsDone || 0].map((elem, i) => {
              return (
                <React.Fragment key={elem.string + elem.count}>
                  <S.Quest isDone={dayQuest[elem.property] >= elem.count}>
                    {elem.string}
                    {elem.price &&
                      (dayQuest[elem.property] || 0) < elem.count && (
                        <img
                          src={imgGold}
                          width={20}
                          onClick={changeActive.bind(null, elem)}
                          alt=""
                        />
                      )}
                  </S.Quest>
                  <S.CountsDone isDone={dayQuest[elem.property] > elem.count}>
                    {elem.count <= 10 ? (
                      Array(elem.count)
                        .fill("")
                        .map((_, i) => {
                          return (
                            <S.Check
                              key={i}
                              isActive={dayQuest[elem.property] > i}
                            />
                          );
                        })
                    ) : (
                      <span>
                        {dayQuest[elem.property] > elem.count
                          ? elem.count
                          : dayQuest[elem.property] || 0}
                        /{elem.count}
                      </span>
                    )}
                  </S.CountsDone>
                </React.Fragment>
              );
            })}
          </>
        ) : (
          <S.Quest>Я выполнил все задания на сегодня</S.Quest>
        )}
      </S.Page>

      <Popup
        isOpen={activeElem}
        setIsOpen={setActiveElem}
        w={281}
        h={152}
        back={frame1}
      >
        <Close onClick={changeActive.bind(null, false)} />
        {activeElem && (
          <>
            <b>Желаете платно завершить задание?</b>

            <Button onClick={buyQuest}>
              <Reward
                name={"gold"}
                count={`Завершить ${activeElem.price}`}
                w={15}
                button={true}
              />
            </Button>
          </>
        )}
      </Popup>
    </BookPages>
  );
}

export default React.memo(Quests);
