import React, { useCallback, useEffect, useRef, useState } from "react";

import * as S from "./ArenaMenu.styles";

import SumCharacter from "../../components/SumCharacter/SumCharacter";

import { ButtonsWrapper } from "../ActiveBoss/ActiveBoss.styles";
import { TopButton } from "../../panels/Tops/Tops.styles";
import { getMultipleRandom } from "../../helpers";
import {
  Avatar,
  Button,
  ButtonValue,
  Lvl,
  Name,
} from "../../panels/Arena/Arena.styles";

import { ButtonValue as ButtonValueSmall } from "../Healls/Healls.styles";

import { CharactersInfo, Opponent } from "../ArenaFight/ArenaFight.styles";
import { Attack } from "../ArenaInfo/ArenaInfo.styles";
import { ButtonChange } from "../Healls/Healls.styles";
import { InputAttacks } from "../Spell/Spell.styles";

function ArenaMenu({
  app,
  user,
  setUser,
  isFetching,
  isModalOpen,
  getCharacterSum,
  isLoading,
  chooseUsers,
  timeHelp,
  maxHourFight,
  setIsInfoOpen,
  timeToNext,
  setPlayerInfo,
  setDisableMove,
  setIsLoading,
  setIsFetching,
  setModalError,
  allUsers,
  setChooseUsers,
  serverTime,
  swapOpponents,
  usersInfo,
  setUsersInfo,
  moveToUserRoom,
  bossesInfo,
  setBossesInfo,
}) {
  const [countAttacks, setCountAttacks] = useState(bossesInfo?.arenaX || 1);
  const maxCharac = useRef(100);
  const [maxValue, setMaxValue] = useState(maxCharac.current);
  const [minValue, setMinValue] = useState(1);

  useEffect(() => {
    if (timeHelp) {
      let maxCount =
        maxHourFight -
        (timeHelp ? user.arena.countDayFight : user.arena.countHourFight);

      if (maxCount > 10) {
        maxCount = 10;
      }

      if (countAttacks > maxCount) {
        setCountAttacks(maxCount || 1);
      }
    } else {
      setCountAttacks(1);
    }
  }, [timeHelp, maxHourFight, user]);

  useEffect(() => {
    setBossesInfo((prev) => ({
      ...prev,
      arenaX: countAttacks,
    }));
  }, [countAttacks]);

  function changeCount(add) {
    let maxCount =
      maxHourFight -
      (timeHelp ? user.arena.countDayFight : user.arena.countHourFight);

    if (maxCount > 10) {
      maxCount = 10;
    }

    setCountAttacks((prev) => {
      if (add) {
        if (+prev + 1 > maxCount) {
          return maxCount;
        }
        return +prev + 1;
      } else {
        if (+prev - 1 >= 1) {
          return +prev - 1;
        }
      }
      return +prev;
    });
  }

  function handleInput(e) {
    let maxCount =
      maxHourFight -
      (timeHelp ? user.arena.countDayFight : user.arena.countHourFight);

    if (maxCount > 10) {
      maxCount = 10;
    }

    let value = e.target.value;
    if (value > maxCount) {
      if (value >= 1) {
        value = maxCount;
      } else {
        value = 1;
      }
    }

    setCountAttacks(value ? Math.abs(+Math.floor(value)) : "");
  }

  function startFight(character) {
    if (
      (timeHelp &&
        user.arena.countDayFight + (countAttacks || 1) > maxHourFight) ||
      user.arena.countHourFight + (countAttacks || 1) > maxHourFight
    ) {
      setModalError("Вы достигли лимита дуэлей");
      console.log("Вы достигли лимита дуэлей");
    } else {
      setIsFetching(true);
      setIsLoading(true);
      setDisableMove(true);
      const me = setPlayerInfo(user);
      const enemy = setPlayerInfo(character);

      const meSum = getCharacterSum(user);
      const enemySum = getCharacterSum(character);

      app
        .service("users")
        .patch(
          user._id,
          {
            "arena.isOver": false,
            "arena.countX": countAttacks || 1,
            "arena.me": {
              ...me,
              fullHp: me.hp,
              lvl: user.lvl,
              sum: meSum,
              name: user.name,
              nickName: user.nickName,
              img: user.photo,
              id: user._id,
              faculty: user.faculty,
              orden: user.orden,
            },
            "arena.enemy": {
              ...enemy,
              fullHp: enemy.hp,
              lvl: character.lvl,
              sum: enemySum,
              name: character.name,
              nickName: character.nickName,
              img: character.photo,
              id: character._id,
              orden: character.orden,
              faculty: character.faculty,
            },
            common: true,
          },
          {
            query: {
              $select: ["_id", "email", "arena"],
            },
          }
        )
        .then((data) => {
          setUser((prev) => ({ ...prev, ...data }));
          setIsFetching(false);
          setIsLoading(false);
        })
        .catch((e) => {
          setModalError(e);
          console.log(e);
          setIsLoading(false);
          setIsFetching(false);
          setDisableMove(false);
        });
    }
  }

  const setFilter = useCallback(() => {
    if (allUsers.length > 0) {
      maxCharac.current = allUsers.sort(
        (a, b) => b.allCharac - a.allCharac
      )[0].allCharac;
      if (user.arenaFilter.length) {
        changeRange(user.arenaFilter[0], user.arenaFilter[1]);
        setMinValue(user.arenaFilter[0]);
        setMaxValue(user.arenaFilter[1]);
      } else {
        changeRange(1, maxCharac.current);
        setMinValue(1);
        setMaxValue(maxCharac.current);
      }
    }
  }, [user]);

  useEffect(() => {
    setFilter();
  }, [allUsers]);

  useEffect(() => {
    setChooseUsers(getMultipleRandom(usersInfo, 3));
  }, [usersInfo]);

  useEffect(() => {
    changeRange(minValue, maxValue);
  }, [minValue, maxValue]);

  const handleInputMin = (e) => {
    let value = e.target.value;
    if (!value) {
      setMinValue("");
    } else {
      if (value >= maxValue) {
        value = maxValue - 1;
      }
      if (value < 1) {
        value = 1;
      }
      setMinValue(value ? Math.abs(+Math.floor(value)) : "");
    }
  };

  const handleInputMax = (e) => {
    let value = e.target.value;

    if (!value) {
      setMaxValue("");
    } else {
      if (value >= maxCharac.current) {
        value = maxCharac.current;
      }
      if (value <= 1) {
        value = 1;
      }
      setMaxValue(value ? Math.abs(+Math.floor(value)) : "");
    }
  };

  function changeRange(min, max) {
    if (allUsers.length > 0) {
      setUsersInfo(
        allUsers.filter((el) => {
          if (
            el.email !== user.email &&
            el.allCharac >= min &&
            el.allCharac <= max
          ) {
            return el;
          }
        })
      );
    }
  }

  function saveFilter(minValue, maxValue) {
    if (minValue !== user.arenaFilter[0] || maxValue !== user.arenaFilter[1]) {
      setIsFetching(true);
      setIsLoading(true);
      app
        .service("users")
        .patch(
          user._id,
          {
            arenaFilter: [minValue, maxValue],
            field: serverTime,
          },
          {
            query: {
              $select: ["_id", "email", "arenaFilter"],
            },
          }
        )
        .then((data) => {
          setUser((prev) => ({ ...prev, ...data }));
          setIsFetching(false);
          setIsLoading(false);
        })
        .catch((e) => {
          setModalError(e);
          console.log(e);
          setIsFetching(false);
          setIsLoading(false);
        });
    }
  }

  return (
    <React.Fragment>
      <S.FastInfo>
        <div>
          <span>Мои характеристики</span>
          <ButtonValue width={140}>
            <div>
              <SumCharacter getCharacterSum={getCharacterSum} />
            </div>
          </ButtonValue>
        </div>

        <div>
          <span>Лимит дуэлей</span>
          <ButtonValue width={140}>
            <div>
              {timeHelp ? user.arena.countDayFight : user.arena.countHourFight}/
              {maxHourFight}
            </div>
          </ButtonValue>
        </div>

        <div>
          <span>Сброс лимита</span>
          <ButtonValue width={140}>
            <div>{timeToNext}</div>
          </ButtonValue>
        </div>
      </S.FastInfo>

      <S.OpponentsWrapper>
        {chooseUsers.length > 0 &&
          chooseUsers.map((enemy, i) => {
            return (
              <Opponent key={enemy._id} faculty={enemy.faculty}>
                <div>
                  <Lvl>
                    <span>{enemy.lvl} уровень</span>
                  </Lvl>

                  <Avatar onClick={moveToUserRoom.bind(null, enemy)}>
                    <img
                      src={enemy.photo}
                      width={100}
                      height={100}
                      alt={"аватар"}
                    />
                  </Avatar>

                  <Name>
                    <span>
                      {enemy.orden?.tag ? `[${enemy.orden?.tag}] ` : ""}
                    </span>
                    <span>
                      {enemy.nickName
                        ? enemy.nickName
                        : `${enemy.name?.firstName} ${enemy.name?.lastName}`}
                    </span>
                  </Name>

                  <CharactersInfo>
                    <ButtonValue width={110}>
                      <div>
                        <SumCharacter
                          isRound={true}
                          user={enemy}
                          getCharacterSum={getCharacterSum}
                        />
                      </div>
                    </ButtonValue>
                  </CharactersInfo>

                  <Attack helper={timeHelp}>
                    {timeHelp && (
                      <S.ButtonChangeWrapper>
                        <ButtonChange
                          islast={true}
                          onClick={changeCount.bind(null, true)}
                        >
                          <div>+1</div>
                        </ButtonChange>
                        <ButtonValueSmall islast={false}>
                          <InputAttacks>
                            <input
                              value={countAttacks}
                              onChange={handleInput}
                              type="number"
                              max={50}
                              min={1}
                              placeholder={""}
                              step={1}
                            />
                          </InputAttacks>
                        </ButtonValueSmall>
                        <ButtonChange
                          islast={false}
                          onClick={changeCount.bind(null, false)}
                        >
                          <div>-1</div>
                        </ButtonChange>
                      </S.ButtonChangeWrapper>
                    )}

                    <Button
                      width={110}
                      disabled={isLoading || isFetching || isModalOpen}
                      onClick={
                        !isLoading && !isFetching && !isModalOpen
                          ? startFight.bind(null, enemy)
                          : null
                      }
                    >
                      <div>Напасть</div>
                    </Button>
                  </Attack>
                </div>
              </Opponent>
            );
          })}
      </S.OpponentsWrapper>

      <S.FilterString>
        <div>
          <S.FiltersCharac>
            <span>Фильтр: от</span>

            <ButtonValue width={160}>
              <div>
                <input
                  value={minValue}
                  onChange={handleInputMin}
                  type="number"
                  max={maxValue - 1}
                  min={1}
                  placeholder={""}
                  step={1}
                />
              </div>
            </ButtonValue>

            <span>до</span>

            <ButtonValue width={160}>
              <div>
                <input
                  value={maxValue}
                  onChange={handleInputMax}
                  type="number"
                  max={maxCharac.current}
                  min={1}
                  placeholder={""}
                  step={1}
                />
              </div>
            </ButtonValue>
          </S.FiltersCharac>

          <ButtonsWrapper>
            <Button
              width={110}
              onClick={
                !isLoading &&
                !isFetching &&
                !isModalOpen &&
                chooseUsers.length > 0
                  ? saveFilter.bind(null, minValue, maxValue)
                  : null
              }
            >
              <div>Сохранить</div>
            </Button>

            <Button
              width={110}
              onClick={
                !isLoading &&
                !isFetching &&
                !isModalOpen &&
                chooseUsers.length > 0
                  ? swapOpponents
                  : null
              }
            >
              <div>Обновить</div>
            </Button>
          </ButtonsWrapper>
        </div>
      </S.FilterString>

      <TopButton>
        <Button
          width={130}
          onClick={
            !isLoading && !isFetching && !isModalOpen && chooseUsers.length > 0
              ? () => {
                  setIsInfoOpen(true);
                }
              : null
          }
        >
          <div>Моя карточка</div>
        </Button>
      </TopButton>
    </React.Fragment>
  );
}

export default React.memo(ArenaMenu);
