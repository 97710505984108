import styled, { keyframes } from "styled-components";
import buttonLeft from "../../img/Buttonleft.png";

export const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const show = () => keyframes`
  from {
  pointer-events: none;
  opacity: 0;
  }

  to {
  pointer-events: auto;
  opacity: 1;
  }
`;

export const ImgAnswer = styled.div`
  position: absolute;
  transform: translate(-72px, -70px);
  left: ${(props) => (props.x ? props.x : "0")}px;
  top: ${(props) => (props.y ? props.y : "0")}px;
  animation: ${() => show()} 0.5s ease;

  & > div {
    position: relative;

    & > img {
      transform: rotate(${(props) => (props.scale ? props.scale : "0")}deg);
      position: absolute;
      width: ${(props) => (props.w ? props.w : "0")}px;
      height: ${(props) => (props.h ? props.h : "0")}px;
      clip-path: ${(props) => (props.polygon ? props.polygon : "unset")};
    }

    & > img:nth-child(2) {
      cursor: pointer;
      opacity: 0;
      transition: opacity 0.3s linear;
    }

    & > img:nth-child(2):hover {
      opacity: 1;
    }
  }
`;

export const QuestionText = styled.div`
  animation: ${() => show()} 0.5s ease;
  text-align: center;
  position: absolute;
  width: 210px;
  height: 90px;
  left: 50%;
  transform: translateX(-50%);
  top: -30px;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Bellota-Regular";
  color: #281707;
`;

export const StartWrapper = styled.div`
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  width: 310px;
  height: 440px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 9px;
  text-align: center;
  align-items: center;
  font-family: "Bellota-Regular";
  color: #000000;
  font-size: 12px;
  line-height: 13px;
  z-index: 1;
`;

export const TestWrapper = styled.div`
  animation: ${() => show()} 0.5s ease;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  width: 320px;
  height: 460px;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;
  align-items: center;
  font-family: Inter;
  color: #000000;
  font-size: 13px;
  line-height: 15px;
  z-index: 1;
`;

export const FinishWrapper = styled.div`
  animation: ${() => show()} 0.5s ease;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  width: 320px;
  height: 460px;
  padding: 35px 15px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  font-family: "Bellota-Regular";
  color: #281707;
  font-size: 14px;
  line-height: 17px;
  z-index: 1;
`;

export const NameFaculty = styled.div`
  margin: 20px 0;
  font-size: 20px;
  font-weight: 600;
`;

export const ImgFaculty = styled.img`
  margin: 12px 0;
`;

export const LastTestTest = styled.div`
  margin: 20px 0;
`;

export const Podpis = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

export const QuestionTest = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: start;
  width: 100%;
  gap: 4px;
  & > hr {
    width: 100%;
    margin: 0;
    padding: 0;
    height: 0;
    border: none;
    border-top: 1px solid #000000;
  }
  & > span {
    font-weight: 600;
  }
  & > div {
    display: flex;
    flex-direction: column;
    gap: 2px;
    font-size: 12px;
    line-height: 13px;
  }
`;

export const TestAnswer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
  & > div {
    position: relative;
    width: 14px;
    height: 14px;
    border: 1px solid #000;
    &::after {
      transition: 0.1s ease;
      opacity: ${(props) => (props.isActive ? 1 : 0)};
      content: "";
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      width: 11px;
      height: 11px;
      background: #6a3700;
    }
  }
`;

export const StartButton = styled.div`
  cursor: ${(props) => !props.disabled && "pointer"};
  width: 95%;
  position: relative;

  &::before {
    aspect-ratio: 25 / 50;
    height: 170%;
    position: absolute;
    left: 0;
    top: 50%;
    content: "";
    background-image: url(${buttonLeft});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transform: translate(-35%, -50%);
  }
  &::after {
    aspect-ratio: 25 / 50;
    height: 170%;
    position: absolute;
    right: 0;
    top: 50%;
    content: "";
    background-image: url(${buttonLeft});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transform: translate(35%, -50%) rotateZ(180deg);
  }

  border-radius: 36px;
  box-shadow: 0px 0.5px 0px 1px #281707;
  background-color: ${(props) =>
    props.isActive
      ? "#8d2800"
      : props.isDone
      ? "#364500"
      : props.disabled
      ? "#140C02"
      : "#6B3700"};

  transition: background-color 0.15s linear;

  &:hover {
    background-color: ${(props) =>
      props.isActive
        ? "#8d2800"
        : props.isDone
        ? "#364500"
        : props.disabled
        ? "#140C02"
        : "#7d4100"};
  }
  &:active {
    background-color: ${(props) =>
      props.isActive
        ? "#8d2800"
        : props.isDone
        ? "#364500"
        : props.disabled
        ? "#140C02"
        : "#552a00"};
  }

  & > div {
    box-shadow: 0px 0px 0px 1px #281707 inset;
    border: 2px solid #e98101;
    border-radius: 36px;
    padding: 2px 10px;
    color: #fcc382;
    font-family: "Bellota-Regular";
    font-size: 13px;
    line-height: 17px;
  }
`;

export const StartHeader = styled.div`
  font-size: 13px;
  font-weight: 600;
  padding-bottom: 5px;
  color: #281707;
`;

export const StartSign = styled.div`
  font-weight: 600;
  color: #281707;
`;

export const LineSing = styled.div`
  width: 90px;
  margin: 0;
  padding: 0;
  height: 0;
  border: none;
  border-top: 1px solid #000000;
`;
