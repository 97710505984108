import styled from "styled-components";

import imgSliz from "../../img/arena/Sliz.png";
import imgGriff from "../../img/arena/Griff.png";
import imgPuff from "../../img/arena/Puff.png";
import imgKogt from "../../img/arena/Kogt.png";

import buttonConnect from "../../img/ButtonConnect.png";
import { Name } from "../../panels/Arena/Arena.styles";

export const LogWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  overflow-y: hidden;
  max-height: 332px;

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: #f7b471;
    text-shadow: 0 0 4px #000000;
    font-weight: 600;
    text-align: center;
  }
`;

export const RoundNumber = styled.div`
  margin-bottom: 10px;
`;

export const BottomVector = styled.img`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

export const RoundWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 1fr 0.8fr;
  gap: 2px;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Result = styled.div`
  color: ${(props) =>
    props.color === 1 ? "#ff3232" : props.color === 2 ? "#2eb32e" : "#f7b471"};
  font-size: 12px;
  line-height: 12px;
  text-align: center;
`;

export const Round = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > span {
    font-size: 12px;
    line-height: 12px;
  }
`;

export const ImgsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
`;

export const Rewards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

export const FightWrapper = styled.div`
  z-index: 1;
  width: 100%;
  display: flex;
  gap: 2px;
  justify-content: space-between;
  & > div > div {
    margin-top: 71px;
    height: 332px;
    width: 223px;
  }

  & > div:nth-child(2) {
    display: flex;
    flex: 1;

    & > div {
      width: 100%;
    }
  }
`;

export const Opponent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 15px;

  background-image: url("${(props) =>
    props.faculty === "slytherin"
      ? imgSliz
      : props.faculty === "ravenclaw"
      ? imgKogt
      : props.faculty === "hufflepuff"
      ? imgPuff
      : imgGriff}");
  background-size: contain;

  & > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const ButtonsGetWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;

  position: absolute;
  bottom: 95px;
`;

export const ButtonsGet = styled.div`
  width: 52px;
  height: 52px;
  cursor: pointer;
  position: relative;

  & > img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  & > img:nth-child(3) {
    transition: opacity 0.2s linear;
    opacity: 0;
  }

  & > img:nth-child(2) {
    transition: opacity 0.2s linear;
    opacity: ${(props) => (props.active ? "0" : "1")};
  }

  &:hover > img:nth-child(3) {
    opacity: ${(props) => (props.active ? "0" : "1")};
  }
`;

export const NameStyled = styled.div`
  width: 180px;
  position: absolute;
  bottom: 180px;
  transform: translateY(50%);

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  color: #f7b471;
  text-shadow: 0 0 #281707;
  font-weight: 600;
  line-height: 14px;
`;

export const BottomButton = styled.div`
  position: absolute;
  left: 50%;
  bottom: 70px;
  transform: translateX(-50%);
  z-index: 1;
`;

export const HealthInfo = styled.div`
  position: absolute;
  bottom: 40px;
  color: #281707;
  font-weight: 600;
  text-shadow: 0 0 #281707;

  & > div:nth-child(1) {
    font-size: 13px;
    display: flex;
    justify-content: center;
    gap: 2px;
  }
`;

export const ReadyInfo = styled.div`
  position: absolute;
  bottom: 22px;
  color: #f7b471;
  text-shadow: 0 0 #281707;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
`;
