import styled from "styled-components";
import buttonConnect from "../../img/ButtonConnect.png";

export const Charact = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  min-width: 130px;
  max-width: 130px;
`;

export const ImgCharac = styled.div`
  position: relative;
  display: flex;
  cursor: help;
  & > img:hover + div {
    opacity: 1;
    visibility: visible;
  }
`;

export const Info = styled.div`
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: 0.15s linear;
  display: flex;
  width: 250px;
  height: 150px;
  position: absolute;
  transform: translate(-50%, -100%) scale(1);
  left: 50%;
  top: -25px;
  cursor: default;
  font-size: 13px;
  line-height: 15px;
  color: #ffe3c8;
  font-family: "Bellota-Regular";

  background: rgba(17, 12, 9, 0.85);
  border-radius: 10px;

  outline: 1px solid #281707;
  box-shadow: inset 0px 0px 0px 1px #281707;
  border: 2px solid #fc9800;

  & > div {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
  }
`;

export const ButtonChangeWrapper = styled.div`
  display: flex;
  flex-flow: row-reverse;
  gap: 2px;
  align-items: center;
`;

export const ButtonValue = styled.div`
  width: 40px;
  position: relative;

  &::after {
    visibility: ${(props) => props.islast && "hidden"};
    width: 12px;
    height: 8px;
    transform: translate(50%, -50%);
    position: absolute;
    right: -1px;
    top: 50%;
    content: "";
    background-image: url(${buttonConnect});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  border-radius: 7px;
  box-shadow: 0px 0.5px 0px 1px #281707;
  background: linear-gradient(0deg, #3d2713 0%, #3d2713 100%),
    linear-gradient(180deg, rgba(78, 39, 0, 0) 0%, #4e2700 100%),
    linear-gradient(
      180deg,
      rgba(255, 217, 159, 0.1) 0%,
      rgba(255, 223, 176, 0) 42.19%
    ),
    linear-gradient(0deg, #6b3700 0%, #6b3700 100%),
    linear-gradient(180deg, rgba(49, 24, 0, 0) 0%, #4e2700 100%),
    linear-gradient(
      180deg,
      rgba(255, 217, 159, 0.1) 0%,
      rgba(255, 223, 176, 0) 42.19%
    ),
    #201102;

  & > div {
    transition: filter 0.15s linear;
    filter: ${(props) => props.disabled && "brightness(0.7)"};
    display: flex;
    justify-content: center;
    box-shadow: 0px 0px 0px 1px #281707 inset;
    border: 2px solid #e98101;
    border-radius: 7px;
    padding: 1px;
    color: #fcc382;
    font-family: "Bellota-Regular";
    font-size: 11px;
    line-height: 14px;
  }
`;
