import React, { useState } from "react";
import * as S from "./ArenaCharact.styles";
import { InputAttacks } from "../Spell/Spell.styles";
import Reward from "../Reward/Reward";
import { ButtonChange } from "../Healls/Healls.styles";

function ArenaCharact({ characteristic, charac, changeMoney, isLoading }) {
  const [count, setCount] = useState(1);

  function handleInput(e) {
    let value = e.target.value;
    if (value > 500) {
      if (value >= 1) {
        value = 500;
      } else {
        value = 1;
      }
    }
    setCount(value ? Math.abs(+Math.floor(value)) : "");
  }

  return (
    <S.Charact>
      <div>{charac[1].name}</div>
      <S.ImgCharac>
        <img src={charac[1].img} width={65} height={65} alt="эссенции" />
        <S.Info>
          <div>
            <span>
              {charac[1].name}: {characteristic[charac[0]].toLocaleString("ru")}
            </span>
            <span>{charac[1].string}</span>
          </div>
        </S.Info>
      </S.ImgCharac>

      <S.ButtonChangeWrapper>
        <ButtonChange
          islast={true}
          onClick={
            !isLoading ? changeMoney.bind(null, { [charac[0]]: +count }) : null
          }
        >
          <div>+{count}</div>
        </ButtonChange>
        <S.ButtonValue islast={false}>
          <InputAttacks>
            <input
              value={count}
              onChange={handleInput}
              type="number"
              max={500}
              min={1}
              placeholder={""}
              step={1}
            />
          </InputAttacks>
        </S.ButtonValue>
        <ButtonChange
          islast={false}
          onClick={
            !isLoading ? changeMoney.bind(null, { [charac[0]]: -count }) : null
          }
        >
          <div>-{count}</div>
        </ButtonChange>
      </S.ButtonChangeWrapper>
      <div>
        <Reward
          name={"essence"}
          count={`Цена: ${(characteristic[charac[0]] + 1) * 10}`}
          w={15}
          button={true}
        />
      </div>
    </S.Charact>
  );
}

export default React.memo(ArenaCharact);
