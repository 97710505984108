import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  width: ${(props) => (props.width ? `${props.width}px` : "100%")};

  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  & span {
    width: 100%;
    font-size: 16px;
  }

  & input {
    width: 100%;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & div {
    display: flex;
  }
`;
