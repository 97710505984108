import styled from "styled-components";

import buttonConnect from "../../img/ButtonConnect.png";
import imgMarker1 from "../../img/Marker1.png";
import imgMarker2 from "../../img/Marker2.png";
import imgDiag from "../../img/book/Diagramma.png";
import imgTalents from "../../img/talents/TalentsBack.png";
import imgBlock from "../../img/talents/Block.png";
import { MarkerButton } from "../Quests/Quests.styles";

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Bellota-Regular";
  color: #413423;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 17px;
  line-height: 15px;
  font-family: "Inkverse-BWDRx";
`;

export const Points = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  font-size: 18px;
  line-height: 18px;
  font-family: "Inkverse-BWDRx";
  padding-bottom: 5px;
`;

export const ActiveWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const MarkerActive = styled.div`
  padding: 4px;
  background-image: url(${imgMarker2});
  background-size: contain;
  display: flex;
  justify-content: center;
  text-align: center;
  color: #413423;
  font-family: "Inkverse-BWDRx";
  font-size: 14px;
  margin-bottom: 2px;
`;

export const Progress = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 5px;
  position: relative;

  &:after {
    width: max-content;
    top: 0px;
    left: 50%;
    transform: translate(-50%, -80%) scale(0.85);
    position: absolute;
    content: "Прогресс: ${(props) => props.percent}%";
    visibility: hidden;
    opacity: 0;
    color: #f7b471;
    font-size: 12px;
    transition: all 0.1s linear;
    padding: 3px 6px;
    background: rgba(17, 12, 9, 0.8);
    border-radius: 5px;

    outline: 1px solid #281707;
    box-shadow: inset 0px 0px 0px 1px #281707;
    border: 2px solid #fc9800;
    z-index: 1;
  }
  &:hover:after {
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, -100%) scale(1);
  }

  & > div {
    display: flex;
    position: relative;
    width: 250px;
    height: 14px;

    &::before {
      z-index: 1;

      width: 15px;
      height: 10px;
      transform: translate(-25%, -50%);
      position: absolute;
      left: 0;
      top: 50%;
      content: "";
      background-image: url(${buttonConnect});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    &::after {
      width: 15px;
      height: 10px;
      transform: translate(25%, -50%);
      position: absolute;
      right: 0;
      top: 50%;
      content: "";
      background-image: url(${buttonConnect});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    & > img {
      width: 100%;
      height: 100%;
    }
  }
`;

export const Bar = styled.div`
  top: 5px;
  left: 4px;
  position: absolute;
  transition: width 0.3s linear;
  width: ${(props) => `calc(242px * ${props.progress})`};
  height: 5px;
  border-radius: 3px;
  background-color: #281707;
`;

export const Clear = styled.div`
  width: 100%;
  display: flex;
  padding-top: 10px;

  & > div {
    width: 100%;
  }
`;

export const DamageInfo = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  & > div {
    display: flex;
    gap: 5px;
    justify-content: space-between;
    font-size: 14px;
    line-height: 14px;
    font-family: "Inkverse-BWDRx";
  }
`;

export const Text = styled.div`
  display: flex;
  justify-content: center;
  font-family: "Bellota-Regular";
  color: #413423;
  font-size: ${(props) => (props.size ? props.size : "12")}px;
  line-height: ${(props) => (props.size ? props.size : "14")}px;
  font-weight: 600;
  text-align: center;
`;

export const BlockTalent = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-family: "Bellota-Regular";
  color: #413423;
  font-size: ${(props) => (props.size ? props.size : "12")}px;
  line-height: ${(props) => (props.size ? props.size : "14")}px;
  font-weight: 600;
  text-align: center;

  & > div {
    padding: 4px 8px;
    border-radius: 3px;
    border: 2px solid #bf2828;
  }
`;

export const MarkerButtonStyled = styled(MarkerButton)`
  color: #cab189;
`;

export const MarkerInfo = styled.div`
  opacity: ${(props) => (props.isActive ? "1" : "0")};
  font-size: ${(props) => (props.size ? props.size : "14")}px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #e0bc79;
  font-family: "Inkverse-BWDRx";
  font-weight: 400;
  & > div {
    display: flex;
    gap: 4px;
    border-radius: ${(props) => (props.radius ? props.radius : "6px")};
    padding: ${(props) => (props.padding ? props.padding : "4px 8px")};
    background-image: url("${imgMarker1}");
    background-size: contain;
  }
`;

export const Counts = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: center;
  gap: 3px;
  & > div {
    font-size: 11px;
    line-height: 11px;
  }
`;

export const Circle = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    position: relative;
    width: 240px;
    height: 240px;
    border-radius: 50%;
    background-image: url("${imgTalents}");
    background-size: cover;
  }
`;

export const Common = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    position: relative;
    width: 300px;
    height: 360px;
    background-image: url(${imgDiag});
    background-size: cover;
  }
`;

export const CharacterTalent = styled.div`
  display: flex;
  position: absolute;
  transform: translate(-50%, -50%);
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
  cursor: pointer;

  &:hover > img {
    filter: contrast(${(props) => (props.isActive ? "1.2" : "1")})
      drop-shadow(${(props) => (!props.isActive ? "0px 0px 10px white" : "0")});
  }

  & > img {
    transition: filter 0.2s linear;
    filter: contrast(${(props) => (props.isActive ? "1.2" : "1")})
      ${(props) => (props.isActive ? "drop-shadow(0px 0px 10px white)" : "")};
  }

  & > div {
    transition: opacity 0.2s linear;
    display: flex;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 25%);
    line-height: 14px;
    & > div {
      min-width: 30px;
      justify-content: center;
    }
  }

  &:hover > div {
    opacity: 1;
  }
`;

export const CommonTalent = styled.div`
  display: flex;
  position: absolute;
  transform: translate(-50%, -50%);
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
  cursor: pointer;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    &:after {
      position: absolute;
      width: 15px;
      height: 18px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      content: "";
      background-image: ${(props) =>
        props.isDisable ? `url("${imgBlock}")` : "unset"};
      background-size: cover;
    }

    &:hover > img {
      filter: contrast(${(props) => (props.isActive ? "1.2" : "1")})
        drop-shadow(
          ${(props) => (!props.isActive ? "0px 0px 10px white" : "0")}
        );
    }

    & > img {
      transition: filter 0.2s linear;
      filter: contrast(${(props) => (props.isActive ? "1.2" : "1")})
        ${(props) => (props.isActive ? "drop-shadow(0px 0px 10px white)" : "")};
    }

    & > div {
      transition: opacity 0.2s linear;
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 25%);
      line-height: 14px;
      & > div {
        min-width: 30px;
        justify-content: center;
      }
    }

    &:hover > div {
      opacity: 1;
    }
  }
`;

export const ActiveTalent = styled.div`
  display: flex;
  justify-content: center;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 5px;

  & > button {
    display: flex;
    gap: 5px;
    line-height: 15px;
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #281707;
  margin: 10px 0;
`;

export const UpButton = styled.div`
  width: 100%;
  display: flex;

  & > div {
    width: 100%;
  }
`;
