import styled from "styled-components";

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
`;

export const FilterString = styled.div`
  display: flex;
  width: 100%;
  padding: 12px;
  background: rgba(17, 12, 9, 0.8);
  border-radius: 8px;
  outline: 1px solid #281707;
  box-shadow: inset 0px 0px 0px 1px #281707;
  border: 2px solid #fc9800;
  color: #ffcf82;
  & > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
  }
`;

export const FiltersCharac = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 13px;
  line-height: 1.5;

  & input {
    text-align: center;
    width: 100%;
    border: none !important;
    background-color: rgba(255, 255, 255, 0) !important;
    outline: none !important;
    font-family: "Bellota-Regular";
    color: #ffcf82 !important;
    -moz-appearance: textfield !important;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
    }
    ::placeholder {
      color: #ffcf82 !important;
      text-transform: none;
    }
  }
`;

export const Rewards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

export const FastInfo = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    font-size: 14px;
    line-height: 1.5;
    color: #e1b680;
  }
`;

export const OpponentsWrapper = styled.div`
  width: 100%;
  max-height: 332px;
  min-height: 332px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
`;

export const ButtonChangeWrapper = styled.div`
  display: flex;
  flex-flow: row-reverse;
  gap: 2px;
  align-items: center;
`;
