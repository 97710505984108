import styled, { css, keyframes } from "styled-components";
import { Button as ButtonBoss } from "../../components/ActiveBoss/ActiveBoss.styles";

import buttonConnect from "../../img/ButtonConnect.png";

export const RewardsPopup = styled.div`
  display: flex;
  gap: 3px;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  line-height: 10px;
`;

export const Rewards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

export const Info = styled.div`
  display: flex;
  position: absolute;
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  cursor: pointer;
  opacity: 0;
  transition: 0.3s linear;
  &:hover {
    opacity: 1;
  }

  & > img {
    width: 100%;
    height: 100%;
  }
`;

export const Item = styled.div`
  position: absolute;
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
  opacity: ${(props) => (props.isActive ? "1" : "0")};
  transition: 0.3s linear;
  cursor: ${(props) => (!props.isDone ? "pointer" : "default")};

  &:hover {
    opacity: ${(props) => (!props.isDone ? "1" : "0")};
  }
`;

export const Popup = styled.div`
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  position: absolute;
  width: 140px;
  height: 45px;
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
  color: #fcc382;
  font-family: "Bellota-Regular";

  background: rgba(17, 12, 9, 0.7);
  border-radius: 10px;

  outline: 1px solid #281707;
  box-shadow: inset 0px 0px 0px 1px #281707;
  border: 2px solid #fc9800;

  & > div {
    padding: 2px 4px;
    position: absolute;
    top: 0;
    display: flex;
    gap: 3px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    & > div:nth-child(1) {
      font-size: 14px;
      line-height: 14px;
    }
  }
`;

export const PopupActive = styled.div`
  position: absolute;
  width: 140px;
  height: 120px;
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
  color: #fcc382;
  font-family: "Bellota-Regular";

  background: rgba(17, 12, 9, 0.7);
  border-radius: 10px;

  outline: 1px solid #281707;
  box-shadow: inset 0px 0px 0px 1px #281707;
  border: 2px solid #fc9800;

  & > div {
    text-align: center;
    padding: 2px 4px 20px 4px;
    position: absolute;
    top: 0;
    display: flex;
    gap: 6px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    & > div:nth-child(1) {
      font-size: 12px;
      line-height: 12px;
    }
    & > div:nth-child(2) {
      font-size: 14px;
      line-height: 14px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  bottom: -12px;
`;

export const ButtonChangeWrapper = styled.div`
  display: flex;
  flex-flow: row-reverse;
  gap: 2px;
`;
