import React, { useState } from "react";
import InputNumber from "../InputNumber/InputNumber";
import * as S from "./InventoryElem.styles";
import Reward from "../Reward/Reward";
import { roundConsumablesFixed } from "../../helpers";
import {
  ButtonBottom,
  InputWrapper,
  Minus,
  Plus,
  TopString,
  TopWrapper,
} from "../ShopElem/ShopElem.styles";
import inputImg from "../../img/InputWrapper.png";

function InventoryElem({
  elem,
  loading,
  isModalOpen,
  useItem,
  setIsPopupOpen = null,
  width,
}) {
  const [count, setCount] = useState(1);

  function handleUse(elem, count, setIsPopupOpen) {
    let countLocal = count > elem.count ? elem.count : count;
    useItem(elem, countLocal, setIsPopupOpen);
  }

  function changeCount(number) {
    const max = elem.count;

    if (count + number <= max && count + number > 0) {
      setCount(count + number);
    } else {
      if (count + number > max && max >= 1) {
        setCount(max);
      } else {
        setCount(1);
      }
    }
  }

  return (
    <S.Wrapper width={width}>
      {elem && (
        <>
          <TopWrapper>
            <TopString>
              {elem.categoryName} {elem.name} (
              {roundConsumablesFixed(elem.count)})
            </TopString>

            {elem.category === "hp" ||
            elem.category === "mana" ||
            elem.category === "energy" ? (
              <Reward
                name={"potion"}
                category={elem.category}
                potionName={elem.name}
                w={36}
                button={true}
              />
            ) : (
              <Reward name={elem.category} w={36} button={true} />
            )}

            <InputWrapper>
              <img src={inputImg} alt="" />

              <Minus onClick={changeCount.bind(null, -1)}>-</Minus>
              <InputNumber
                count={count}
                min={1}
                max={elem.count}
                setCount={setCount}
              />
              <Plus onClick={changeCount.bind(null, 1)}>+</Plus>
            </InputWrapper>
          </TopWrapper>

          <ButtonBottom
            onClick={
              !loading && !isModalOpen
                ? handleUse.bind(null, elem, +count, setIsPopupOpen)
                : null
            }
          >
            <div>Использовать {roundConsumablesFixed(+count)}</div>
          </ButtonBottom>
        </>
      )}
    </S.Wrapper>
  );
}

export default React.memo(InventoryElem);
