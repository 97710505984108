import React, { useCallback, useEffect, useRef, useState } from "react";
import Dice from "../Dice/Dice";

import * as S from "./DiceGameTournament.styles";
import Popup from "../Popup/Popup";
import Reward from "../Reward/Reward";

import { Close } from "../../App.styles";
import { RewardWrapper } from "../DayReward/DayReward.styles";
import frame3 from "../../img/Frame3.png";
import { Button } from "../../panels/Arena/Arena.styles";

const activeDices = 4;
const maxRolls = 6;
const maxGames = 40;

const coordsDices = [
  {
    x: 0,
    y: -60,
  },
  {
    x: 60,
    y: 0,
  },
  {
    x: -60,
    y: 0,
  },
  {
    x: 0,
    y: 60,
  },
];

const pointsComb = [
  {
    numbers: 1,
    counts: {
      2: 1,
      3: 2,
      4: 4,
    },
  },
  {
    numbers: 2,
    counts: {
      2: 2,
      3: 4,
      4: 8,
    },
  },
  {
    numbers: 3,
    counts: {
      2: 4,
      3: 8,
      4: 16,
    },
  },
  {
    numbers: 4,
    counts: {
      2: 6,
      3: 12,
      4: 24,
    },
  },
  {
    numbers: 5,
    counts: {
      2: 8,
      3: 18,
      4: 36,
    },
  },
  {
    numbers: 6,
    counts: {
      2: 10,
      3: 20,
      4: 40,
    },
  },
];

const prizes = [
  {
    numbers: 1,
    counts: {
      2: {
        property: "silver",
        name: "серебро",
        count: 100,
      },
      3: {
        property: "silver",
        name: "серебро",
        count: 200,
      },
      4: {
        property: "silver",
        name: "серебро",
        count: 600,
      },
    },
  },
  {
    numbers: 2,
    counts: {
      2: {
        property: "mana",
        name: "мана",
        count: 5,
      },
      3: {
        property: "mana",
        name: "мана",
        count: 10,
      },
      4: {
        property: "mana",
        name: "мана",
        count: 30,
      },
    },
  },
  {
    numbers: 3,
    counts: {
      2: {
        property: "silver",
        name: "серебро",
        count: 250,
      },
      3: {
        property: "silver",
        name: "серебро",
        count: 500,
      },
      4: {
        property: "silver",
        name: "серебро",
        count: 1500,
      },
    },
  },
  {
    numbers: 4,
    counts: {
      2: {
        property: "mana",
        name: "мана",
        count: 20,
      },
      3: {
        property: "mana",
        name: "мана",
        count: 40,
      },
      4: {
        property: "mana",
        name: "мана",
        count: 120,
      },
    },
  },
  {
    numbers: 5,
    counts: {
      2: {
        property: "soul",
        number: 1,
        count: 1,
      },
      3: {
        property: "soul",
        number: 2,
        count: 1,
      },
      4: {
        property: "soul",
        number: 3,
        count: 1,
      },
    },
  },
  {
    numbers: 6,
    counts: {
      2: {
        property: "gold",
        name: "золото",
        count: 4,
      },
      3: {
        property: "gold",
        name: "золото",
        count: 8,
      },
      4: {
        property: "gold",
        name: "золото",
        count: 24,
      },
    },
  },
];

function checkPrizes(dices, isComb = false) {
  const obj = dices.reduce((acc, cur) => {
    acc[cur] = acc[cur] ? acc[cur] + 1 : 1;
    return acc;
  }, {});

  if (isComb) {
    return obj;
  }

  const prizesInfo = [];
  Object.keys(obj).map((key) => {
    if (obj[key] > 1) {
      prizesInfo.push(prizes.find((el) => el.numbers == key).counts[obj[key]]);
    }
  });
  return prizesInfo;
}

function checkPoints(dices) {
  const obj = dices.reduce((acc, cur) => {
    acc[cur] = acc[cur] ? acc[cur] + 1 : 1;
    return acc;
  }, {});

  const points = [];

  Object.keys(obj).map((key) => {
    if (obj[key] > 1) {
      points.push(pointsComb.find((el) => el.numbers == key).counts[obj[key]]);
    }
  });

  return points;
}

const chancesArray = [
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
  2, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 4, 4, 4, 4, 4, 4, 4, 4, 5, 5, 5, 5, 5, 5, 6,
  6, 6, 6,
];

function randomNumber() {
  return chancesArray[Math.floor(Math.random() * chancesArray.length)];
}

function DiceGameTournament({
  user,
  app,
  setUser,
  setAdmin,
  isModalOpen,
  setIsModalOpen,
  setIsFetching,
  setActiveGame,
  isFetching,
  setModalError,
  serverTime,
}) {
  const [endTournamentOpen, setEndTournamentOpen] = useState(false);
  const [points, setPoints] = useState(0);
  const [rolling, setRolling] = useState(false);
  const [isFirstRollGame, setIsFirstRollGame] = useState(
    user.diceGameTournament.firstRoll
  );
  const [allPrizes, setAllPrizes] = useState([]);
  const [prisesComb, setPrisesComb] = useState([]);
  const [isFirstRoll, setIsFirstRoll] = useState(true);
  const [countRoll, setCountRoll] = useState(
    user.diceGameTournament.countRolls
  );
  const [gameStarted, setGameStarted] = useState(
    user.diceGameTournament.isGame
  );
  const [dices, setDices] = useState(user.diceGameTournament.dices);
  const countRollRef = useRef(user.diceGameTournament.countRolls);
  const [dicesClass, setDicesClass] = useState([0, 0, 0, 0]);

  useEffect(() => {
    return () => {
      setActiveGame("");
    };
  }, []);

  useEffect(() => {
    if (user.diceGameTournament.gamesDone >= maxGames) {
      setEndTournamentOpen(true);
    }
    if (!user.diceGameTournament.isStartTournament) {
      setActiveGame("");
    }
  }, [user]);

  useEffect(() => {
    if (isFirstRoll && gameStarted) {
      firstRollDice()
        .then((array) => {
          setTimeout(() => {
            setRolling(false);
          }, 800);
          setIsFirstRoll(false);
          if (array.length > 0) {
            app
              .service("users")
              .patch(
                user._id,
                {
                  "diceGameTournament.dices": array,
                  "diceGameTournament.isGame": true,
                  "diceGameTournament.firstRoll": false,
                  field: serverTime,
                },
                {
                  query: {
                    $select: ["_id", "email", "diceGameTournament"],
                  },
                }
              )
              .then((data) => {
                setDices(array);
                setUser((prev) => ({ ...prev, ...data }));
              })
              .catch((e) => {
                setModalError(e);
                console.log(e);
              });
          }
        })
        .catch((e) => {
          setModalError(e);
          console.log(e);
        });
    }
  }, [isFirstRoll, gameStarted]);

  useEffect(() => {
    countRollRef.current = countRoll;
    if (countRoll === maxRolls && !rolling) {
      setIsModalOpen(true);
    }
  }, [countRoll, rolling]);

  useEffect(() => {
    setPoints(checkPoints(dices).reduce((acc, cur) => acc + cur, 0));

    setAllPrizes(checkPrizes(dices));
    setPrisesComb(checkPrizes(dices, true));
    setTimeout(() => {
      setDicesClass(dices);
    }, 100);
    if (countRollRef.current > 0) {
      app
        .service("users")
        .patch(
          user._id,
          {
            "diceGameTournament.dices": dices,
            "diceGameTournament.countRolls": countRollRef.current,
            field: serverTime,
          },
          {
            query: {
              $select: ["_id", "email", "diceGameTournament"],
            },
          }
        )
        .then((data) => {
          setUser((prev) => ({ ...prev, ...data }));
        })
        .catch((e) => {
          setModalError(e);
          console.log(e);
        });
    }
  }, [dices]);

  const rollDice = useCallback(
    ({ diceIndex, diceNumber }) => {
      if (!diceNumber) {
        setRolling(true);
      }
      const rollNumber = diceNumber ? diceNumber : randomNumber();
      if (!isFirstRoll) {
        setCountRoll((prev) => prev + 1);
      }
      if (!diceNumber && !isFirstRoll) {
        setDices((prev) => {
          const array = Array.from(prev);
          array[diceIndex] = rollNumber;
          return array;
        });
      }

      setDicesClass((prev) => {
        const array = Array.from(prev);
        array[diceIndex] = 0;
        return array;
      });

      if (!diceNumber) {
        setTimeout(() => {
          setRolling(false);
        }, 800);
      }
      return rollNumber;
    },
    [isFirstRoll]
  );

  async function firstRollDice() {
    setRolling(true);
    setIsFirstRollGame(true);
    if (countRoll > 0 || !isFirstRollGame) {
      rollDice({
        diceIndex: 0,
        diceNumber: dices[0],
      });
      rollDice({
        diceIndex: 1,
        diceNumber: dices[1],
      });
      rollDice({
        diceIndex: 2,
        diceNumber: dices[2],
      });
      rollDice({
        diceIndex: 3,
        diceNumber: dices[3],
      });
      return [];
    } else {
      return [
        rollDice({ diceIndex: 0 }),
        rollDice({ diceIndex: 1 }),
        rollDice({ diceIndex: 2 }),
        rollDice({ diceIndex: 3 }),
      ];
    }
  }

  function endTournament() {
    setIsFetching(true);
    app
      .service("users")
      .patch(
        user._id,
        {
          "diceGameTournament.maxPoints":
            user.diceGameTournament.maxPoints < user.diceGameTournament.points
              ? user.diceGameTournament.points
              : user.diceGameTournament.maxPoints,
          "diceGameTournament.isStartTournament": false,
          "diceGameTournament.history": [
            ...user.diceGameTournament.history,
            user.diceGameTournament.points,
          ],
          field: serverTime,
        },
        {
          query: {
            $select: ["_id", "email", "diceGameTournament"],
          },
        }
      )
      .then((data) => {
        setUser((prev) => ({ ...prev, ...data }));
        app
          .service("admin")
          .patch(
            user.admin,
            {
              [`diceGameTournament.users.${user.email}`]: {
                _id: user._id,
                maxPoints: data.diceGameTournament.maxPoints,
                count: data.diceGameTournament.count,
                rate: user.diceGame.lvl,
              },
            },
            {
              query: {
                $select: ["diceGameTournament"],
              },
            }
          )
          .then((adminData) => {
            setAdmin((prev) => ({ ...prev, ...adminData }));
            setIsFetching(false);
          })
          .catch((e) => {
            setModalError(e);
            console.log(e);
            setIsFetching(false);
          });
      })
      .catch((e) => {
        setModalError(e);
        console.log(e);
        setIsFetching(false);
      });
  }

  function startGame() {
    if (user.diceGameTournament.gamesDone < maxGames) {
      setIsFetching(true);
      app
        .service("users")
        .patch(
          user._id,
          {
            $inc: {
              "dayQuest.diceGameGames": 1,
              "summerSave.results.diceGameGames": 1,
            },
            field: serverTime,
          },
          {
            query: {
              $select: ["_id", "email", "dayQuest", "summerSave"],
            },
          }
        )
        .then((data) => {
          setGameStarted(true);
          setIsFirstRoll(true);
          setUser((prev) => ({ ...prev, ...data }));
          setIsFetching(false);
        })
        .catch((e) => {
          setModalError(e);
          console.log(e);
          setIsFetching(false);
        });
    } else {
      setModalError("Вы уже сыграли все игры");
      console.log("Вы уже сыграли все игры");
    }
  }

  function endGame() {
    setIsFetching(true);

    const prizes = allPrizes.reduce((acc, cur) => {
      acc[cur.property] = {
        count: acc[cur.property]?.count
          ? acc[cur.property]?.count + Number(cur.count)
          : Number(cur.count),
        number: cur.number,
      };

      return acc;
    }, {});

    app
      .service("users")
      .patch(
        user._id,
        {
          "diceGameTournament.isGame": false,
          "diceGameTournament.firstRoll": true,
          "diceGameTournament.countRolls": 0,
          "diceGameTournament.dices": [],
          field: serverTime,
          $inc: {
            "diceGameTournament.points": points,
            "diceGameTournament.gamesDone": 1,
            gold: prizes?.gold?.count ? prizes.gold.count : 0,
            silver: prizes?.silver?.count ? prizes.silver.count : 0,
            essence: prizes?.essence?.count ? prizes.essence.count : 0,
            mana: prizes?.mana?.count ? prizes.mana?.count : 0,
            [`bosses.${prizes?.soul?.number ? prizes?.soul?.number : 0}.souls`]:
              prizes?.soul?.count ? prizes?.soul?.count : 0,
            "dayQuest.diceGameGold": prizes?.gold?.count
              ? prizes.gold?.count
              : 0,
            "newQuest.results.diceGame": 1,
            "summerSave.results.diceGame5555":
              dices.filter((item) => item === 5).length === 4 ? 1 : 0,

            "achievements.diceGame.1111":
              dices.filter((item) => item === 1).length === 4 ? 1 : 0,
            "achievements.diceGame.2222":
              dices.filter((item) => item === 2).length === 4 ? 1 : 0,
            "achievements.diceGame.3333":
              dices.filter((item) => item === 3).length === 4 ? 1 : 0,
            "achievements.diceGame.4444":
              dices.filter((item) => item === 4).length === 4 ? 1 : 0,
            "achievements.diceGame.5555":
              dices.filter((item) => item === 5).length === 4 ? 1 : 0,
            "achievements.diceGame.6666":
              dices.filter((item) => item === 6).length === 4 ? 1 : 0,
          },
        },
        {
          query: {
            $select: [
              "_id",
              "email",
              "diceGameTournament",
              "gold",
              "silver",
              "essence",
              "mana",
              "bosses",
              "dayQuest",
              "weakGamesPoints",
              "newQuest",
              "summerSave",
              "achievements",
            ],
          },
        }
      )
      .then((data) => {
        setIsModalOpen(false);
        setIsFirstRollGame(true);
        setCountRoll(0);
        setGameStarted(false);
        setPoints(0);
        setAllPrizes([]);
        setPrisesComb([]);
        setUser((prev) => ({ ...prev, ...data }));
        setIsFetching(false);
      })
      .catch((e) => {
        console.log(e);
        setIsFetching(false);
        setIsModalOpen(false);
        setIsFirstRollGame(true);
        setCountRoll(0);
        setGameStarted(false);
      });
  }

  return (
    <S.DiceGameWrapper>
      <S.PrizesWrapper>
        <S.TopInfo>
          <span>
            Сыграно игр: {user.diceGameTournament.gamesDone}/{maxGames}
          </span>
          <span>Набрано очков: {user.diceGameTournament.points}</span>
          <span>Комбинации</span>
        </S.TopInfo>
        <S.Prizes>
          <div>
            <span>x2</span>
            {prizes.map((elem, i) => {
              return (
                <S.Prize key={i} isActive={prisesComb[i + 1] === 2}>
                  <img
                    width={24}
                    src={require(`../../img/DicePen${i + 1}.png`)}
                    alt="кубик"
                  />
                  <Reward
                    name={elem.counts[2].property}
                    soul={elem.counts[2].number}
                    count={elem.counts[2].count}
                    w={16}
                  />
                </S.Prize>
              );
            })}
          </div>
          <div>
            <span>x3</span>
            {prizes.map((elem, i) => {
              return (
                <S.Prize key={i} isActive={prisesComb[i + 1] === 3}>
                  <img
                    width={24}
                    src={require(`../../img/DicePen${i + 1}.png`)}
                    alt="кубик"
                  />
                  <Reward
                    name={elem.counts[3].property}
                    soul={elem.counts[3].number}
                    count={elem.counts[3].count}
                    w={16}
                  />
                </S.Prize>
              );
            })}
          </div>
          <div>
            <span>x4</span>
            {prizes.map((elem, i) => {
              return (
                <S.Prize key={i} isActive={prisesComb[i + 1] === 4}>
                  <img
                    width={24}
                    src={require(`../../img/DicePen${i + 1}.png`)}
                    alt="кубик"
                  />
                  <Reward
                    name={elem.counts[4].property}
                    soul={elem.counts[4].number}
                    count={elem.counts[4].count}
                    w={16}
                  />
                </S.Prize>
              );
            })}
          </div>
        </S.Prizes>
        <S.BottomWrapper>
          <span>Доступно перебросов: {maxRolls - countRoll}</span>
          {!gameStarted ? (
            <S.MarkerButtonStyled
              width={100}
              onClick={
                !rolling && !isModalOpen && !isFetching ? startGame : null
              }
            >
              <div>Начать</div>
            </S.MarkerButtonStyled>
          ) : (
            <S.MarkerButtonStyled
              width={100}
              onClick={
                !rolling && !isModalOpen && !isFetching
                  ? () => setIsModalOpen(true)
                  : null
              }
            >
              <div>Закончить</div>
            </S.MarkerButtonStyled>
          )}
        </S.BottomWrapper>
      </S.PrizesWrapper>

      <S.DicesArea>
        {coordsDices.map((info, i) => (
          <Dice
            key={i}
            diceIndex={i}
            number={dicesClass[i]}
            x={info.x}
            y={info.y}
            rollDice={rollDice}
            rolling={rolling}
            disabled={
              rolling || isModalOpen || !gameStarted || countRoll >= maxRolls
            }
            gameStarted={gameStarted}
            active={activeDices > i}
          />
        ))}
      </S.DicesArea>

      <Popup isOpen={isModalOpen} w={317} h={217} back={frame3}>
        {allPrizes.length <= 0 && (
          <Close onClick={isModalOpen && !isFetching ? endGame : null} />
        )}

        <b>Очки за игру: {points}</b>
        {allPrizes.length > 0 ? (
          <>
            <b>Награда за комбинации</b>
            <S.Rewards>
              {allPrizes.map((el, i) => {
                return (
                  <RewardWrapper key={i} light={false} w={65}>
                    <div>
                      <Reward
                        name={el.property}
                        count={el.count}
                        soul={el.number}
                        w={28}
                        showCount={true}
                        round={true}
                        font={13}
                        reverseColumn={true}
                        color={"#fdbb54"}
                      />
                    </div>
                  </RewardWrapper>
                );
              })}
            </S.Rewards>
            <Button width={100} onClick={!isFetching ? endGame : null}>
              <div>Забрать</div>
            </Button>
          </>
        ) : (
          <>
            <b>Не удалось собрать комбинацию</b>
            <Button width={100} onClick={!isFetching ? endGame : null}>
              <div>Закрыть</div>
            </Button>
          </>
        )}
      </Popup>

      <Popup isOpen={endTournamentOpen} w={317} h={217} back={frame3}>
        <b>Результаты</b>
        <span>Набрано очков: {user.diceGameTournament.points}</span>
        <span>
          Лучший результат:{" "}
          {user.diceGameTournament.points > user.diceGameTournament.maxPoints
            ? user.diceGameTournament.points
            : user.diceGameTournament.maxPoints}
        </span>

        <Button width={100} onClick={!isFetching ? endTournament : null}>
          <div>Завершить</div>
        </Button>
      </Popup>
    </S.DiceGameWrapper>
  );
}

export default React.memo(DiceGameTournament);
