import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #f7b471;
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  pointer-events: ${(props) => (props.isOpen ? "auto" : "none")};
  z-index: ${(props) => (props.isOpen ? "1" : "-1")};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 806px;
  height: 520px;
  position: relative;
  & > div {
    padding: 20px 20px;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
  background-image: url("${(props) => props.back}");
  background-size: cover;
`;

export const Candy = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: center;
  & button {
    width: 100%;
  }
`;

export const Treasure = styled(Candy)``;

export const UserSpan = styled.span`
  cursor: pointer;
  color: #f7b471;
`;

export const CandyMana = styled.div`
  display: flex;
  gap: 3px;
  justify-content: center;
`;
