import styled from "styled-components";

export const Wrapper = styled.div`
  width: ${(props) => props.width && `${props.width}px`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  justify-items: center;
  align-items: center;
  background: rgba(17, 12, 9, 0.7);

  outline: 1px solid #281707;
  box-shadow: inset 0px 0px 0px 1px #281707;
  border: 2px solid #8b541a;
  border-radius: 3px;
  font-size: 12px;
`;
