import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  pointer-events: ${(props) => (props.isOpen ? "auto" : "none")};
  z-index: ${(props) => (props.isOpen ? "1" : "-1")};
`;

export const Content = styled.div`
  position: relative;
  width: 795px;
  height: 485px;
  background-image: url("${(props) => props.back}");
  background-size: cover;
`;

export const Page = styled.div`
  position: absolute;
  width: 702px;
  height: 462px;
  left: calc(50% + 2px);
  top: calc(50% - 2px);
  transform: translate(-50%, -50%);
  background-image: url("${(props) => props.back}");
  background-size: cover;
`;

export const PageBack = styled.div`
  position: absolute;
  width: 695px;
  height: 440px;
  left: calc(50% + 2px);
  top: calc(50% - 2px);
  transform: translate(-50%, -50%);
  overflow: hidden;
`;

export const Spot = styled.img`
  position: absolute;
  opacity: ${(props) => (props.opacity ? props.opacity : "1")};
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
  width: ${(props) => props.width}px;
  transform: translate(-50%, -50%)
    rotateZ(${(props) => (props.rotate ? `${props.rotate}deg` : "0deg")});
`;

export const BookPages = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 100%;
  width: 100%;
  height: 100%;
  padding: 25px 20px 35px;
  & > div:nth-child(1) {
    padding-right: 25px;
  }
  & > div:nth-child(2) {
    padding-left: 25px;
  }
`;

export const Tab = styled.div`
  position: absolute;
  width: 90px;
  height: 60px;

  left: ${(props) => (!props.isAdd ? "unset" : `${props.x}px`)};
  right: ${(props) => (props.isAdd ? "unset" : `${props.x}px`)};
  top: ${(props) => props.y}px;
  cursor: pointer;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 60px;
    will-change: transform;
    transition: transform 0.2s linear, filter 0.2s linear;
    transform: rotateY(${(props) => (props.isAdd ? 180 : 0)}deg)
      translateX(
        ${(props) =>
          props.isActive ? 15 : props.isAdd && props.isActive ? -15 : 0}px
      );

    & > img {
      will-change: transform;
      transition: transform 0.2s linear, filter 0.2s linear;
    }

    &:hover {
      & > img {
        filter: brightness(1.15);
      }
    }
    &:active {
      & > img {
        filter: brightness(0.9);
      }
    }

    & > img:nth-child(1) {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    & > img:nth-child(2) {
      z-index: 1;
    }
  }
`;

export const CloseBook = styled.div`
  position: absolute;
  width: 36px;
  height: 45px;
  top: -28px;
  right: 74px;
  cursor: pointer;
  transition: filter 0.2s linear;
  &:hover {
    filter: brightness(1.15);
  }
  &:active {
    filter: brightness(0.9);
  }
  background-image: url("${(props) => props.back}");
  background-size: cover;
`;
