import React, { useEffect, useState } from "react";
import * as S from "./Bank.styles";
import { CentredWrapper, Close } from "../../App.styles";
import Reward from "../Reward/Reward";
import { secondsToDhm, secondsToHm } from "../../helpers";
import { bankInfo, boxPrice, boxPrizes, stockDamage } from "../../info/data";
import damageImg from "../../img/bank/DamageHover.png";
import essenceImg from "../../img/bank/EssenceHover.png";
import dobbiImg from "../../img/bank/Dobbi.png";
import dobbiHoverImg from "../../img/bank/DobbiHover.png";
import manaImg from "../../img/bank/ManaHover.png";
import goldImg from "../../img/bank/GoldHover.png";
import saleImg from "../../img/bank/Sale.png";
import boxImg from "../../img/bank/Box.png";
import boxHoverImg from "../../img/bank/BoxHover.png";
import Popup from "../Popup/Popup";
import { RewardWrapper } from "../DayReward/DayReward.styles";
import frame6 from "../../img/Frame6.png";
import frame1 from "../../img/Frame1.png";
import {
  BottomPrice,
  BottomPrices,
  Button,
} from "../../panels/Arena/Arena.styles";

function Bank({
  setModalError,
  isFetching,
  setIsFetching,
  app,
  setUser,
  user,
  serverTime,
  adminInfo,
  getCharacterSum,
  changeOptionsLocation,
  bridge,
}) {
  const [leftTimeString, setLeftTimeString] = useState("00:00");
  const [leftTimeDamageString, setLeftTimeDamageString] = useState("00:00");
  const [timeDamageString, setTimeDamageString] = useState("00:00");
  const [leftTimeBoxString, setLeftTimeBoxString] = useState("00:00");
  const [isDamage, setIsDamage] = useState(false);
  const [isStock, setIsStock] = useState(false);
  const [isBox, setIsBox] = useState(false);
  const [activeBox, setActiveBox] = useState(0);
  const [sumCharac, setSumCharac] = useState(
    Math.floor(getCharacterSum(user) / 1000) * 5
  );
  const [endOpen, setEndOpen] = useState(false);
  const [activePopup, setActivePopup] = useState("");

  useEffect(() => {
    changeOptionsLocation("bank");
  }, []);

  useEffect(() => {
    if (
      (user.stockLvl?.damageEnd || 0) >= serverTime &&
      user.stockLvl?.damage > -1
    ) {
      setLeftTimeDamageString(
        secondsToDhm(((user.stockLvl?.damageEnd || 0) - serverTime) / 1000)
      );
    } else {
      setLeftTimeDamageString(null);
    }

    if (
      serverTime > adminInfo.isDamageStock.startTime &&
      serverTime <
        adminInfo.isDamageStock.startTime +
          adminInfo.isDamageStock.time * 3600000
    ) {
      setTimeDamageString(
        secondsToDhm(
          (adminInfo.isDamageStock.startTime +
            adminInfo.isDamageStock.time * 3600000 -
            serverTime) /
            1000
        )
      );
      setIsDamage(true);
    } else {
      setIsDamage(false);
      setTimeDamageString(
        secondsToDhm((adminInfo.isDamageStock.startTime - serverTime) / 1000)
      );
    }

    const secs =
      adminInfo.stock?.time * 3600 -
      Math.floor((serverTime - adminInfo?.stock?.startTime) / 1000);
    if (secs > 0 && adminInfo?.stock?.startTime <= serverTime) {
      setIsStock(true);
      setLeftTimeString(secondsToHm(secs));
    } else {
      setIsStock(false);
    }

    const secsBox =
      adminInfo.isBoxStock?.time * 3600 -
      Math.floor((serverTime - adminInfo?.isBoxStock?.startTime) / 1000);
    if (secsBox > 0 && adminInfo?.isBoxStock?.startTime <= serverTime) {
      setIsBox(true);
      setLeftTimeBoxString(secondsToHm(secsBox));
    } else {
      setIsBox(false);
    }
  }, [serverTime, adminInfo, user]);

  useEffect(() => {
    setSumCharac(Math.floor(getCharacterSum(user) / 1000) * 5);
  }, [user]);

  function handleClosePopup() {
    setActivePopup("");
  }

  function changeActiveBox(number) {
    setActiveBox(number);
  }

  function changeEndOpen(bool) {
    setEndOpen(bool);
  }

  function changeActivePopup(name) {
    setActivePopup(name);
  }

  function vkPay(params) {
    setIsFetching(true);
    bridge
      .send("VKWebAppShowOrderBox", {
        type: "item",
        item: JSON.stringify({
          [params.property]: params.count,
          g: params.price,
        }),
      })
      .then((data) => {
        if (data.success) {
          console.log("успех", data);
          changeEndOpen(true);
        }
        setIsFetching(false);
      })
      .catch((error) => {
        console.log(error);
        setIsFetching(false);
      });
  }

  return (
    <CentredWrapper>
      <S.Damage
        isStock={true}
        onClick={changeActivePopup.bind(null, "damage")}
        back={damageImg}
      />

      <S.Gold onClick={changeActivePopup.bind(null, "gold")} back={goldImg} />

      <S.Essence
        onClick={changeActivePopup.bind(null, "essence")}
        back={essenceImg}
      />

      <S.Mana onClick={changeActivePopup.bind(null, "mana")} back={manaImg} />

      <S.Dobbi back={dobbiImg} />

      <S.DobbiHover
        onClick={changeActivePopup.bind(null, "helper")}
        back={dobbiHoverImg}
      />

      {isStock && <S.SaleWrapper back={saleImg} />}

      {isBox && user.stockLvl.box === -1 && (
        <>
          <S.Box back={boxImg} />
          <S.BoxHover
            isStock={true}
            onClick={changeActivePopup.bind(null, "box")}
            back={boxHoverImg}
          />
        </>
      )}

      <Popup
        isOpen={activePopup === "helper"}
        setIsOpen={setActivePopup}
        w={436}
        h={320}
        back={frame6}
      >
        <Close onClick={handleClosePopup} />
        {activePopup === "helper" && (
          <>
            <b>Приобрести Универсального Помощника</b>
            <span>Включает в себя:</span>
            <span>Резервация Дуэльной комнаты (30 суток - 50 гол.)</span>
            <span>Резервация Боссов (30 суток - 50 гол.)</span>
            <span>Помощник в Теплице (30 суток - 30 гол.)</span>
            <span>Помощник в Лаборатории (30 суток - 30 гол.)</span>

            <BottomPrice>
              <s>160 голосов</s>
              <BottomPrices>
                <div>
                  <Button
                    width={100}
                    onClick={
                      !isFetching
                        ? vkPay.bind(null, {
                            property: "helpersAll",
                            count: 30,
                            price: 100,
                          })
                        : null
                    }
                  >
                    <div>100 голосов</div>
                  </Button>
                </div>
              </BottomPrices>
              <span>* все активные подписки продлеваются на 30 суток</span>
            </BottomPrice>
          </>
        )}
      </Popup>

      <Popup
        isOpen={activePopup === "gold" || activePopup === "mana"}
        setIsOpen={setActivePopup}
        w={436}
        h={320}
        back={frame6}
      >
        <Close onClick={handleClosePopup} />
        {(activePopup === "gold" || activePopup === "mana") && (
          <>
            <b>Приобрести {bankInfo[activePopup].name}</b>

            {isStock && user.stockLvl?.[activePopup] === -1 && (
              <span>
                Одна сделка X{adminInfo?.stock?.countX} на любую позицию:{" "}
                {leftTimeString}
              </span>
            )}

            <S.PropertyWrapper>
              {bankInfo[activePopup].prices.map((elem, i) => {
                return (
                  <div key={i}>
                    <RewardWrapper light={false} w={65}>
                      <div>
                        <Reward
                          name={activePopup}
                          count={
                            bankInfo[activePopup].count[i] *
                            (isStock && user.stockLvl?.[activePopup] === -1
                              ? adminInfo?.stock?.countX
                              : 1)
                          }
                          w={28}
                          showCount={true}
                          round={true}
                          font={13}
                          reverseColumn={true}
                          color={"#fdbb54"}
                        />
                      </div>
                    </RewardWrapper>

                    <button
                      tabIndex="-1"
                      onClick={
                        !isFetching
                          ? vkPay.bind(null, {
                              property: activePopup,
                              count:
                                bankInfo[activePopup].count[i] *
                                (isStock && user.stockLvl?.[activePopup] === -1
                                  ? adminInfo?.stock?.countX
                                  : 1),
                              price: elem,
                            })
                          : null
                      }
                    >
                      <S.Price>
                        <span>{elem} гол.</span>
                      </S.Price>
                    </button>
                  </div>
                );
              })}
            </S.PropertyWrapper>
          </>
        )}
      </Popup>

      <Popup
        isOpen={activePopup === "essence"}
        setIsOpen={setActivePopup}
        w={436}
        h={320}
        back={frame6}
      >
        <Close onClick={handleClosePopup} />
        {activePopup === "essence" && (
          <>
            <b>Приобрести {bankInfo[activePopup].name}</b>

            {isStock && user.stockLvl?.[activePopup] === -1 && (
              <span>
                Одна сделка X{adminInfo?.stock?.countX} на любую позицию:{" "}
                {leftTimeString}
              </span>
            )}

            <S.PropertyWrapper>
              {bankInfo[activePopup].prices.map((elem, i) => {
                return (
                  <div key={i}>
                    <RewardWrapper light={false} w={65}>
                      <div>
                        <Reward
                          name={activePopup}
                          count={
                            (bankInfo[activePopup].count[i] +
                              sumCharac * bankInfo[activePopup].additional[i]) *
                            (isStock && user.stockLvl?.[activePopup] === -1
                              ? adminInfo?.stock?.countX
                              : 1)
                          }
                          w={28}
                          showCount={true}
                          round={true}
                          font={13}
                          reverseColumn={true}
                          color={"#fdbb54"}
                        />
                      </div>
                    </RewardWrapper>

                    <button
                      tabIndex="-1"
                      onClick={
                        !isFetching
                          ? vkPay.bind(null, {
                              property: activePopup,
                              count:
                                (bankInfo[activePopup].count[i] +
                                  sumCharac *
                                    bankInfo[activePopup].additional[i]) *
                                (isStock && user.stockLvl?.[activePopup] === -1
                                  ? adminInfo?.stock?.countX
                                  : 1),
                              price: elem,
                            })
                          : null
                      }
                    >
                      <S.Price>
                        <span>{elem} гол.</span>
                      </S.Price>
                    </button>
                  </div>
                );
              })}
            </S.PropertyWrapper>
          </>
        )}
      </Popup>

      <Popup
        isOpen={activePopup === "damage"}
        setIsOpen={setActivePopup}
        w={436}
        h={320}
        back={frame6}
      >
        <Close onClick={handleClosePopup} />
        {activePopup === "damage" && (
          <>
            <b>Увеличить урон в сражениях с Боссами</b>

            {isDamage ? (
              <>
                <span>Время до конца акции:</span>
                <span>{timeDamageString}</span>
              </>
            ) : (
              <>
                <span>Время до начала акции:</span>
                <span>{timeDamageString}</span>
              </>
            )}

            {leftTimeDamageString && user.stockLvl.damage > -1 && (
              <>
                <span>
                  Оставшееся время бонуса (+
                  {stockDamage[user.stockLvl.damage].scale * 100}%):
                </span>
                <span>{leftTimeDamageString}</span>
              </>
            )}

            <BottomPrice>
              {!leftTimeDamageString && isDamage && (
                <>
                  <span>
                    Приобрести бонус на {adminInfo.isDamageStock.duration}ч.
                  </span>

                  <BottomPrices>
                    {stockDamage.map((elem, i) => {
                      return (
                        <div key={i}>
                          <span>+{elem.scale * 100}% к урону</span>
                          <Button
                            width={100}
                            onClick={
                              !isFetching
                                ? vkPay.bind(null, {
                                    property: "damage",
                                    count: i,
                                    price: elem.price,
                                  })
                                : null
                            }
                          >
                            <div>{elem.price} гол.</div>
                          </Button>
                        </div>
                      );
                    })}
                  </BottomPrices>
                </>
              )}
            </BottomPrice>
          </>
        )}
      </Popup>

      <Popup
        isOpen={activePopup === "box" && user.stockLvl?.box === -1}
        setIsOpen={setActivePopup}
        w={436}
        h={320}
        back={frame6}
      >
        <Close onClick={handleClosePopup} />
        {activePopup === "box" && (
          <>
            <b>Приобрести один из наборов</b>
            <span>До конца предложения {leftTimeBoxString}</span>

            <S.BoxButtonsWrapper>
              <Button
                onClick={changeActiveBox.bind(null, 0)}
                disabled={activeBox === 0}
                width={100}
              >
                <div>Малый</div>
              </Button>
              <Button
                onClick={changeActiveBox.bind(null, 1)}
                disabled={activeBox === 1}
                width={100}
              >
                <div>Средний</div>
              </Button>
              <Button
                onClick={changeActiveBox.bind(null, 2)}
                disabled={activeBox === 2}
                width={100}
              >
                <div>Большой</div>
              </Button>
            </S.BoxButtonsWrapper>

            {isBox && user.stockLvl?.box === -1 && (
              <>
                <S.PropertyWrapper>
                  {boxPrizes[activeBox].map((elem, i) => {
                    return (
                      <RewardWrapper key={i} light={false} w={65}>
                        <div>
                          <Reward
                            name={elem.property}
                            count={elem.count}
                            soul={elem.number}
                            w={28}
                            showCount={true}
                            round={true}
                            font={13}
                            reverseColumn={true}
                            color={"#fdbb54"}
                          />
                        </div>
                      </RewardWrapper>
                    );
                  })}
                </S.PropertyWrapper>
                <button
                  style={{ minWidth: "80px" }}
                  tabIndex="-1"
                  onClick={
                    !isFetching
                      ? vkPay.bind(null, {
                          property: "box",
                          count: activeBox,
                          price: boxPrice[activeBox],
                        })
                      : null
                  }
                >
                  {boxPrice[activeBox]} гол.
                </button>
              </>
            )}
          </>
        )}
      </Popup>

      <Popup
        isOpen={endOpen}
        setIsOpen={setEndOpen}
        w={281}
        h={152}
        back={frame1}
      >
        <Close onClick={changeEndOpen.bind(null, false)} />
        <b>Оплата прошла успешно</b>
      </Popup>
    </CentredWrapper>
  );
}

export default React.memo(Bank);
