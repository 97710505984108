import React, { useEffect, useState } from "react";
import InputNumber from "../InputNumber/InputNumber";
import * as S from "./ShopElem.styles";
import Reward from "../Reward/Reward";
import inputImg from "../../img/InputWrapper.png";
import { roundConsumablesFixed } from "../../helpers";

function ShopElem({
  gold,
  silver,
  maxCharacCount,
  damageTalents,
  elem,
  loading,
  isModalOpen,
  buyItem,
  inventory,
  bosses,
  bosses2,
  eventDiamondsCount,
}) {
  const [count, setCount] = useState(1);
  const [hpPriceScale, setHpPriceScale] = useState(
    1 + Math.floor(maxCharacCount / 1000) * 0.4
  );

  useEffect(() => {
    const hpPriceScale = 1 + Math.floor(maxCharacCount / 1000) * 0.4;
    setHpPriceScale(hpPriceScale);
  }, [maxCharacCount]);

  function changeCount(number) {
    const max = Math.floor(
      (elem.price.property === "diamond"
        ? eventDiamondsCount
        : elem.price.property === "gold"
        ? gold
        : silver) /
        Math.floor(
          elem.price.count *
            (elem.category === "hp" && elem.price.property === "silver"
              ? (damageTalents.simpleTalents.hpSilverDiscount > 0
                  ? 1 - damageTalents.simpleTalents.hpSilverDiscount / 100
                  : 1) * hpPriceScale
              : 1)
        )
    );

    if (count + number <= max && count + number > 0) {
      setCount(count + number);
    } else {
      if (count + number > max && max >= 1) {
        setCount(max);
      } else {
        setCount(1);
      }
    }
  }

  return (
    <S.Wrapper>
      <S.TopWrapper>
        {elem.category === "soul" ? (
          <>
            <S.TopString>
              {elem.name} (
              {roundConsumablesFixed(bosses[elem.value]?.souls || 0)})
            </S.TopString>
            <Reward name={"soul"} soul={elem.value} w={36} button={true} />
          </>
        ) : elem.category === "soul2" ? (
          <>
            <S.TopString>
              {elem.name} (
              {roundConsumablesFixed(bosses2[elem.value]?.souls || 0)})
            </S.TopString>
            <Reward name={"soul2"} soul={elem.value} w={36} button={true} />
          </>
        ) : (
          <>
            <S.TopString>
              {elem.categoryName} {elem.name} (
              {roundConsumablesFixed(
                inventory?.[`${elem.category}${elem.name}`] || 0
              )}
              )
            </S.TopString>

            {elem.potion ? (
              <Reward
                name={"potion"}
                category={elem.category}
                potionName={elem.name}
                w={36}
                button={true}
              />
            ) : (
              <Reward name={elem.category} w={36} button={true} />
            )}
          </>
        )}

        <S.InputWrapper>
          <img src={inputImg} alt="" />

          <S.Minus onClick={changeCount.bind(null, -1)}>-</S.Minus>
          <InputNumber
            count={count}
            min={1}
            max={Math.floor(
              (elem.price.property === "diamond"
                ? eventDiamondsCount
                : elem.price.property === "gold"
                ? gold
                : silver) /
                Math.floor(
                  elem.price.count *
                    (elem.category === "hp" && elem.price.property === "silver"
                      ? (damageTalents.simpleTalents.hpSilverDiscount > 0
                          ? 1 -
                            damageTalents.simpleTalents.hpSilverDiscount / 100
                          : 1) * hpPriceScale
                      : 1)
                )
            )}
            setCount={setCount}
          />
          <S.Plus onClick={changeCount.bind(null, 1)}>+</S.Plus>
        </S.InputWrapper>
      </S.TopWrapper>

      <S.ButtonBottom
        onClick={
          !loading && !isModalOpen
            ? buyItem.bind(null, elem, Number(count))
            : null
        }
      >
        <Reward
          button={true}
          name={elem.price.property}
          count={
            Math.floor(
              elem.price.count *
                (elem.category === "hp" && elem.price.property === "silver"
                  ? (damageTalents.simpleTalents.hpSilverDiscount > 0
                      ? 1 - damageTalents.simpleTalents.hpSilverDiscount / 100
                      : 1) * hpPriceScale
                  : 1)
            ) * count
          }
          w={15}
        />
      </S.ButtonBottom>
    </S.Wrapper>
  );
}

export default React.memo(ShopElem);
