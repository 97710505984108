import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
`;

export const ImgSpell = styled.div`
  display: flex;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};

  & > img {
    transition: 0.15s linear;
    filter: ${(props) => props.disabled && "brightness(0.8)"};
  }

  & > img:hover {
    filter: ${(props) =>
      props.disabled ? "brightness(0.8)" : "brightness(1.3)"};
  }

  & > img:hover + div {
    opacity: ${(props) => (props.disabled ? "0" : "1" + "" + "")};
    visibility: ${(props) => (props.disabled ? "hidden" : "visible")};
  }

  & > img:active {
    filter: ${(props) =>
      props.disabled ? "brightness(0.8)" : "brightness(0.9)"};
  }
`;

export const InfoSpell = styled.div`
  opacity: 0;
  visibility: hidden;
  transition: 0.15s linear;
  display: flex;
  width: 165px;
  height: 155px;
  position: absolute;
  transform: translate(0, -100%);
  left: 50%;
  top: -12px;
  cursor: default;
  color: #ffe3c8;
  font-family: "Bellota-Regular";

  background: rgba(17, 12, 9, 0.7);
  border-radius: 10px;

  outline: 1px solid #281707;
  box-shadow: inset 0px 0px 0px 1px #281707;
  border: 2px solid #fc9800;

  & > div {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    gap: 5px;
    padding: 5px;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;

    & > div {
      display: flex;
      align-items: center;
      gap: 2px;
      font-size: 12px;
      line-height: 12px;
    }

    & > div:nth-child(1) {
      font-size: 15px;
      line-height: 15px;
      margin-bottom: 5px;
    }
    & > div > span {
      font-weight: 600;
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;

export const InputAttacks = styled.div`
  & > input {
    font-size: 11px;
    line-height: 14px;
    text-align: center;
    color: #fcc382;
    font-family: "Bellota-Regular";
    padding: 0 !important ;
    border: none !important;
    background-color: transparent !important;
    outline: none !important;
    min-width: 20px;
    max-width: 20px;

    -moz-appearance: textfield !important;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
    }
  }
`;
