import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #f7b471;
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  pointer-events: ${(props) => (props.isOpen ? "auto" : "none")};
  z-index: 1;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 806px;
  height: 520px;
  position: relative;
  & > div {
    padding: 20px 20px;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
  }
  background-image: url("${(props) => props.back}");
  background-size: cover;
`;

export const ShopElements = styled.div`
  min-height: 435px;
  max-height: 435px;
  align-content: flex-start;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  overflow-y: scroll;
  gap: 9px;
  font-size: 12px;
  padding: 0 10px;
`;

export const ShopElemWrapper = styled.div`
  width: ${(props) => props.width && `${props.width}px`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  justify-items: center;
  align-items: center;
  background: rgba(17, 12, 9, 0.7);

  outline: 1px solid #281707;
  box-shadow: inset 0px 0px 0px 1px #281707;
  border: 2px solid #8b541a;
  border-radius: 3px;
  font-size: 12px;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 0 15px 0 10px;
  & > div {
    display: flex;
    gap: 8px;
  }
`;
