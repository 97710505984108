import styled from "styled-components";
import btnImg from "../../img/miniIcons/Button.png";

export const Wrapper = styled.div`
  position: absolute;
  display: flex;
  bottom: 70px;
  left: 465px;
  align-items: center;

  & > div {
    position: absolute;
    & input {
      font-size: 11px;
      line-height: 14px;
      padding: 0 8px;
      width: 303px;
      height: 27px;
      border: none !important;
      background-color: rgba(255, 255, 255, 0) !important;
      outline: none !important;
      font-family: "Bellota-Regular";
      color: #ffcf82 !important;
      -moz-appearance: textfield !important;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
      }
      ::placeholder {
        color: rgba(255, 207, 130, 0.7) !important;
        text-transform: none;
      }
    }
    & > div {
      cursor: pointer;
      position: absolute;
      right: -3px;
      top: 0;
      transform: translateX(100%);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 27px;
      height: 27px;

      & img {
        backface-visibility: hidden;
        will-change: transform;
        transition: 0.2s ease;
      }
      &:hover img {
        filter: brightness(1.2);
      }
      &:active img {
        filter: brightness(0.9);
      }
    }
  }
`;
