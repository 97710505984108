import React, { useEffect, useState } from "react";
import * as S from "./FinishTopBosses.styles";
import { Avatar, ButtonValue } from "../ActiveBoss/ActiveBoss.styles";
import place1 from "../../img/Place1.png";
import place2 from "../../img/Place2.png";
import place3 from "../../img/Place3.png";
import { roundPropertyLow } from "../../helpers";
import { DamageInfo } from "../DamageTop/DamageTop.styles";

function FinishTopBosses({
  damageTop,
  allUsers,
  nickName,
  photo,
  orden,
  name,
  email,
  allHp,
  isRaid,
}) {
  const [arrayDamage, setArrayDamage] = useState([]);
  const [meDamage, setMeDamage] = useState({
    place: -1,
    percent: 0,
    damage: 0,
  });

  useEffect(() => {
    const damageInfo = Object.entries(damageTop).sort((a, b) => b[1] - a[1]);

    if (damageInfo.length > 0) {
      const index = damageInfo.findIndex((elem, i) => email == elem[0]);
      if (index > -1) {
        setMeDamage({
          place: index + 1,
          damage: damageInfo[index][1],
          name: name,
          nickName: nickName,
          orden: orden,
          photo: photo,
          percent: (((damageInfo[index][1] || 0) / allHp) * 100).toFixed(2),
        });
      } else {
        setMeDamage({
          place: -1,
          percent: 0,
          damage: 0,
        });
      }

      setArrayDamage(
        damageInfo
          .map((elem) => {
            const user = allUsers.find((user) => user.email == elem[0]);
            return {
              email: user?.email,
              damage: elem[1],
              name: user?.name,
              nickName: user?.nickName,
              orden: user?.orden,
              photo: user?.photo,
              percent: (((elem[1] || 0) / allHp) * 100).toFixed(2),
            };
          })
          .sort((a, b) => b.damage - a.damage)
          .slice(0, 100)
      );
    }
  }, [damageTop, allUsers, nickName, photo, orden, name]);

  return arrayDamage.length > 0 ? (
    <S.Wrapper>
      {!isRaid && (
        <S.Top3>
          {arrayDamage.map((elem, i) => {
            return (
              i < 3 && (
                <div key={i}>
                  <Avatar>
                    <img
                      src={elem.photo}
                      width={70}
                      height={70}
                      alt={"аватар"}
                    />
                    <div>
                      <img
                        src={i === 0 ? place1 : i === 1 ? place2 : place3}
                        width={34}
                        height={34}
                        alt={"медаль"}
                      />
                    </div>
                  </Avatar>
                  <ButtonValue width={70}>
                    <div>
                      {roundPropertyLow(elem.damage).toLocaleString("ru")}
                    </div>
                  </ButtonValue>
                </div>
              )
            );
          })}
        </S.Top3>
      )}

      <S.TopLog isRaid={isRaid}>
        <div>
          {arrayDamage.map(
            ({ name, damage, nickName, orden, email, percent }, i) => {
              return (
                <DamageInfo key={i}>
                  <div>
                    <span>{i + 1}. </span>
                    <span>{orden?.tag ? `[${orden?.tag}] ` : ""}</span>
                    <span>
                      {nickName
                        ? nickName + " "
                        : name?.firstName + " " + name?.lastName + " "}
                      = {roundPropertyLow(damage).toLocaleString("ru")} (
                      {percent}%)
                    </span>
                  </div>
                </DamageInfo>
                // <DamageInfo key={i}>
                //   <div>
                //     <span>{i + 1}. </span>
                //     <span>{orden?.tag ? `[${orden?.tag}] ` : ""}</span>
                //     <span>
                //       @id{email}(
                //       {nickName
                //         ? nickName
                //         : name?.firstName + " " + name?.lastName}
                //       ) = {roundPropertyLow(damage).toLocaleString("ru")} (
                //       {percent}%)
                //     </span>
                //   </div>
                // </DamageInfo>
              );
            }
          )}
        </div>

        <div>
          <DamageInfo>
            <div>
              <span>{meDamage.place === -1 ? "—" : `${meDamage.place}.`} </span>

              <span>
                Вы = {meDamage.damage?.toLocaleString("ru")} ({meDamage.percent}
                %)
              </span>
            </div>
          </DamageInfo>
        </div>
      </S.TopLog>
    </S.Wrapper>
  ) : null;
}

export default React.memo(FinishTopBosses);
