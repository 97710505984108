import styled, { css } from "styled-components";
import btnImg from "../../img/miniIcons/Button.png";

export const Wrapper = styled.div`
  position: absolute;
  left: 465px;
  bottom: 8px;
  display: flex;
  align-items: center;
`;

export const FriendsBlock = styled.div`
  position: absolute;
  display: flex;
  gap: 1px;
`;

export const FriendsFrames = styled.div`
  display: flex;
  gap: 1px;
`;

export const Friend = styled.div`
  position: relative;
  display: flex;
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.isMe ? "default" : "pointer")};
`;

export const Number = styled.span`
  cursor: default;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  line-height: 1;
  position: absolute;
  color: rgb(247, 180, 113);
  font-size: 12px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 45px;
  text-align: center;
  max-width: 45px;
`;

export const ArrowsWrapper = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  position: absolute;
  left: -2px;
  transform: translateX(-100%);
`;

export const ArrowsWrapperRight = styled(ArrowsWrapper)`
  left: unset;
  right: -2px;
  transform: translateX(100%);
`;

export const PrevButton = styled.div`
  background-image: url("${btnImg}");
  background-size: cover;
  width: 27px;
  height: 27px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    will-change: transform;
    transition: filter 0.3s linear;
    transform: rotateY(180deg);
  }

  &:hover img {
    transform: scale(1.05) rotateY(180deg);
  }
  &:active img {
    transform: scale(1) rotateY(180deg);
  }
  ${(props) =>
    props.limit &&
    css`
      filter: brightness(0.7);
      cursor: default;
      &:hover img {
        transform: rotateY(180deg);
      }
    `};
`;

export const PrevButtonAny = styled(PrevButton)``;

export const NextButton = styled(PrevButton)`
  & img {
    transition: filter 0.3s linear;
    transform: unset;
  }

  &:hover img {
    transform: scale(1.05);
  }
  &:active img {
    transform: scale(1);
  }
  ${(props) =>
    props.limit &&
    css`
      filter: brightness(0.7);
      cursor: default;
      &:hover img {
        transform: unset;
      }
    `};
`;

export const NextButtonAny = styled(NextButton)``;
